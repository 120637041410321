import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

// Material Table
import MaterialTable from "@material-table/core";
import ExportCsv from "@material-table/exporters/csv";

export default function Sub_contractlist(props) {
  const { subcontractList } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [clickedRow, setClickedRow] = React.useState();

  // role Id from Local storage
  const roleId = localStorage.getItem("roleId");

  const onButtonClick = (e, row) => {
    e.stopPropagation();
    setClickedRow(row);
  };
  const handleButtonClick = (projectNo) => {
    // Do something with the project ID
    console.log("Clicked project ID:", projectNo);
    // localStorage.setItem("projectId", projectNo);
    navigate("/view_project");
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  const MTcolumn = [
    { field: "id", title: "ID", width: 5 },
    { field: "work", title: "Work", width: 10 },
    { field: "", title: "Sub-contractor Name", width: 10, render: (params) => params.subContractorFirstName+" "+params.subContractorLastName },
    // { field: "", title: "Contact Person", width: 10, render: (params) => "-" },
    // { field: "", title: "Email", width: 10, render: (params) => "-" },
    // { field: "", title: "Phone Number", width: 10, render: (params) => "-" },
    { field: "description", title: "Description", width: 150 },
    {
      field: "status",
      title: "Status",
      width: 50,
      // valueGetter: (params) => params.row.id,
      render: (params) => {
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "60px",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                minWidth: "auto",
                marginRight: "5px",
                color: colors.grey[300],
                // backgroundColor: colors.primary[500],
              },
            }}
          >
            <Typography gutterBottom variant="h5" component="div">Progress : 50%</Typography>
            <BorderLinearProgress variant="determinate" value={50} />
          </Box>
        );
      },
      width: 80,
    },
    {
      field: "action",
      title: "Actions",
      width: 100,
      // valueGetter: (params) => params.row.id,
      render: (params) => {
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "60px",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                minWidth: "auto",
                marginRight: "5px",
                color: colors.grey[300],
                // backgroundColor: colors.primary[500],
              },
            }}
          >
            <div className="grid-actionsWrap">
              <Button
                onClick={() =>
                  navigate("/subContractorDash", {
                    state: {
                      id: params.subContractorId,
                      subContractid: params.id
                    },
                  })
                }
                variant="contained"
              >
                <VisibilityOutlinedIcon />
              </Button>
              <Button
                onClick={(e) => onButtonClick(e, params)}
                variant="contained"
              >
                <EditOutlinedIcon />
              </Button>
              <Button>
                <MessageOutlinedIcon />
              </Button>
            </div>
          </Box>
        );
      },
      width: 120,
    },
  ];

  return (
    <Box>
      <Box
        m="40px 0 0 0"
        sx={{
          "& button": {
            marginRight: "10px",
          },
          "& .MuiDataGrid-root": {
            fontSize: "14px",
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-root p": {
            margin: 0,
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none !important",
            backgroundColor: colors.grey[900],
            fontSize: "14px",
          },
          "& .name-column--cell": {
            color: colors.primary[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[900],
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.grey[600],
          },
          "& .MuiDataGrid-footerContainer": {
            borderColor: colors.grey[800],
            backgroundColor: colors.grey[900],
          },
          "& .user_list_title": {
            color: colors.grey[300],
            fontSize: "22px",
            fontWeight: 600,
          },
          "& .user_list_gridWrap": {
            backgroundColor: colors.grey[800],
          },
          "& .user_list_search": {
            backgroundColor: colors.grey[900],
          },
          "& .user_list_search input": {
            color: colors.grey[200],
          },
          "& .grid-actionsWrap": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          },
          "& .grid-actionsWrap Button": {
            fontSize: "13px",
            padding: "4px",
            minWidth: "auto",
            marginRight: "5px",
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Box style={{ display: "flex", justifyContent: "start" }}>
          {/* <Button
            disabled={roleId === "2" ? false : true}
            variant="contained"
            // onClick={() => navigate("/Exist_invite")}
          >
           Add Project
          </Button>
          <Button
            disabled={roleId === "2" ? false : true}
            variant="contained"
            // onClick={() => navigate("/Exist_invite")}
          >
            Add Client
          </Button> */}
          <Button
            disabled={roleId === "2" ? false : true}
            variant="contained"
            onClick={() => navigate("/Add_sub-contract")}
          >
            Add sub-contract
          </Button>
          {/* <Button
            disabled={roleId === "2" ? false : true}
            variant="contained"
            onClick={() => navigate("/Exist_invite")}
          >
            Invite/Add subContractor
          </Button> */}
          {/* <Button
            disabled={roleId === "2" ? false : true}
            variant="contained"
            onClick={() => navigate("/Exist_invite")}
          >
            Add Data Manager
          </Button> */}
          {/* <Button
            disabled={roleId === "2" ? false : true}
            variant="contained"
            onClick={() => navigate("/Exist_invite")}
          >
            Invite/Add QA
          </Button> */}
        </Box>
        <MaterialTable
          title={<h2 className="user_list_title">Sub Contracts</h2>}
          columns={MTcolumn}
          data={subcontractList}
          //   options={{
          //     exportMenu: [
          //       {
          //         label: "Export CSV",
          //         exportFunc: (cols, datas) => {
          //           cols = cols.filter(col => col.field !== 'action')
          //           console.log(cols)
          //           ExportCsv(cols, data, "Report")
          //           // downloadExelFile(transaction)
          //         },
          //       },
          //     ]

          //   }}
        />
      </Box>
    </Box>
  );
}
