import * as React from "react";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoginImage from "../images/OIU9IR1T.png";
import LoginLogo from "../images/SS.png";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { openDB } from "idb";
import { useEffect } from "react";
import { Backdrop, CircularProgress, Icon, IconButton, InputAdornment } from "@mui/material";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import loadingLogo from "../images/animation_ljz9wzoo.json";
import { notification } from "antd";
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from "react-redux";
import {getUserProfileApi} from "../Redux/GetUserDetails/userAPI"
import LinearProgress from '@mui/material/LinearProgress';

// material icon for paswords show and off
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {/* <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '} */}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

// const handleSubmit=(e)=>{
//     e.preventDefault();
// }

function SignInSide() {

  
  const dispatch = useDispatch();

  const token = localStorage.getItem('token');


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);

// for show password
const [showPassword, setShowPassword] = useState(false);


// for show password
const handleShowPassword = () => {
  setShowPassword((show) => !show);
};


  console.log(email, password);

  const handleSubmit = (event) => {
    setLoading(true)
    event.preventDefault();
    console.log(email, password);
    axios
      .post("https://sig.eastus.cloudapp.azure.com/backend/user/login", {
        email: email,
        password: password,
      })
      .then((result) => {
        console.log(result.data);
        localStorage.setItem("token", result.data.token);
        localStorage.setItem("userId", result.data.userId);

        dispatch(getUserProfileApi(result.data.userId));
       
       
        notification.success({
          message: "Success",
          description: "Logged in successfully",
          placement: "topRight",
          duration: 2,
          style: {
            marginTop: "50px",
            textTransform: "capitalize",
          },
        });
      })
      .then(()=>{
        navigate("/dashboard");
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
        notification.error({
          message: "Error",
          description: error.response.data.message,
          placement: "topRight",
          duration: 2,
          style: {
            marginTop: "50px",
            textTransform: "capitalize",
          },
        });
        setLoading(false)
      });
  };


  // google auth login

  const responseMessage = (response) => {
    console.log(response);
    setUser(response);
  };
  const errorMessage = (error) => {
    console.log("error msg", error);
  };


  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });


  useEffect(
    () => {
      if (user) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            setProfile(res.data);
            console.log(res.data);
          })
          .catch((err) => console.log(err));
      }
    },
    [user]
  );

  useEffect(()=>{
    if(token){
      navigate("/dashboard")
    }
  },[])

  return (
    <ThemeProvider theme={theme}>
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      // onClick={handleClose}
      > */}
        {/* <Player
          autoplay
          loop
          // src="https://assets3.lottiefiles.com/private_files/lf30_gdfa2beg.json"
          src={loadingLogo}
          style={{ height: "300px", width: "300px" }}
        ></Player> */}
        {/* <CircularProgress color="inherit" /> */}
      {/* </Backdrop> */}
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage: `url(${LoginImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "bottom center",
          }}
        />
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            
            <Box sx={{ m: 1, pb: 3 }}>
              <img src={`${LoginLogo}`} width="300"></img>
            </Box> 
            {
              loading && <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
            }
            
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoFocus
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="Password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
             
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={handleShowPassword} edge="end">
                        {/* <Icon
                        // sx={{color:"black"}}
                        icon={showPassword ?  eyeFill : eyeOffFill} /> */}
                        {showPassword ?  <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
             />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onSubmit={handleSubmit}
              >
                Sign In
              </Button>

              {/* <GoogleLogin
              //  onClick={() => login()}
  onSuccess={responseMessage} onError={errorMessage}
/> */}
              
              <Grid container>
                <Grid item xs>
                  <Link href={"./forgotPass"} variant="body2">
                    Forgot password?
                  </Link>
                </Grid>

                <Grid item>
                  <Link href={"./SignUp"} variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              {/* <Grid container>
                <div 
                style={{border: "1px solid", padding:"10px",cursor:"pointer",borderRadius:"7px"}}
                onClick={() => login()}>Sign in with Google 
                <img 
                style={{height: "30px", width:"30px",marginLeft:"20px"}}
                src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" />
                 </div>

              </Grid> */}
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default SignInSide;
