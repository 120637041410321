import { Box, Paper, Typography, useTheme } from "@mui/material";
import { useMode, tokens } from "../../theme";
import MainLayout from "../../layout/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import ProjectInfo from "./projectInfo";
import SchecudleList from "./SchecudleList";
import EngineerSchedules from "./cards/engineerSchedule";
import Technician_TaskList from "./Technician_TaskList";
import { useParams } from "react-router-dom";
import ProjectIfoOnAccordion from "./ProjectIfoOnAccordion";
import EngineeringIcon from "@mui/icons-material/Engineering";

const EngineerDash = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, isLoading } = useSelector((state) => state.user);
  localStorage.setItem("roleId", 7);

  return (
    <MainLayout>
      <Box
        sx={{
          p: 3,
          "& h5": {
            fontSize: "16px",
            fontWeight: "600",
          },
        }}
      >
        <div className="user_cards_wrap">
          <div className="row">
            <div className="col-md-9 col-12 mb-3">
              {/* <ProjectInfo /> */}
              <ProjectIfoOnAccordion />
            </div>
            <div className="col-md-3 col-12 mb-3">
              <Paper
                sx={{
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >

                <EngineeringIcon 
                sx={{fontSize:"80px"}}
                />
<Box>

                <Typography variant="h3" gutterBottom>
                  {+" " + user && user.firstName + " " + user.lastName}
                </Typography>
                <Typography variant="h4" gutterBottom>
                  (
                  {localStorage.getItem("role")
                    ? localStorage.getItem("role").charAt(0).toUpperCase() +
                      localStorage.getItem("role").slice(1)
                    : ""}
                  )
                </Typography>
</Box>
              </Paper>
            </div>
            <div className="col-md-3 col-12 mb-3">
              <EngineerSchedules />
            </div>
            <div className="col-md-12 col-12 mb-3">
              <Technician_TaskList />
            </div>
          </div>
        </div>
      </Box>
    </MainLayout>
  );
};

export default EngineerDash;
