import React from "react";
import MainLayout from "../layout/MainLayout";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { api_rout } from "../Api-Config/config";
import Grid from "@mui/material/Grid";
import { Label } from "@mui/icons-material";

export default function ViewConcreteMix() {
  const [misData, setMixData] = useState();

  const [value, setValue] = React.useState();

  const roleID = localStorage.getItem('roleId');
  console.log(roleID, "concretmixdesign roleid");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

    const getMixDataById = async ()=>{
        try{
            const response = await axios.get(`${api_rout}/concmixdesign/get/by/id/5`);
            console.log(response.data);
            const {mixDescription,wcRatio,water,sand,sNo8,sNo67,sNo4,s3In,flyAsh,comments} = response.data[0]
            setMixData({mixDescription,wcRatio,water,sand,sNo8,sNo67,sNo4,s3In,flyAsh,comments})
        }
        catch(error){
            console.log(error)
        }
    }
  

  async function isDate(str) {
    if (typeof str === "number") {
      return false;
    } else {
      const date = await new Date(str);
      console.log(!isNaN(date));
      return !isNaN(date) && !isNaN(date.getDate());
    }
  }

  function getonlyDate(str) {
    console.log(typeof str);
    return str.slice(0, 10);
  }

  useEffect(() => {
    getMixDataById();
  }, []);

  return (
    <Box sx={{ marginTop: "20px", padding: "15px", borderRadius: "6px", border: "1px solid #eee"}}>
      <Box
        sx={{
          "& .grid-actionsWrap": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          },
          "& .grid-actionsWrap Button": {
            fontSize: "13px",
            padding: "4px",
            minWidth: "auto",
            marginRight: "5px",
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Grid container spacing={3}>
            {misData &&
              Object.entries(misData).map(([Label, value]) => (
                <Grid item xs={12} sm={4}>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                    key={Label}
                  >
                    <h5>
                    {Label.charAt(0).toUpperCase() + Label.slice(1).split(/(?=[A-Z])/).join(" ")}: 
                    </h5>
                    {/* <h6> {isDate(value) === true ?  getonlyDate(value): value} </h6> */}
                    <h6> {value} </h6>
                    {/* <h6> {Label} </h6> */}
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>

        {roleID !== '1' && roleID !== '2' && roleID !== '7' && (
          <Box>
        <Box sx={{ marginTop: "15px", borderTop: "1px solid #f3f3f3", paddingTop: "15px"}}>
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              <h5>Approve?</h5>
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel value="1" control={<Radio />} label="Yes" />
              <FormControlLabel value="0" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box>
          <Button variant="contained">Submit</Button>
        </Box>
        </Box>
        )}
      </Box>
    </Box>
  );
}
