import React, { useState } from 'react'
import ProjectCards from './ProjectCards/ProjectCards'
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { api_rout } from '../../Api-Config/config';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";


function EmployeeDashBoard() {
    const navigate = useNavigate();

    const [project, setProject] = useState()
    const [role, setRole] = useState();
    const [loading, setLoading] = useState(false);
    let userId = localStorage.getItem('userId')

    console.log(userId)


    function getUniqueProjects(entries) {
        const uniqueProjects = new Map();
    
        for (const entry of entries) {
            if (!uniqueProjects.has(entry.projectId)) {
                uniqueProjects.set(entry.projectId, entry);
            }
        }
    
        return Array.from(uniqueProjects.values());
    }

    const getAssignmentByuserid = async () => {
        try {
            const response = await axios.get(`${api_rout}/assignment/get/by/user/${userId}`);
            console.log(response.data);
            const uniqueProjects = getUniqueProjects(response.data);
            return uniqueProjects;
        }
        catch (err) {
            console.log(err)
        }
    }

    const getAllProject = async () => {
        try {
            const response = await axios.get(`${api_rout}/project/get/all`);
            console.log(response.data);
            return response.data;
        }
        catch (err) {
            console.log(err)
        }
    }

    const getAllRole = async () => {
        try {
            const response = await axios.get(`${api_rout}/role/all`);
            console.log(response.data);
            return response.data;
        }
        catch (err) {
            console.log(err)
        }
    }

    const getAllData = async () => {
        setLoading(true)
        let assignment = await getAssignmentByuserid();
        let allProject = await getAllProject();
        let allRole = await getAllRole();
        console.log(assignment, "assignment")
        const combineProject = await assignment.map((assignment) => {
            let filterProject = allProject.filter(project => assignment.projectId === project.id)
            let filterRole = allRole.filter(role => assignment.roleId === role.id);
            console.log(filterProject, filterRole, "filter");
            return { ...filterProject[0], role: filterRole[0] }
        });
        console.log(combineProject)
    //     if(combineProject.length ===1){
    //          localStorage.setItem("projectId", projectNo);
    // localStorage.setItem("role",role);
    // localStorage.setItem("roleId",roleId);
    // localStorage.setItem("rolePriority",rolePriority);
    //     }
        setProject(combineProject)

        if(combineProject.length === 1){
            localStorage.setItem("projectId", combineProject[0].id);
    localStorage.setItem("role",combineProject[0].role.name);
    localStorage.setItem("roleId", combineProject[0].role.id);
    localStorage.setItem("rolePriority",combineProject[0].role.priority);
    if(combineProject[0].role.name === "client"){
        navigate("/projectView");
      }
      else if(combineProject[0].role.name === "contractor"){
        navigate("/contractorDash")
      }
      else if(combineProject[0].role.name === "sub contractor"){
        navigate("/subContractorDash")
      }
      else if(combineProject[0].role.name === "QC"){
        navigate("/QCDash")
      }
      else if(combineProject[0].role.name === "QA"){
        navigate("/QADash")
      }
      else{
        navigate("/engineerDash")
      }
}
        // setProject(filterProject[0]);
        // setRole(filterRole[0]);
        setLoading(false)

    }

    useEffect(() => {
        console.log(project, "project")
        
    }, [project])
    useEffect(() => {
        getAllData();
    }, [])

    if (loading) return <h1>Loading....</h1>
    return (
        <div style={{display:"flex", flexDirection:"row",flexWrap:"wrap", gap:"1rem",margin:"10px"}}>
            {
             project &&   project.map((project)=> <ProjectCards
                projectName={project.projectName}
                role={ project.role.name}
                // change projectNo to ID
                projectId={ project.id }
                roleId={ project.role.id }
                rolePriority = { project.role.priority}
                description= { project.description }
            />)
            }
            
        </div>
    )
}

export default EmployeeDashBoard