import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./addSchedule.css";
import axios from "axios";
import MainLayout from "../../layout/MainLayout";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function AddSchedule() {
  const navigate = useNavigate();
  const currentDate = new Date().toISOString().split("T")[0];
  const initialValue = {
    discipline: "",
    technician: "",
    date: "",
    project: "",
  };
  const [formValue, setFormValue] = useState(initialValue);
  const [users, setUsers] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selected, setSelected] = useState(localStorage.getItem("user"));

  useEffect(() => {
    axios
      .get("https://sig.eastus.cloudapp.azure.com/backend/user/allusers")
      .then((response) => {
        setUsers(response.data);
      });
    axios
      .get("https://sig.eastus.cloudapp.azure.com/backend/project/get/all")
      .then((response) => {
        setProjects(response.data);
      });
    axios
      .get("https://sig.eastus.cloudapp.azure.com/backend/steps/get/all")
      .then((res) => {
        console.log(res.data);
        setDisciplines(res.data);
      });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = () => {
    if (localStorage.getItem("user")) {
      axios
        .post("https://sig.eastus.cloudapp.azure.com/backend/schedule/create", {
          ...formValue,
          createdBy: localStorage.getItem("user"),
        })
        .then((res) => {
          console.log(res.data);
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Select a user");
    }
  };

  const handleChangeiput = (event) => {
    console.log(event.target.value);
    localStorage.setItem("user", event.target.value);
    setSelected(event.target.value);
  };
  useEffect(() => {
    axios
      .get("http://sig.eastus.cloudapp.azure.com:8080/backend/user/allusers")
      .then((response) => {
        const { data } = response;
        setUsers(data);
      });
  }, []);

  return (
    <MainLayout>
      {users && (
        <FormControl sx={{ m: 1, minWidth: 120, color: "white" }} size="small">
          <InputLabel id="demo-simple-select-label">Select User</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selected}
            label="Age"
            onChange={handleChangeiput}
          >
            {users.map((value) => (
              <MenuItem value={value.id} key={value.id}>
                {value.firstName + " " + value.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <div style={{ width: "100%", height: "100vh" }}>
        <div style={{ width: "100%", height: "100%" }}>
          <form className="add_sch_form">
            <h2>Add Schedule</h2>
            <select name="project" onChange={handleChange}>
              <option value="">Select Project</option>
              {projects &&
                projects.map((value) => (
                  <option value={value.id} key={value.id}>
                    {value.projectName}
                  </option>
                ))}
            </select>
            {/* <input type="text" name="name" onChange={handleChange} /> */}
            <select name="discipline" onChange={handleChange}>
              <option value="">Select Test </option>
              {disciplines &&
                disciplines.map((value) => (
                  <option value={value.id}>{value.name}</option>
                ))}
            </select>
            <select name="technician" onChange={handleChange}>
              <option value="">Select Technician</option>
              {users &&
                users.map((value) => (
                  <option value={value.id} key={value.id}>
                    {value.firstName + " " + value.lastName}
                  </option>
                ))}
            </select>

            <input
              type="date"
              name="date"
              id=""
              min={currentDate}
              value={formValue.date}
              onChange={handleChange}
            />
            {/* <input type="time" name="time" onChange={handleChange} /> */}
            <button onClick={handleSubmit} type="button">
              Submit
            </button>
          </form>
        </div>
      </div>
    </MainLayout>
  );
}

export default AddSchedule;
