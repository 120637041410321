import React from "react";
import axios from "axios";
import localforage from "localforage";
import { openDB } from "idb";
import { api_rout } from "../Api-Config/config";

const API_URL = "https://sig.eastus.cloudapp.azure.com/backend";

//Retrieve data for Projects List
export const projectListData = async () => {
  return axios.get(`${api_rout}/project/get/all`);
};

export const projectDetails = async (props) => {
  return axios.get(`${api_rout}/project/single/${props}`);
};

// export const addNewProject = async(formData) =>{
//     return axios.post(`${api_rout}/project/create`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
// }

//Post data for Projects List
export const addNewProject = async (formData) => {
    try {
      const response = await axios.post(`${api_rout}/project/create`, formData);
      return response.data;
    } catch (error) {
      console.error("Error creating project:", error);
      throw error;
    }
};

//Post data for Construction Projects
export const addNewConstProject = async (formData) => {
  try {
    const response = await axios.post(`${api_rout}/constructioninfo/create`, formData);
    return response.data;
  } catch (error) {
    console.error("Error creating project:", error);
    throw error;
  }
};

// export const syncOfflineProjects = async () => {
//     if (navigator.onLine) {
//       const offlineProjects = JSON.parse(localStorage.getItem('offlineProjects')) || [];
  
//       for (const projectData of offlineProjects) {
//         try {
//           await addNewProject(projectData);
//         } catch (error) {
//           console.error('Error syncing offline project:', error);
//         }
//       }
  
//       localStorage.removeItem('offlineProjects');
//       alert('Offline projects synchronized successfully!');
//     }
//   };

//Retrieve data for Users List
export const userListData = async () => {
  return axios.get(`${api_rout}/user/allusers`);
};

export default {
  projectListData,
  projectDetails,
  addNewProject,
  userListData,
  addNewConstProject
};
