import React, { useEffect, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import MainLayout from "../../layout/MainLayout";
import GoogleMapReact from "google-map-react";
import axios from "axios";
import ProjectMap from "../maps/projectMap";
import apiService from "../../data/apiService";
import DateRangePicker from "@mui/lab/DateRangePicker";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import { api_rout } from "../../Api-Config/config";
import Carousel from "react-material-ui-carousel";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router-dom";
import Sub_contractlist from "./Sub_contractlist";
import SchecudleList from "./SchecudleList";
import Technician_TaskList from "./Technician_TaskList";
import TestList from "./Test_List";
import Mix_List from "./Mix_List";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const View_project = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const projectId = localStorage.getItem("projectId");

  const [projectData, setProjectData] = useState("");
  const [loading, setLoading] = useState(null);
  const [projectImages, setProjectImages] = useState([]);
  const [projectVideo, setProjectVideo] = useState([]);

  // nevigate
  const nevigate = useNavigate();

  // NastedTableData state
  const [nestedTabeData, setNestedTableData] = useState([]);

  // filter by date
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const filterDataByDate = (startDate, endDate) => {
    const filtered = projectImages.filter((row) => {
      const createdDate = new Date(row.createdAt);

      return createdDate >= startDate && createdDate <= endDate;
    });
    console.log(filtered, "filter data");
    if (filtered.length === 0) {
      alert("No images were found on this Date Range");
    }
    setFilteredData(filtered);
  };

  const fetchProjectData = async () => {
    try {
      const response = await apiService.projectDetails(projectId);
      setProjectData(response.data);
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };
  const FetchProjectVideo = async () => {
    try {
      const response = await axios.get(
        `https://sig.eastus.cloudapp.azure.com/backend/onsitevideo/get/project/${projectId}`
      );
      // const response = await axios.get(
      //   `https://sig.eastus.cloudapp.azure.com/backend/onsitevideo/all`
      // );
      // const response = await apiService.projectDetails(projectId);
      setProjectVideo(response.data);
      console.log(response.data, "data*******");
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  // GEt data for concrete Betch Data
  const getAllconcreteMixDesignData = async () => {
    try {
      const response = await axios.get(`${api_rout}/concmixdesign/get/all`);
      console.log(response.data, "getAllconcreteMixDesignData");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  const getCocreteBetchTicketByProjectID = async () => {
    try {
      const response = await axios.get(
        `${api_rout}/concbatchtickets/get/by/construction/${projectId}`
      );
      console.log(response.data, "getCocreteBetchTicketByProjectID");
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  const createDataForConcreteBatchNastedTableData = async () => {
    try {
      let concretebatchData = await getCocreteBetchTicketByProjectID();
      let concreteMixData = await getAllconcreteMixDesignData();
      let combineData = await concretebatchData?.map((concreteData) => {
        let fltMixdata = concreteMixData.filter(
          (mixData) => mixData.mixId == concreteData.mixId
        );
        console.log(fltMixdata, "filter Data");
        return { ...concreteData, concreteMixData: fltMixdata };
      });
      setNestedTableData(combineData);
      console.log(combineData, "combineData");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    createDataForConcreteBatchNastedTableData();
    fetchProjectData();
    FetchProjectVideo();
  }, [projectId]);

  useEffect(() => {
    // Fetch product images from API
    fetch(
      `https://sig.eastus.cloudapp.azure.com/backend/onsite/get/project/${projectId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setProjectImages(data); // Assuming data is an array of objects with image path and product ID
        console.log(data, "*********************");
      })
      .catch((error) => {
        console.error("Error fetching product images:", error);
      });
    console.log(projectId);
  }, []);

  return (
    <MainLayout>
      <Box
        sx={{
          p: 3,
          "& video": {
            width: "100%",
          },
          "& h5": {
            fontSize: "16px",
            fontWeight: "600",
          },
        }}
      >
        <h1>{projectData.projectName}</h1>
        <div className="row">
          <div className="col-12 mb-4">
            <div style={{ height: "320px", width: "100%" }}>
              <ProjectMap
                latitude={projectData.latitude}
                longitude={projectData.longitude}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-md-3 mb-4">
                <div className="projectView_txt">
                  <h5>Project Number</h5>
                  <p>{projectData.projectNo}</p>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="projectView_txt">
                  <h5>Associated with</h5>
                  <p>{projectData.clientId}</p>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="projectView_txt">
                  <h5>Sector</h5>
                  <p>{projectData.sectorId}</p>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="projectView_txt">
                  <h5>Phase</h5>
                  <p>{projectData.phaseId}</p>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="projectView_txt">
                  <h5>City</h5>
                  <p>{projectData.city}</p>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="projectView_txt">
                  <h5>County/District</h5>
                  <p>{projectData.countyOrDistrict}</p>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="projectView_txt">
                  <h5>State</h5>
                  <p>{projectData.stateId}</p>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="projectView_txt">
                  <h5>Country</h5>
                  <p>{projectData.countryId}</p>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="projectView_txt">
                  <h5>Latitude</h5>
                  <p>{projectData.latitude}</p>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="projectView_txt">
                  <h5>Longitude</h5>
                  <p>{projectData.longitude}</p>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="projectView_txt">
                  <h5>Description</h5>
                  <p>{projectData.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="projectView_mediaWrap">
          <div className="row">
            {projectData.images && (
              <div className="col-md-6 mb-4">
                <div className="projectView_media">
                  <h5>Project Image</h5>
                  <img
                    src={
                      "https://sig.eastus.cloudapp.azure.com/backend/project/image?path=" +
                      projectData.images
                    }
                    className="img-fluid"
                  />
                </div>
              </div>
            )}
            <div className="col-md-6 mb-4">
              <Box
                style={{
                  backgroundColor: colors.grey[800],
                  width: "100%",
                  borderRadius: "6px",
                  padding: "15px",
                  height: "100%",
                }}
                sx={{}}
              >
                <h4>Project Videos</h4>
                <Carousel
                  next={(next, active) =>
                    console.log(`we left ${active}, and are now at ${next}`)
                  }
                  prev={(prev, active) =>
                    console.log(`we left ${active}, and are now at ${prev}`)
                  }
                >
                  {projectVideo &&
                    projectVideo.map((vdo) => (
                      <div className="projectView_media">
                        <div className="projectView_video">
                          <video controls>
                            <source
                              src={
                                "https://sig.eastus.cloudapp.azure.com/backend/file/video?path=" +
                                vdo.video
                              }
                              type="video/mp4"
                            />
                            Your browser does not support HTML video.
                          </video>
                        </div>
                      </div>
                    ))}
                </Carousel>
              </Box>
            </div>
            <div className="col-md-6 mb-4">
              <Box
                style={{
                  backgroundColor: colors.grey[800],
                  width: "100%",
                  borderRadius: "6px",
                  padding: "15px",
                  height: "100%",
                }}
                sx={{}}
              >
                <div className="row">
                  <h3>Onsite Photos</h3>
                  <Box
                    sx={{
                      "& .onsitePhotoFilters input": {
                        padding: "3px 5px",
                        marginRight: "5px",
                        borderRadius: "4px",
                      },
                      "& .onsitePhotoFilters button": {
                        padding: "3px 5px",
                        marginRight: "5px",
                        borderRadius: "4px",
                        backgroundColor: "#3d96f7",
                        borderColor: "#3d96f7 !important",
                        boxShadow: "none",
                        color: "#fff",
                      },
                    }}
                  >
                    <div className="onsitePhotoFilters">
                      <input
                        type="date"
                        onChange={(e) => setStartDate(new Date(e.target.value))}
                      />
                      <input
                        type="date"
                        onChange={(e) => setEndDate(new Date(e.target.value))}
                      />
                      <button
                        onClick={() => filterDataByDate(startDate, endDate)}
                      >
                        Search
                      </button>
                      <button
                        onClick={() => setFilteredData([])}
                        variant="contained"
                      >
                        <RestartAltIcon /> Reset
                      </button>
                    </div>
                    {/* <DemoContainer
        components={[
          'DateRangePicker',
        ]}
      >
            <DemoItem label="Responsive variant" component="DateRangePicker">
          <DateRangePicker
            // defaultValue={[dayjs('2022-04-17'), dayjs('2022-04-21')]}
          />
        </DemoItem>

      </DemoContainer> */}
                  </Box>
                  {filteredData.length !== 0 ? (
                    <Carousel
                      next={(next, active) =>
                        console.log(`we left ${active}, and are now at ${next}`)
                      }
                      prev={(prev, active) =>
                        console.log(`we left ${active}, and are now at ${prev}`)
                      }
                    >
                      {filteredData.map((project) => (
                        <div className="col-md-4 col-sm-6 col-12 mt-4">
                          <img
                            key={project.projectId}
                            src={
                              "https://sig.eastus.cloudapp.azure.com/backend/file/image?path=" +
                              project.images
                            }
                            alt={`Product Image ${project.projectId}`}
                            className="img-fluid"
                          />
                          <div
                            className="onSight_imageLoc"
                            style={{ position: "relative" }}
                          >
                            <h4>
                              Latitude: {project.latitude},<br></br> Longitude:{" "}
                              {project.longitude}
                            </h4>
                            {/* <div style={{ height: "240px", width: "100%" }}>
                        <ProjectMap
                          latitude={project.latitude}
                          longitude={project.longitude}
                        />
                      </div> */}
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  ) : (
                    // <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                    <Carousel
                      next={(next, active) =>
                        console.log(`we left ${active}, and are now at ${next}`)
                      }
                      prev={(prev, active) =>
                        console.log(`we left ${active}, and are now at ${prev}`)
                      }
                    >
                      {projectImages &&
                        projectImages.map((project) => (
                          <div className="col-md-4 col-sm-6 col-12 mt-4">
                            <img
                              key={project.projectId}
                              src={
                                "https://sig.eastus.cloudapp.azure.com/backend/file/image?path=" +
                                project.images
                              }
                              alt={`Product Image ${project.projectId}`}
                              className="img-fluid"
                            />
                            <div
                              className="onSight_imageLoc"
                              style={{ position: "relative" }}
                            >
                              <h4
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  left: "10px",
                                  width: "100%",
                                  zIndex: 3,
                                  textShadow: "0 3px 3px #000",
                                }}
                              >
                                Latitude: {project.latitude},<br></br>{" "}
                                Longitude: {project.longitude}
                              </h4>
                              {/* <div style={{ height: "240px", width: "100%" }}>
                        <ProjectMap
                          latitude={project.latitude}
                          longitude={project.longitude}
                        />
                      </div> */}
                            </div>
                          </div>
                        ))}
                    </Carousel>
                  )}
                </div>
              </Box>
            </div>
          </div>
        </div>

        <Sub_contractlist />

        <SchecudleList />

        <Technician_TaskList />

        <TestList/>

        <Mix_List/>

        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>ID</TableCell>
                <TableCell>Construction Id</TableCell>
                <TableCell align="right">Mix ID</TableCell>
                <TableCell align="right">Plant ID</TableCell>
                <TableCell align="right">Mixer ID</TableCell>
                <TableCell align="right">Batch Number</TableCell>
                <TableCell align="right">Ticket Number</TableCell>
                <TableCell align="right">Truck ID</TableCell>
                <TableCell align="right">Time Batched</TableCell>
                <TableCell align="right">Placement Location</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nestedTabeData &&
                nestedTabeData.map((data) => (
                  <Row key={data.id} data={data} nevigate={nevigate} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </MainLayout>
  );
};

function Row(props) {
  const { data, nevigate } = props;
  console.log(data, "onprops");
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {data.id}
        </TableCell>
        <TableCell align="">{data.constId}</TableCell>
        <TableCell align="right">{data.mixId}</TableCell>
        <TableCell align="right">{data.plantId}</TableCell>
        <TableCell align="right">{data.mixerId}</TableCell>
        <TableCell align="right">{data.batchNum}</TableCell>
        <TableCell align="right">{data.ticketNum}</TableCell>
        <TableCell align="right">{data.truckId}</TableCell>
        <TableCell align="right">{data.timeBatched.slice(0, 10)}</TableCell>
        <TableCell align="right">{data.placementLocation}</TableCell>
        <TableCell align="right">
          <Button onClick={() => nevigate(`/viewconcreteBatch/${data.id}`)}>
            <VisibilityOutlinedIcon />
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {/* <Typography variant="h6" gutterBottom component="div">
                History
              </Typography> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Mix ID</TableCell>
                    <TableCell>Trial/Final</TableCell>
                    <TableCell align="right">Mix Description</TableCell>
                    <TableCell align="right">Water/Cement Ratio</TableCell>
                    <TableCell align="right">Water</TableCell>
                    <TableCell align="right">Sand</TableCell>
                    <TableCell align="right">Comments</TableCell>
                    <TableCell align="right">Date Appended</TableCell>
                    <TableCell align="right">Author</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.concreteMixData?.map((historyRow) => (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {historyRow.id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {historyRow.mixId}
                      </TableCell>
                      <TableCell>
                        {historyRow.trialFinal === 0 ? "Trial" : "Final"}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.mixDescription}
                      </TableCell>
                      <TableCell align="right">{historyRow.wcRatio}</TableCell>
                      <TableCell align="right">{historyRow.water}</TableCell>
                      <TableCell align="right">{historyRow.sand}</TableCell>
                      <TableCell align="right">{historyRow.comments}</TableCell>
                      <TableCell align="right">
                        {historyRow.dateAppended.slice(0, 10)}
                      </TableCell>
                      <TableCell align="right">{historyRow.author}</TableCell>
                      <TableCell align="right">
                        <Button
                          onClick={() =>
                            nevigate(`/viewconcreteMix/${data.id}`)
                          }
                        >
                          <VisibilityOutlinedIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default View_project;
