import React from 'react'
import styles from './style.module.css';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
export const ImageHoverandDelete = (props) => {
    const {img,handleDelete,id} = props
  return (
    <div className={styles.container}>
    <img src={img} alt="Avatar" className={styles.image} style={{width:"100%"}} />
    <div className={styles.middle}>
      <div className={styles.text}
      onClick={()=>handleDelete(id)}
      > <ClearOutlinedIcon /></div>
    </div>
  </div>
  )
}
