import { api_rout } from "../../Api-Config/config";
import {
    get_user_failure,
    get_user_request,
    get_user_success,
  } from "./user.action";
  import axios from "axios";


  export const getUserProfileApi = (id) => async(dispatch) => {
    console.log(id,"id from log in")
    try{
        dispatch(get_user_request());
        const response = await axios.get(`${api_rout}/user/find/${id}`);
        console.log(response.data)
        dispatch(get_user_success(response.data));
    }
    catch(err){
        dispatch(get_user_failure(err));
    }
  }