import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoginImage from "../images/OIU9IR1T.png";
import LoginLogo from "../images/SS.png";
import { Navigate, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      {/* <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '} */}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

// const handleSubmit=(e)=>{
//     e.preventDefault();
// }

export default function ResetPass() {
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const data = new FormData(event.currentTarget);
    // console.log({
    //   password: data.get('password'),
    // });
    if (password === confirmpassword) {
      try {
        const userId = localStorage.getItem('userId');
        const email = localStorage.getItem('userEmail');
        const firstname = localStorage.getItem('userFisrtname');
        const lastname = localStorage.getItem('userLastname');
        const companyName = localStorage.getItem('userLastname');
        const dateofbirth = localStorage.getItem('userLastname');
        const phone = localStorage.getItem('userLastname');
        console.log(userId);
        await axios.put(`http://localhost:8000/users/${userId}`, {
          firstname,
          lastname,
          email,
        password
      });
        navigate('/dashboard');
      } catch (error) {
        console.log(error);
      }
    } else {
      alert('Passwords do not match');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage: `url(${LoginImage})`    ,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'bottom center',
          }}
        />
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ m: 1, pb: 3 }}>
              <img src={`${LoginLogo}`} width="300"></img>
            </Box>
            <Typography component="h1" variant="h5">
              Please Reset Your Password
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="New Password"
                type="password"
                id="password"
                value={password}
                onChange={(event)=>setPassword(event.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmpassword"
                label="New Password"
                type="password"
                id="passwordConf"
                value={confirmpassword}
                onChange={(event)=>setConfirmPassword(event.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }} 
                onSubmit={handleSubmit}
              >
                Submit
              </Button>
              <Grid container justifyContent="center">
              <Grid item>
                <Link href={'./'} variant="body2">
                  Back to Login
                </Link>
              </Grid>
            </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}