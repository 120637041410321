import { Box, Paper, Typography, useTheme } from "@mui/material";
import { useMode, tokens } from "../../theme";
import MainLayout from "../../layout/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import ProjectDetails from "./clientDash";
import ProjectInfo from "./projectInfo";
import Sub_contractlist from "./Sub_contractlist";
import SchecudleList from "./SchecudleList";
import Reports from "./cards/reports";
import axios from "axios";
import { api_rout } from "../../Api-Config/config";
import { useEffect, useState } from "react";
import ProjectIfoOnAccordion from "./ProjectIfoOnAccordion";
import MessagesCard from "../messages/messagesCard";
import DataManagerCard from "../dataManager/dataManagerCard";
import AllModulesList from "../modules/allModulesList";
// import DataManagerList from "../dataManager/dataManagerList";

const ContractorDash = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, isLoading } = useSelector((state) => state.user);
  const projectId = localStorage.getItem("projectId");

  // All sub-contract list State

  const [AllsubContract, setAllSubContract] = useState([]);

  const getContract = async () => {
    try {
      const response = await axios.get(
        `${api_rout}/contract/by/project/${projectId}`
      );
      console.log(response.data, "contract by project Id");
      return response.data[0];
    } catch (err) {
      console.log(err);
    }
  };

  const getSubContractByContractId = async (ContractId) => {
    try {
      const response = await axios.get(
        `${api_rout}/subcontract/by/contract/${ContractId}`
      );
      console.log(response.data, "subcontract by contract Id");
      return response.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getSubContract = async () => {
    let contract = await getContract();
    console.log(contract, "get contract");
    if (contract) {
      let subContractByContractId = await getSubContractByContractId(
        contract.id
      );
      console.log(subContractByContractId, "sub contract");
      setAllSubContract(subContractByContractId);
    }
  };

  useEffect(() => {
    getSubContract();
  }, []);
  return (
    <MainLayout>
      <Box
        sx={{
          p: 3,
          backgroundColor: "#f9f9f9",
          "& h5": {
            fontSize: "16px",
            fontWeight: "600",
          },
        }}
      >
         {/* <div
         style={{position:"fixed",right: 0,top:100,zIndex:1}}
         type="button" class="btn btn-info mr-md-3 text-light">M<br/>é<br/>n<br/>a<br/>g<br/>e<br/><br/> p<br/>r<br/>o<br/>j<br/>e<br/>c<br/>t</div> */}
        <div className="user_cards_wrap container">
          <div className="row">
            <div className="col-md-9 col-12 mb-4">
              {/* <ProjectInfo /> */}
              <ProjectIfoOnAccordion/>
            </div>
            <div className="col-md-3 col-12 mb-4">
              <Paper  sx={{ padding: "10px",alignItems:"center",gap:"10px",border: "1px solid #d9d9d9", boxShadow: "none" }}>
                <Box sx={{display:"flex",alignItems:"center"}}>
                <Typography variant="h3" gutterBottom style={{ fontSize: "19px", margin: 0}}>
               {+" " + user && user.firstName + " " + user.lastName}
                </Typography>
                <Typography variant="h4" gutterBottom style={{ fontSize: "16px", margin: 0}}>
                ({localStorage.getItem("role") ? localStorage.getItem("role").charAt(0).toUpperCase()+ localStorage.getItem("role").slice(1): ""})
                </Typography>
                </Box>
                <Box>
                  {/* <p><strong>Current Login:</strong> {new Date(Date.now()).toLocaleString()}</p> */}
                  <p style={{ margin: 0}}><strong>Last Login:</strong> 8/11/2023, 3:01:32 PM</p>
                </Box>
              </Paper>
            </div>
            <div className="col-md-4 col-12 mb-4">
              <MessagesCard />
            </div>
            <div className="col-md-4 col-12 mb-4">
              <DataManagerCard />
            </div>
            <div className="col-md-12 col-12 mb-4">
              {/* <Sub_contractlist subcontractList={AllsubContract} /> */}
              <AllModulesList />
            </div>
          </div>
        </div>
      </Box>
    </MainLayout>
  );
};

export default ContractorDash;
