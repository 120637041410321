import React from "react";
import MainLayout from "../../layout/MainLayout";
import VideoRecoder from "../../general/VideoRecoder";
import Camera from "../../general/camera";
import { Box, Paper } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

export const CameraAndVideoCapture = () => {

    const [cameraDetails,setCameraDetails] = useState();
    const [location,setLocation] = useState();

    useEffect(() => {
        console.log(cameraDetails,"camera details",location);
    },[cameraDetails,location])
  return (
    <MainLayout>
      <Box sx={{ display: "flex", gap: "1rem",margin:"10px" }}>
        <Paper sx={{ height: "550px", width: "48%" }}>
          <VideoRecoder />
        </Paper>
        <Paper sx={{ height: "550px", width: "48%" }}>
          <Camera 
          setCameraDetails={setCameraDetails}
          setLocation={setLocation}
         />
        </Paper>
      </Box>
    </MainLayout>
  );
};
