import React, { useEffect, useState } from "react";
import { Box, Button, Paper, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import ProjectMap from "../maps/projectMap";
import { useNavigate } from "react-router-dom";
import ProjectInfo from "./projectInfo";
import ProjectIfoOnAccordion from "./ProjectIfoOnAccordion";
import { useSelector } from "react-redux";
import axios from "axios";
import { api_rout } from "../../Api-Config/config";
import { notification } from "antd";

const ProjectDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const projectId = localStorage.getItem("projectId");
  const nevigate = useNavigate();
  const [showConstructor, setShowConstructor] = useState(false);
// Contractor details State
const [contractorDetails,setContractorDetails] = useState();

  const navigate = useNavigate();

  const { user, isLoading } = useSelector((state) => state.user);


  const hancleCreateContract = async() => {
    // setShowConstructor(true);
    const payload = {
      "projectId": Number(projectId),
      "contractorId": null,
      "createBy": user.id
}
    try{
      const response = await axios.post(`${api_rout}/contract/create`,payload)
      console.log(response.data,"contract created");
      notification.success({
        message: "Success",
        description: "ontract created Successfully",
        placement: "topRight",
        duration: 2,
        style: {
          marginTop: "50px",
          textTransform: "capitalize",
        },
      });
    }
    catch(error){

    }

  };


  const getContractDetails = async() => {
    try{
      const response = await axios.get(`${api_rout}/user/get/users/by/project/${projectId}`);
      console.log(response.data);
      const data = response.data.filter((item)=> item.roleId === 2)
      console.log(data);
      setContractorDetails(data.length!==0 && data[0])
    }
    catch(error){
      console.log(error);
    }
  }

  useEffect(()=>{
    getContractDetails();
  },[])

  return (
    <Box
      sx={{
        p: 3,
        "& h5": {
          fontSize: "16px",
          fontWeight: "600",
        },
      }}
    >
        {/* <ProjectInfo /> */}
        <div className="row">
            <div className="col-md-9 col-12 mb-3">
              <ProjectIfoOnAccordion />
            </div>
            <div className="col-md-3 col-12 mb-3">
              <Paper sx={{ padding: "10px",display:"flex",alignItems:"center",gap:"10px" }}>
                <Typography variant="h3" gutterBottom>
                {+" " + user && user.firstName + " " + user.lastName}
                </Typography>
                <Typography variant="h4" gutterBottom>
                  ({localStorage.getItem("role") ? localStorage.getItem("role").charAt(0).toUpperCase()+ localStorage.getItem("role").slice(1): ""})
                {/* ({localStorage.getItem("role") }) */}
                </Typography>
              </Paper>
            </div>
            </div>  

      <div className="row mt-4">
        <div className="col-md-12">
          <div
            className="constructorInfo p-3 rounded"
            style={{ backgroundColor: "#f3f3f3" }}
          >
         
              <div className="row constructorInfoWrap">
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-9">
                      <h2 className="page_title">Contractor</h2>
                    </div>
                    <div className="col-md-3">
                      <button className="btn btn-primary float-end" onClick={()=>navigate("/contractorDash")}>
                        View
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 mb-0">
                      <div className="projectView_txt">
                        <h5>Company Name</h5>
                        <p>{
                          contractorDetails ? contractorDetails.companyName : ""
                        }
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 mb-0">
                      <div className="projectView_txt">
                        <h5>Contact Person</h5>
                        <p>{
                          contractorDetails ? contractorDetails.firstName+ " "+contractorDetails.lastName : ""
                        }
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 mb-0">
                      <div className="projectView_txt">
                        <h5>Email</h5>
                        <p>{
                          contractorDetails ? contractorDetails.email : ""
                        }
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 mb-0">
                      <div className="projectView_txt">
                        <h5>Phone Number</h5>
                        <p>{
                          contractorDetails ? contractorDetails.phoneNumber : ""
                        }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
           
              <div className="row">
                <div className="col-12 contractorBtnWrap">
                  <button
                  disabled={contractorDetails ? true : false}
                    className="btn btn-primary m-2"
                    onClick={() => navigate("/Exist_invite")}
                  >
                    Invite Contractor
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => hancleCreateContract()}
                    // onClick={() => handleButtonClick()}
                  >
                    Create Contract
                  </button>
                </div>
              </div>

          </div>
        </div>
      </div>
    </Box>
  );
};

export default ProjectDetails;
