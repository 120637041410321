import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { tokens } from "../../theme";
import { useLocation, useNavigate } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// Material Table
import MaterialTable from "@material-table/core";
import ExportCsv from "@material-table/exporters/csv";
import axios from "axios";
import { api_rout } from "../../Api-Config/config";
import DeleteIcon from "@mui/icons-material/Delete";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function SchecudleList(props) {
  const { subContract } = props;
  console.log(props, "what props");

  const { state } = useLocation();
  console.log( state, "whole state");


  // get role Id from local storage

  const roleId = localStorage.getItem("roleId");
  const projectId = Number(localStorage.getItem("projectId"));
  const userId = Number(localStorage.getItem("userId"));

  console.log(roleId, "by roleId");
  const [value, setValue] = React.useState(0);
  const [clickedRow, setClickedRow] = React.useState();
  const [scheduleList, setScheduleList] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  const getContractIdByProjectId = async()=> {
    try{
      const response = await axios.get(`${api_rout}/contract/by/project/${projectId}`);
      console.log(response.data);
      return response.data[0];
    }
    catch(error){
      console.log(error);
    }
  }

  const getAllSubContractByContractID = async(contractId)=>{
    try{
      let id = state ? state.id : userId;
      console.log(id,"for filter");
      const response = await axios.get(`${api_rout}/subcontract/by/contract/${contractId}`);
      console.log(response.data,"sub contraact by contract ID");
      const subContract = response.data.filter(subcontract => subcontract.subContractorId === id);
      console.log(subContract,"filter sub contract");
      return subContract[0];
    }
    catch(error){
      console.log(error);
    }
  }

  const getAllscheduleBySubcontractID = async(subContractId)=>{
    try{
      const response = await axios.get(`${api_rout}/schedule/get/by/subcontract/${subContractId}`);
      console.log(response.data);
      const data = response.data?.map((itemList,i)=>{
        return {...itemList,slNo:i+1}
      })
      setScheduleList(data)
    }
    catch(error){
      console.log(error);
    }
  }


const getScheduleListAsPerSubContractor = async()=>{
  const cintractId = await getContractIdByProjectId();
  const subContractId = await getAllSubContractByContractID(cintractId.id);
  await getAllscheduleBySubcontractID(subContractId.id)
}

  // const getAllschedule = async () => {
  //   try {
  //     const response = await axios.get(`${api_rout}/schedule/get/all`);
  //     let data = response.data.map((item) => {
  //       let payload = {
  //         ...item,
  //         fullName: item.engineerFirstName + " " + item.engineerLastName,
  //       };
  //       return payload;
  //     });
  //     console.log(data, "all schedule");
  //     setData(data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  useEffect(() => {
    // getAllschedule();
    getScheduleListAsPerSubContractor();
  }, []);

  const onButtonClick = (e, row) => {
    e.stopPropagation();
    setClickedRow(row);
  };
  const handleButtonClick = (projectNo) => {
    // Do something with the project ID
    console.log("Clicked project ID:", projectNo);
    // localStorage.setItem("projectId", projectNo);
    navigate("/view_project");
  };
  const handleScheduleButtonClick = (workId, currentStep) => {
    console.log("Clicked work ID and Current Step", workId + " " + currentStep);
    // localStorage.setItem("projectId", projectNo);
    navigate("/workSteps/" + workId + "/" + currentStep);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  let itemList = [];

  const getStatusProgressBar = (rowData) => {
    itemList = [];
    for (let i = 0; i < rowData.totalSteps; i++) {
      const barClass =
        i < rowData.currentStep ? "bg-success text-light" : "text-dark";
      itemList.push(
        <li
          style={{
            border: "1px solid #ccc",
            borderRadius: "32px",
            width: "25%",
            height: "25px",
            marginRight: "3px",
            textAlign: "center",
          }}
          key={i}
          className={barClass}
        >
          Step {i + 1}
        </li>
      );
    }

    return (
      <Box>
        <div>
          <ul
            style={{
              display: "flex",
              listStyle: "none",
              margin: 0,
              padding: 0,
            }}
          >
            {itemList}
          </ul>
        </div>
      </Box>
    );
  };

  const MTcolumn = [
    { field: "slNo", title: "Sl No.", width: 5 },
    { field: "work", title: "work Name", width: 10 },
    { field: "fullName", title: "Name", width: 150,render: (params) => {

      return params.engineerFirstName+" "+params.engineerLastName
    }, },
    {
      field: "scheduleDate",
      title: "Start Date",
      width: 150,
      render: (params) => {
        // console.log(params,"******************************")
        return params.scheduleDate.slice(0, 10)
      },
    },
    {
      field: "EndDate",
      title: "End Date",
      width: 150,
      render: (params) => "-", //params.scheduleDate.slice(0, 10)
    },
    { field: "description", title: "Description", width: 100 },

    {
      field: "totalSteps",
      title: "Steps",
      width: 150,
      // valueGetter: (params) => params.row.id,
      render: getStatusProgressBar,
    },
    {
      field: "action",
      title: "Actions",
      width: 100,
      // valueGetter: (params) => params.row.id,
      render: (params) => {
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "100px",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                minWidth: "auto",
                marginRight: "5px",
                color: colors.grey[300],
                // backgroundColor: colors.primary[500],
              },
            }}
          >
            <div className="grid-actionsWrap">
              <Button
                onClick={() =>
                  handleScheduleButtonClick(params.workId, params.currentStep)
                }
                variant="contained"
              >
                <VisibilityOutlinedIcon />
              </Button>
              <Button
                // onClick={(e) => onButtonClick(e, params)}
                variant="contained"
              >
                <DeleteIcon />
              </Button>
              <Button
                onClick={(e) => onButtonClick(e, params)}
                variant="contained"
              >
                <EditOutlinedIcon />
              </Button>
            </div>
          </Box>
        );
      },
      width: 120,
    },
  ];

  const ConcreteMTcolumn = [
    { field: "id", title: "ID", width: 5 },
    { field: "work", title: "work", width: 10 },
    { field: "fullName", title: "Name", width: 150 },
    {
      field: "action",
      title: "Actions",
      width: 100,
      // valueGetter: (params) => params.row.id,
      render: (params) => {
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "100px",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                minWidth: "auto",
                marginRight: "5px",
                color: colors.grey[300],
                // backgroundColor: colors.primary[500],
              },
            }}
          >
            <div className="grid-actionsWrap">
              <Button
                onClick={() => navigate("/concreteBatchView")}
                variant="contained"
              >
                <VisibilityOutlinedIcon />
              </Button>
              <Button
                onClick={(e) => onButtonClick(e, params)}
                variant="contained"
              >
                <EditOutlinedIcon />
              </Button>
            </div>
          </Box>
        );
      },
      width: 120,
    },
  ];

  const CSMcolumn = [
    { field: "id", title: "ID", width: 5 },
    { field: "createdBy", title: "createdBy", width: 10 },
    {
      field: "status",
      title: "status",
      width: 150,
      render: (params) => {
        return (
          <Box>
            <div
              style={{
                background: `${
                  params.status === "completed"
                    ? "green"
                    : params.status === "pending"
                    ? "red"
                    : "yellow"
                }`,
                color: "white",
              }}
            >
              {params.status}
            </div>
          </Box>
        );
      },
    },
    {
      field: "action",
      title: "Actions",
      width: 100,
      // valueGetter: (params) => params.row.id,
      render: (params) => {
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "100px",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                minWidth: "auto",
                marginRight: "5px",
                color: colors.grey[300],
                // backgroundColor: colors.primary[500],
              },
            }}
          >
            <div className="grid-actionsWrap">
              <Button
                onClick={() => handleButtonClick(params.projectNo)}
                variant="contained"
              >
                <VisibilityOutlinedIcon />
              </Button>
              <Button
                onClick={(e) => onButtonClick(e, params)}
                variant="contained"
              >
                <EditOutlinedIcon />
              </Button>
            </div>
          </Box>
        );
      },
      width: 120,
    },
  ];

  return (
    <Box>
      <Box
        m="40px 0 0 0"
        sx={{
          "& .MuiDataGrid-root": {
            fontSize: "14px",
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-root p": {
            margin: 0,
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none !important",
            backgroundColor: colors.grey[900],
            fontSize: "14px",
          },
          "& .name-column--cell": {
            color: colors.primary[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[900],
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.grey[600],
          },
          "& .MuiDataGrid-footerContainer": {
            borderColor: colors.grey[800],
            backgroundColor: colors.grey[900],
          },
          "& .user_list_title": {
            color: colors.grey[300],
            fontSize: "22px",
            fontWeight: 600,
          },
          "& .user_list_gridWrap": {
            backgroundColor: colors.grey[800],
          },
          "& .user_list_search": {
            backgroundColor: colors.grey[900],
          },
          "& .user_list_search input": {
            color: colors.grey[200],
          },
          "& .grid-actionsWrap": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          },
          "& .grid-actionsWrap Button": {
            fontSize: "13px",
            padding: "4px",
            minWidth: "auto",
            marginRight: "5px",
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Button
          disabled={Number(roleId) === 5 ? false : true}
          onClick={() =>
            navigate(`/Addschedule`, {
              state: {
                id: subContract.id,
              },
            })
          }
          variant="contained"
        >
          Add Schedule
        </Button>
        <MaterialTable
          title={<h2 className="user_list_title">Schedule List</h2>}
          columns={MTcolumn}
          data={scheduleList}
        />

        {/* <Box sx={{ width: "100%", marginTop: "30px" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Concrete" {...a11yProps(0)} />
              <Tab label="CLSM" {...a11yProps(1)} />
              <Tab label="Work" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <MaterialTable
              title={<h2 className="user_list_title">Concrete List</h2>}
              columns={ConcreteMTcolumn}
              data={data}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <MaterialTable
              title={<h2 className="user_list_title">CLSM List</h2>}
              columns={CSMcolumn}
              data={data}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            Item Three
          </CustomTabPanel>
        </Box> */}
      </Box>
    </Box>
  );
}
