import MaterialTable from "@material-table/core";
import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import { useEffect } from "react";
import axios from "axios";
import { api_rout } from "../../Api-Config/config";
import { idID } from "@mui/material/locale";

export default function Technician_TaskList() {
  const [data, setData] = useState();
  const [clickedRow, setClickedRow] = React.useState();
  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const onButtonClick = (e, row) => {
    e.stopPropagation();
    setClickedRow(row);
  };

// For stapper 
let itemList = [];

const getStatusProgressBar = (rowData) => {
  itemList = [];
  for (let i = 0; i < rowData.totalSteps; i++) {
    const barClass =
      i < rowData.currentStep ? "bg-success text-light" : "text-dark";
    itemList.push(
      <li
        style={{
          border: "1px solid #ccc",
          borderRadius: "32px",
          width: "25%",
          height: "25px",
          marginRight: "3px",
          textAlign: "center",
        }}
        key={i}
        className={barClass}
      >
        Step {i + 1}
      </li>
    );
  }

  return (
    <Box>
      <div>
        <ul
          style={{
            display: "flex",
            listStyle: "none",
            margin: 0,
            padding: 0,
          }}
        >
          {itemList}
        </ul>
      </div>
    </Box>
  );
};


  const handleButtonClick = (projectNo,work,id) => {
    // Do something with the project ID
    console.log("Clicked project ID:", projectNo,"work",work);
    // localStorage.setItem("projectId", projectNo);
    if(work === "take photo video"){
      navigate("/camera",{
        state: {
          id: id,
        },
      });


    }
    else{

      navigate("/addConcreteMixDesign/"+id);
    }
    
  };

  const getAllschedule = async () => {
    try {
      const response = await axios.get(`${api_rout}/schedule/get/all`);
      let data = response.data.map((item,i) => {
        let payload = {
          ...item,
          fullName: item.engineerFirstName + " " + item.engineerLastName,
          slNo:i+1
        };
        return payload;
      });
      console.log(data, "all schedule");
      setData(data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAllschedule();
  }, []);

  const CSMcolumn = [
    { field: "slNo", title: "Sl. No", width: 5 },
    { field: "fullName", title: "Engineer Name", width: 5 },
    { field: "work", title: "Work", width: 10,
  render:(params) => {
    return (
      <p style={{textTransform:"capitalize"}}>{params.work}</p>
    )
  }
  },
    { field: "description", title: "Description", width: 100 },
    { field: "scheduleDate", title: "Start Date", width: 10,
  render:(params)=>{
    return params.scheduleDate.slice(0,10)
  }
  },
    { field: "slNo", title: "Enad Date", width: 10,
  render:(params)=>{
    // return params.scheduleDate.slice(0,10)
    return "-"
  }
  },
 

    {
      field: "status",
      title: "Status",
      width: 150,
      render: (params) => {
        return (
          <Box>
            <div
              style={{
                textTransform:"capitalize",
                color: `${
                  params.status === "completed"
                    ? "green"
                    : params.status === "pending"
                    ? "red"
                    : "orange"
                }`,
                // color: "white",
              }}
            >
              {params.status}
            </div>
          </Box>
        );
      },
    },
    {
      field: "totalSteps",
      title: "Steps",
      width: 150,
      // valueGetter: (params) => params.row.id,
      render: getStatusProgressBar,
    },
    {
      field: "action",
      title: "Actions",
      width: 100,
      // valueGetter: (params) => params.row.id,
      render: (params) => {
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "100px",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                minWidth: "auto",
                marginRight: "5px",
                color: colors.grey[300],
                // backgroundColor: colors.primary[500],
              },
            }}
          >
            <div className="grid-actionsWrap">
              <Button
                onClick={() => handleButtonClick(params.projectNo,params.work,params.id)}
                variant="contained"
              >
                <VisibilityOutlinedIcon />
              </Button>
              <Button
                onClick={(e) => onButtonClick(e, params)}
                variant="contained"
              >
                <EditOutlinedIcon />
              </Button>
            </div>
          </Box>
        );
      },
      width: 120,
    },
  ];
  return (
    <Box>
      <Box
        m="40px 0 0 0"
        sx={{
          "& .MuiDataGrid-root": {
            fontSize: "14px",
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-root p": {
            margin: 0,
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none !important",
            backgroundColor: colors.grey[900],
            fontSize: "14px",
          },
          "& .name-column--cell": {
            color: colors.primary[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[900],
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.grey[600],
          },
          "& .MuiDataGrid-footerContainer": {
            borderColor: colors.grey[800],
            backgroundColor: colors.grey[900],
          },
          "& .user_list_title": {
            color: colors.grey[300],
            fontSize: "22px",
            fontWeight: 600,
          },
          "& .user_list_gridWrap": {
            backgroundColor: colors.grey[800],
          },
          "& .user_list_search": {
            backgroundColor: colors.grey[900],
          },
          "& .user_list_search input": {
            color: colors.grey[200],
          },
          "& .grid-actionsWrap": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          },
          "& .grid-actionsWrap Button": {
            fontSize: "13px",
            padding: "4px",
            minWidth: "auto",
            marginRight: "5px",
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <MaterialTable
          title={<h2 className="user_list_title">Task List</h2>}
          columns={CSMcolumn}
          data={data}
        />
      </Box>
    </Box>
  );
}
