import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Typography, useTheme } from "@mui/material";
import { Button } from "@mui/material";
import { tokens } from "../../theme";
// import { mockProjectsData } from "../../data/mockdata";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import React, { useEffect, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router-dom";
import apiService from "../../data/apiService";
import { openDB } from "idb";
import { CSVLink } from "react-csv";

// Material Table
import MaterialTable from "@material-table/core";
import  ExportCsv  from "@material-table/exporters/csv";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { api_rout } from "../../Api-Config/config";


const Projects_list = () => {
 
  const { user, isLoading } = useSelector((state) => state.user);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [clickedRow, setClickedRow] = React.useState();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const navigate = useNavigate();
  const onButtonClick = (e, row) => {
    e.stopPropagation();
    setClickedRow(row);
  };
  let userId = localStorage.getItem('userId')
  const getAssignmentByuserid = async () => {
    try {
        const response = await axios.get(`${api_rout}/assignment/get/by/user/${userId}`);
        console.log(response.data);
        return response.data;
    }
    catch (err) {
        console.log(err)
    }
}


  useEffect(() => {
    const fetchProjectsList = async () => {
      try {
        const response = await apiService.projectListData();
        setTableData(response.data);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjectsList();
    // fetch("https://sig.eastus.cloudapp.azure.com/backend/project/get/all")
    //   .then((data) => data.json())
    //   .then((data) => setTableData(data));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let assmnt = await getAssignmentByuserid();

      try {
        const db = await openDatabase(); // Open the IndexedDB database
        const transaction = db.transaction(["projects"], "readonly");
        const objectStore = transaction.objectStore("projects");
        const request = objectStore.getAll();

        request.onsuccess = () => {
          const result = request.result;
          if(user.designation === "Admin"){
            setData(result)
          }
          else {

            const combineProject =  assmnt.map((assignment) => {
            let filterProject = result.filter(project => assignment.projectId === project.id);
            console.log(filterProject);
            return filterProject[0];
            });
            console.log(combineProject);
            setData( combineProject);
          }
        };

        request.onerror = (event) => {
          console.error(
            "Error retrieving data from IndexedDB",
            event.target.error
          );
        };
      } catch (error) {
        console.error("Error opening IndexedDB database", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleNetworkChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);

    return () => {
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
    };
  }, []);

  const handleButtonClick = (projectNo) => {
    // Do something with the project ID
    console.log("Clicked project ID:", projectNo);
    localStorage.setItem("projectId", projectNo);
    navigate("/view_project");
  };

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "projectNo", headerName: "Project Number" },
    { field: "projectName", headerName: "Project Name", width: 120 },
    { field: "clientId", headerName: "Client" },
    { field: "createdBy", headerName: "Created By", width: 120 },
    { field: "createdDate", headerName: "Created On", width: 200},
    { field: "countryId", headerName: "Country", width: 120 },
    { field: "city", headerName: "City", width: 120 },
    {
      field: "action",
      headerName: "Actions",
      // valueGetter: (params) => params.row.id,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                minWidth: "auto",
                marginRight: "5px",
                color: colors.grey[300],
                // backgroundColor: colors.primary[500],
              },
            }}
          >
            <div className="grid-actionsWrap">
              <Button
                onClick={() => handleButtonClick(params.row.projectNo)}
                variant="contained"
              >
                <VisibilityOutlinedIcon />
              </Button>
              <Button
                onClick={(e) => onButtonClick(e, params.row)}
                variant="contained"
              >
                <EditOutlinedIcon />
              </Button>
              <Button
                onClick={(e) => onButtonClick(e, params.row)}
                variant="contained"
              >
                <DeleteOutlineOutlinedIcon />
              </Button>
            </div>
          </Box>
        );
      },
      width: 120,
    },
  ];


  const MTcolumn = [
    { field: "id", title: "ID" },
    { field: "projectNo", title: "Project Number" },
    { field: "projectName", title: "Project Name", width: 120 },
    { field: "clientId", title: "Client" },
    { field: "createdBy", title: "Created By", width: 120 },
    { field: "createdDate", title: "Created On", width: 200,
    render: (params) =>{
      return params.createdDate ? params.createdDate.slice(0, 10) : "_"
      // return params.createdDate.slice(0, 10)
     }
  },
    { field: "countryId", title: "Country", width: 120 },
    { field: "city", title: "City", width: 120 },
    {
      field: "action",
      title: "Actions",
      // valueGetter: (params) => params.row.id,
      render: (params) => {
        
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                minWidth: "auto",
                marginRight: "5px",
                color: colors.grey[300],
                // backgroundColor: colors.primary[500],
              },
            }}
          >
            <div className="grid-actionsWrap">
              <Button
                onClick={() => handleButtonClick(params.projectNo)}
                variant="contained"
              >
                <VisibilityOutlinedIcon />
              </Button>
              <Button
                onClick={(e) => onButtonClick(e, params)}
                variant="contained"
              >
                <EditOutlinedIcon />
              </Button>
              <Button
                onClick={(e) => onButtonClick(e, params)}
                variant="contained"
              >
                <DeleteOutlineOutlinedIcon />
              </Button>
            </div>
          </Box>
        );
      },
      width: 120,
    },
  ];
  return (
    <Box>
      <Box
        m="40px 0 0 0"
        sx={{
          "& .MuiDataGrid-root": {
            fontSize: "14px",
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-root p": {
            margin: 0,
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none !important",
            backgroundColor: colors.grey[900],
            fontSize: "14px",
          },
          "& .name-column--cell": {
            color: colors.primary[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[900],
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.grey[600],
          },
          "& .MuiDataGrid-footerContainer": {
            borderColor: colors.grey[800],
            backgroundColor: colors.grey[900],
          },
          "& .user_list_title": {
            color: colors.grey[300],
            fontSize: "22px",
            fontWeight: 600,
          },
          "& .user_list_gridWrap": {
            backgroundColor: colors.grey[800],
          },
          "& .user_list_search": {
            backgroundColor: colors.grey[900],
          },
          "& .user_list_search input": {
            color: colors.grey[200],
          },
          "& .grid-actionsWrap": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          },
          "& .grid-actionsWrap Button": {
            fontSize: "13px",
            padding: "4px",
            minWidth: "auto",
            marginRight: "5px",
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        {/* <h2 className="user_list_title">Projects</h2> */}
        <div className="user_list_gridWrap">
          {/* <div className="user_list_filterWrap">
            <div className="row flex-row-reverse">
              <div className="col-md-6">
                <div className="user_list_filter_rt">
                  <div className="user_list_search">
                    <SearchOutlinedIcon />
                    <input type="text" />
                  </div>
                  <CSVLink data={data} filename="Projects_Data.csv">
                    <div className="user_list_btn export">
                      Export to CSV <FileDownloadOutlinedIcon />
                    </div>
                  </CSVLink>
                </div>
              </div>
              <div className="col-md-6">
                <div className="user_list_filter_lt">
                  <div className="user_list_btn delete">
                    Delete <DeleteOutlineOutlinedIcon />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="projectListWrap">
            {isOnline ? (
              // <DataGrid
              //   rows={data}
              //   className="withtableData"
              //   columns={columns}
              //   initialState={{
              //     pagination: {
              //       paginationModel: { page: 0, pageSize: 5 },
              //     },
              //   }}
              //   pageSizeOptions={[5, 10]}
              //   checkboxSelection
              //   // slots={{ toolbar: GridToolbar }}
              // />
              <MaterialTable
              title={
                <h2 className="user_list_title"> Online Projects</h2> 
              }
              columns={MTcolumn}
              data={data}
              options={{
                exportMenu: [
                  {
                    label: "Export CSV",
                    exportFunc: (cols, datas) => {
                      cols = cols.filter(col => col.field !== 'action')
                      console.log(cols)
                      ExportCsv(cols, data, "Report")
                      // downloadExelFile(transaction)
                    },
                  },
                ]

              }}
              />
            ) : (
              // <DataGrid
              //   rows={tableData}
              //   className="withdata"
              //   columns={columns}
              //   initialState={{
              //     pagination: {
              //       paginationModel: { page: 0, pageSize: 5 },
              //     },
              //   }}
              //   pageSizeOptions={[5, 10]}
              //   checkboxSelection
              // />
              <MaterialTable
              title={
                <Typography variant="h2">
                  Projects
                </Typography>
              }
              columns={MTcolumn}
              data={data}
              options={{
                exportMenu: [
                  {
                    label: "Export CSV",
                    exportFunc: (cols, datas) => {
                      ExportCsv(cols, datas, "Report")
                      // downloadExelFile(transaction)
                    },
                  },
                ],
                filtering: true
              }}
              />
            )}
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Projects_list;

// Helper function to open the IndexedDB database
const openDatabase = () => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open("projectsDB", 1);

    request.onsuccess = () => {
      const db = request.result;
      resolve(db);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      const objectStore = db.createObjectStore("projects", {
        keyPath: "id",
        autoIncrement: true,
      });
      // Define the object store structure if necessary
      // e.g., objectStore.createIndex('name', 'name', { unique: false });
    };
  });
};
