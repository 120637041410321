import { Box, Typography, useTheme } from "@mui/material";
import { Button } from "@mui/material";
import { tokens } from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import axios from "axios";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
// Material Table
import MaterialTable from "@material-table/core";
import { useNavigate } from "react-router-dom";
import { api_rout } from "../../Api-Config/config";

const SubContractorRejList = (props) => {

  const {handleClick,allRejectList}= props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [tableData, setTableData] = useState([]);
  const [clickedRow, setClickedRow] = React.useState();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const onButtonClick = (e, row) => {
    e.stopPropagation();
    setClickedRow(row);
  };

  // useEffect(() => {
  //   fetch(`${api_rout}/approval/get/rejected/by/user/${user.id}`)
  //     .then((data) => data.json())
  //     .then((data) => setTableData(data.data));
  // }, []);

  console.log(tableData);

  const columns = [
    // { field: "slNo", title: "Sl No.", width: 5 },
    { field: "module", title: "Module", width: 10 },
    { field: "subModule", title: "Sub Module", width: 10 },
    { field: "tableName", title: "Work", width: 10 },
    { field: "submittedBy", title: "Author", width: 10 },
    { field: "createdDate", title: "Create On", width: 10,
  render: (params) => {
    return params.createdDate.slice(0, 10)
  }
  },
    // { field: "status", title: "Status", width: 10 },
    {
      field: "action",
      title: "View",
      width: 100,
      // valueGetter: (params) => params.row.id,
      render: (params) => {
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "100px",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                minWidth: "auto",
                marginRight: "5px",
                color: colors.grey[300],
                // backgroundColor: colors.primary[500],
              },
            }}
          >
            <div className="grid-actionsWrap">
              <Button
            //   onClick={()=> navigate("/addConcreteMixDesign", {
            //     state: {
            //       rejectedUUID: params.entryId,
            //       approvalId:params.id,
            //       tableNameCase: params.tableNameCase,
            //     },
            //   })
            // }

            onClick={()=> handleClick(params.entryId,params.id,params.tableNameCase,params.tableName)}
              >
                <VisibilityOutlinedIcon />
              </Button>
            </div>
          </Box>
        );
      },
      width: 60,
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          "& button": {
            marginRight: "10px",
          },
          "& .MuiDataGrid-root": {
            fontSize: "14px",
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-root p": {
            margin: 0,
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none !important",
            backgroundColor: colors.grey[900],
            fontSize: "14px",
          },
          "& .name-column--cell": {
            color: colors.primary[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[900],
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.grey[600],
          },
          "& .MuiDataGrid-footerContainer": {
            borderColor: colors.grey[800],
            backgroundColor: colors.grey[900],
          },
          "& .user_list_title": {
            color: colors.grey[300],
            fontSize: "22px",
            fontWeight: 600,
            margin: 0
          },
          "& .user_list_gridWrap": {
            backgroundColor: colors.grey[800],
          },
          "& .user_list_search": {
            backgroundColor: colors.grey[900],
          },
          "& .user_list_search input": {
            color: colors.grey[200],
          },
          "& .grid-actionsWrap": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          },
          "& .grid-actionsWrap Button": {
            fontSize: "13px",
            padding: "4px",
            minWidth: "auto",
            marginRight: "5px",
            backgroundColor: "transparent",
            boxShadow: "none",
          },
          "& .user_list_gridWrap .css-ig9rso-MuiToolbar-root": {
            minHeight: "auto",
            padding: "8px 15px",
          },
          "& .user_list_gridWrap table td": {
            padding: "10px 16px",
          },
        }}
      >
        <div className="user_list_gridWrap">
          <MaterialTable
            style={{ border: "1px solid #ddd", boxShadow: "none" }}
            title={<h2 className="user_list_title">Rejected Work</h2>}
            columns={columns}
            data={allRejectList}
            pageSize={25}
            // initialState={{
            //   pagination: {
            //     paginationModel: { page: 0, pageSize: 5 },
            //   },
            // }}
            // pageSizeOptions={[5, 10]}
            // checkboxSelection
            options={{
              pageSize: 5,
              pageSizeOptions: [5, 10, 20, 30],
              rowStyle: {
                height: "12px",
              },
              headerStyle: {
                backgroundColor: "#01579b",
                color: "#FFF",
              },
            }}
          />
        </div>
      </Box>
    </Box>
  );
};

export default SubContractorRejList;
