import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ColorModeContext, tokens, useMode } from "./theme";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
// import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
// import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
// import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
// import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
// import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
// import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonIcon from "@mui/icons-material/Person";
import Topbar from "./general/global/topbar";
import { useEffect } from "react";
import Users from "./pages/users";
import SignInSide from "./general/login";
import SignUp from "./general/SignUp";
import CompleteSignup from "./general/complete_SignUp";
import ForgotPass from "./general/forgotPass";
import ResetPass from "./general/resetPass";
import Dashboard from "./general/dashboard";
import Add_user from "./components/users/add_user";
import Add_role from "./components/roles/add_role";
import Add_module from "./components/modules/add_module";
import Projects from "./pages/projects";
import Add_project from "./components/projects/add_project";
import View_project from "./components/projects/view_project";
import Camera from "./general/camera";
import Schedule from "./pages/Schedule";
import AddSchedule from "./pages/Scdule/createSchedule";
import ScheduleStep from "./pages/Progress/ScduleStep";
import { openDB } from "idb";
import apiService from "./data/apiService";
import VideoRecoder from "./general/VideoRecoder";
import AddConstProject from "./components/records/consctructionInfo";
import AddConcreteMix from "./components/records/concreteMix";
import AddConcreteBatch from "./components/records/concreteBatch";
import ClientsCard from "./components/clients/clientsCard";
import View_concreteBatch from "./components/records/View_concreteBatch";
import View_concreteMix from "./components/records/View_concreteMix";
import Profile from "./general/Profile";
import ProjectView from "./components/projects/projectView";
import ProjectInfo from "./components/projects/projectInfo";
import ContractorDash from "./components/projects/contractorDash";
import SubContractorDash from "./components/projects/subContractorDash";
import EngineerDash from "./components/projects/engineerDash";
import ConcreteBatchView from "./components/tests/concreteBatchView";

import Email_list from "./components/email/email_list";
import Email_add from "./components/email/email_add";
import Invite_user from "./components/invite_user/invite_user";
import SubContractForm from "./components/Sub-Contract/SubContractForm/SubContractForm";
import ProjectDetails from "./components/projects/clientDash";
import Add_schedule from "./components/schedules/add_schedule";
import WorkSteps from "./components/work/workSteps";
import AllEngineer_view_page from "./general/AllEngineer_view_page";
import Existing_user_invite from "./pages/Invite/Existing_user_invite";
import Create_subCotractor from "./pages/Invite/Create_subCotractor";
import ViewConcreteMix from "./pages/ViewConcreteMix";
import QCDashBoard from "./pages/QCDashBoard";
import { QCVirifivcationList } from "./pages/QCDashBoard/QCWorkListVarification";
import AddConcreteMixDesign from "./pages/addConcreteMixDesign/addConcreteMixDesign";
import ViewConcreteMixDesign from "./pages/addConcreteMixDesign/viewConcreteMixDesign";
import ConcreteSpecimen from "./pages/concreteSpecimen/concreteSpecimen";
import QADashBoard from "./pages/QADashBoard";
import ConcreteCompressiveStrength from "./pages/concreteCompressiveStrength/concreteCompressiveStrength";
import ChangeHeightCylSpecimen from "./pages/changeHeightCylSpecimen/changeHeightCylSpecimen";
import ConcreteBleed from "./pages/concreteBleed/concreteBleed";
import DensityYieldAircontent from "./pages/densityYieldAircontent/densityYieldAircontent";
import ConcPenetrationResist from "./pages/setTimeConcPenetrationResist/setTimeConcPenetrationResist";
import SlumpHCC from "./pages/slumpHCC/slumpHCC";
import TempFreshHydrolicCementConc from "./pages/tempFreshHydrolicCementConc/tempFreshHydrolicCementConc";
import WaterChemAnalysis from "./pages/waterChemAnalysis/waterChemAnalysis";
import WaterSolids from "./pages/waterSolids/waterSolids";
import { CameraAndVideoCapture } from "./pages/CameraAndVideoCapture";

function App() {
  const [theme, colorMode] = useMode();

  useEffect(() => {
    const fetchData = async () => {
      const response = await apiService.projectListData(); // Replace with your API endpoint
      const projects = response.data;

      const db = await openDB("projectsDB", 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains("projects")) {
            db.createObjectStore("projects", { keyPath: "id" });
          }
        },
      });

      const transaction = db.transaction("projects", "readwrite");
      const store = transaction.objectStore("projects");

      projects.forEach((project) => {
        store.put(project);
      });

      console.log("projects added to IndexedDB:", projects);
    };

    fetchData();
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box>
          <div id="app" style={({ height: "100vh" }, { display: "flex" })}>
            <main className="content">
              <Router>
                <Routes>
                  <Route path="/" element={<SignInSide />}></Route>
                  <Route path="/SignUp" element={<SignUp />}></Route>
                  <Route
                    path="/complete/SignUp"
                    element={<CompleteSignup />}
                  ></Route>
                  <Route path="/dashboard" element={<Dashboard />}></Route>
                  <Route path="/forgotPass" element={<ForgotPass />}></Route>
                  <Route path="/forgotPass/:id" element={<ForgotPass />}></Route>
                  <Route path="/resetPass" element={<ResetPass />}></Route>
                  <Route path="/users" element={<Users />}></Route>
                  <Route path="/add_user" element={<Add_user />}></Route>
                  <Route path="/add_role" element={<Add_role />}></Route>
                  <Route path="/add_module" element={<Add_module />}></Route>
                  <Route path="/projects" element={<Projects />}></Route>
                  <Route path="/add_project" element={<Add_project />}></Route>
                  <Route
                    path="/view_project"
                    element={<View_project />}
                  ></Route>
                  <Route path="/camera" element={<Camera />}></Route>
                  <Route path="/Video" element={<VideoRecoder />}></Route>
                  <Route path="/schedule" element={<Schedule />}></Route>
                  <Route path="/create" element={<AddSchedule />}></Route>
                  <Route path="/progress" element={<ScheduleStep />}></Route>
                  <Route
                    path="/consctructionInfo"
                    element={<AddConstProject />}
                  ></Route>
                  <Route
                    path="/concreteMix"
                    element={<AddConcreteMix />}
                  ></Route>
                  <Route
                    path="/concreteBatch"
                    element={<AddConcreteBatch />}
                  ></Route>
                  <Route path="/clientsCard" element={<ClientsCard />}></Route>
                  <Route path="/viewconcreteBatch/:id" element={<View_concreteBatch />}></Route>
                  <Route path="/viewconcreteMix/:id" element={<View_concreteMix />}></Route>
                  <Route path="/profile" element={<Profile />}></Route>

                  <Route path="/email_list" element={<Email_list />}></Route>
                  <Route path="/email_add" element={<Email_add />}></Route>
                  <Route path="/invite/:response" element={<Invite_user />}></Route>
                  <Route path="/invite" element={<Invite_user />}></Route>
                  <Route path="/Exist_invite" element={<Existing_user_invite />}></Route>
                  <Route path="/add_Subcontractor" element={<SubContractForm />}></Route>
                  <Route path="/projectDetails" element={<ProjectDetails />}></Route>
                  <Route path="/projectView" element={<ProjectView />}></Route>
                  <Route path="/projectInfo" element={<ProjectInfo />}></Route>
                  <Route path="/contractorDash" element={<ContractorDash />}></Route>
                  <Route path="/subContractorDash" element={<SubContractorDash />}></Route>
                  <Route path="/engineerDash" element={<EngineerDash />}></Route>
                  <Route path="/concreteBatchView" element={<ConcreteBatchView />}></Route>
                  <Route path="/engineerDash/:id" element={<EngineerDash />}></Route>
                  <Route path="/Addschedule" element={<Add_schedule />}></Route>
                  <Route path="/workSteps" element={<WorkSteps />}></Route>
                  <Route path="/workSteps/:WorkID/:CurrentStep" element={<WorkSteps />}></Route>
                  {/* <Route path="/Addschedule/:id" element={<Add_schedule />}></Route> */}
                  <Route path="/engineer_View_page" element={<AllEngineer_view_page />}></Route>
                  <Route path="/add_sub-contract" element={<Create_subCotractor/>}></Route>
                  <Route path="/view_cocrete_mix" element={<ViewConcreteMix/>}></Route>
                  <Route path="/QCDash" element={<QCDashBoard/>}></Route>
                  <Route path="/QCDash/list" element={<QCVirifivcationList/>}></Route>
                  <Route path="/addConcreteMixDesign" element={<AddConcreteMixDesign/>}></Route>
                  <Route path="/addConcreteMixDesign/:id" element={<AddConcreteMixDesign/>}></Route>
                  <Route path="/viewConcreteMixDesign" element={<ViewConcreteMixDesign/>}></Route>
                  <Route path="/concreteSpecimen" element={<ConcreteSpecimen/>}></Route>
                  <Route path="/concreteCompressiveStrength" element={<ConcreteCompressiveStrength/>}></Route>
                  <Route path="/changeHeightCylSpecimen" element={<ChangeHeightCylSpecimen/>}></Route>
                  <Route path="/concreteBleed" element={<ConcreteBleed/>}></Route>
                  <Route path="/densityYieldAircontent" element={<DensityYieldAircontent/>}></Route>
                  <Route path="/concPenetrationResist" element={<ConcPenetrationResist/>}></Route>
                  <Route path="/slumpHCC" element={<SlumpHCC/>}></Route>
                  <Route path="/tempFreshHydrolicCementConc" element={<TempFreshHydrolicCementConc/>}></Route>
                  <Route path="/waterChemAnalysis" element={<WaterChemAnalysis/>}></Route>
                  <Route path="/waterSolids" element={<WaterSolids/>}></Route>
                  <Route path="/QADash" element={<QADashBoard/>}></Route>
                  <Route path="/CameraAndVideoCapture" element={<CameraAndVideoCapture/>}></Route>

                </Routes>
              </Router>
            </main>
          </div>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
