import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import { Box, Button, Paper, Typography } from "@mui/material";
import ProjectInfo from "../../components/projects/projectInfo";
import Reports from "../../components/projects/cards/reports";
import ApproveList from "../../components/ApproveListForQCandQA";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { api_rout } from "../../Api-Config/config";
import ApproveListModal from "../../components/ApproveListForQCandQA/ApproveListModal";
import ProjectIfoOnAccordion from "../../components/projects/ProjectIfoOnAccordion";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import MessagesCard from "../../components/messages/messagesCard";
import DataManagerRejCard from "../../components/dataManager/dataManagerRejCard";
import { RejectAndApproveListModal } from "../../components/ApproveListForQCandQA/RejectAndApproveListModal";
export default function QCDashBoard() {
  const { user } = useSelector((state) => state.user);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [rows, setRows] = useState();

  // some more data send to the modal
  const [more, setMore] = useState();

  // State for Open Modal
  const [open, setOpen] = useState(false);

  const [totalVerifier,seTotalVerifier] = useState([]);
  const [totalApproved,setTotalApproved] = useState([]);
  const [totalRejected,setTotalRejected] = useState([]);

  const [openRejectAndApproveListModal,setOpenRejectAndApproveListModal] = useState(false)
  const [showList,setShowList] = useState(3);
  // function for close the modal

  const handleColseApprovalListModal = () => {
    setOpenRejectAndApproveListModal(false)
  }

  const handleClose = () => {
    setOpen(false);

  };

  // const getAllWorkData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${api_rout}/scheduleactivity/all/approval/${Number(
  //         localStorage.getItem("projectId")
  //       )}`
  //     );
  //     console.log(response.data);
  //     const data = response.data.map((item, i) => {
  //       return { ...item, slNo: i + 1 };
  //     });

  //     setData(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getAllWorkData = async () => {
    try {
      const response = await axios.get(`${api_rout}/approval/get/all`);
      console.log(response.data.data);
      const data = response.data.data.filter(
        (item) => item.status === "pending" 
        // || item.status === "resubmitted"
      );
      const approved = response.data.data.filter(
        (item) => item.status === "approved"
      );
      const rejected = response.data.data.filter(
        (item) => item.status === "rejected"
      );
      const verifier = response.data.data.filter(
        (item) => item.status !== "resubmitted"
      );
      seTotalVerifier(verifier);
      setTotalApproved(approved);
      setTotalRejected(rejected);
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlgetWorkByFormId = async (params) => {
    //.tableNameCase, params.entryId
    try {
      setOpen(true);
      const response = await axios.get(
        `${api_rout}/approval/get/enrty/details/${params.tableNameCase}/${params.entryId}`
      );
      console.log(response.data.data);
      // const data = response.data.map((item,i)=> {
      //     return {...item,slNo:i+1}
      // })
      const {
        mixDescription,
        wcRatio,
        water,
        sand,
        sNo8,
        sNo67,
        sNo4,
        s3In,
        flyAsh,
        comments,
      } = response.data.data[response.data.data.length-1];

      setMore({
        tableId: params.entryId, // entryId

        approvalId: params.id, // id
        status:params.status, // status
        tableNameCase:params.tableNameCase
      });

      setRows({
        mixDescription,
        wcRatio,
        water,
        sand,
        sNo8,
        sNo67,
        sNo4,
        s3In,
        flyAsh,
        comments,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleQCSubmit = async (status, comments) => {
    console.log("status: " + status, "comments: " + comments);
    try {
      const payload = {
        tableId: more.tableId,

        approvalId: more.approvalId, // id

        approvalStatus: status, // status 1 = approved, 0 = rejected

        approverId: user.id, // qc userid

        comment: comments, // comment from qc
      };
      // console.log(payload,"approve payload")
      const response = await axios.patch(
        `${api_rout}/approval/edit/qc/status/${more.tableNameCase}`,
        payload
      );
      console.log(response.data);
      getAllWorkData();
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllWorkData();
  }, []);

  useEffect(() => {
    console.log(rows);
  }, [rows, open]);

  const NewColumn = [
    // { field: "slNo", title: "Sl No.", width: 5 },
    { field: "module", title: "Module", width: 10 },
    { field: "subModule", title: "Sub Module", width: 10 },
    { field: "tableName", title: "Work", width: 10 },
    { field: "createdDate", title: "Create On", width: 10 },
    { field: "status", title: "Status", width: 10 },
    { field: "submittedBy", title: "Author", width: 10 },

    // { field: "date", title: "Scheduled Date", width: 5 },
    // { field: "scheduleId", title: "Mix Id", width: 5 },
    // { field: "version", title: "Version.", width: 5 },
    // { field: "formName", title: "Form Name", width: 10 },

    {
      field: "action",
      title: "View",
      width: 100,
      // valueGetter: (params) => params.row.id,
      render: (params) => {
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "100px",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                minWidth: "auto",
                marginRight: "5px",
                color: colors.grey[300],
                // backgroundColor: colors.primary[500],
              },
            }}
          >
            <div className="grid-actionsWrap">
              <Button
                onClick={
                  () => handlgetWorkByFormId(params)
                  // navigate("/QCDash/list", {
                  //   state: {
                  //     id: params.formId,
                  //   },
                  // })
                  // {
                  //     setOpen(true);
                  //     const {mixDescription,wcRatio,water,sand,sNo8,sNo67,sNo4,s3In,flyAsh,comments} = params

                  //     setRows({mixDescription,wcRatio,water,sand,sNo8,sNo67,sNo4,s3In,flyAsh,comments})
                  // }
                }
                variant="contained"
              >
                <VisibilityOutlinedIcon />
              </Button>
              {/* <Button
                    onClick={(e) => onButtonClick(e, params)}
                    variant="contained"
                  >
                    <EditOutlinedIcon />
                  </Button> */}
            </div>
          </Box>
        );
      },
      width: 60,
    },
  ];
  return (
    <MainLayout>
      <Box
        sx={{
          p: 3,
          backgroundColor: "#f9f9f9",
          "& h5": {
            fontSize: "16px",
            fontWeight: "600",
          },
        }}
      >
        <div className="user_cards_wrap container">
          <div className="row">
            <div className="col-md-9 col-12 mb-4">
              <ProjectIfoOnAccordion />
            </div>
            <div className="col-md-3 col-12 mb-4">
              <Paper
                sx={{
                  padding: "10px",
                  alignItems: "center",
                  gap: "10px",
                  border: "1px solid #d9d9d9",
                  boxShadow: "none",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h3"
                    gutterBottom
                    style={{ fontSize: "19px", margin: 0 }}
                  >
                    {+" " + user && user.firstName + " " + user.lastName}
                  </Typography>
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ fontSize: "16px", margin: 0 }}
                  >
                    (
                    {localStorage.getItem("role")
                      ? localStorage.getItem("role").charAt(0).toUpperCase() +
                        localStorage.getItem("role").slice(1)
                      : ""}
                    )
                  </Typography>
                </Box>
                <Box>
                  {/* <p><strong>Current Login:</strong> {new Date(Date.now()).toLocaleString()}</p> */}
                  <p style={{ margin: 0 }}>
                    <strong>Last Login:</strong> 8/11/2023, 3:01:32 PM
                  </p>
                </Box>
              </Paper>
            </div>
            {/* <ProjectInfo /> */}
            <div className="col-md-4 col-12 mb-4">
              <MessagesCard />
            </div>
            <div className="col-md-4 col-12 mb-4">
              <DataManagerRejCard 
              totalVerifier={totalVerifier}
              totalApproved={totalApproved}
              totalRejected={totalRejected}
              totlaPending = {data}
              setOpen = {setOpenRejectAndApproveListModal}
              setShowList={setShowList}
              />
            </div>
            <div className="col-md-12 col-12 mb-4">
              <ApproveList
                title={showList===3 ? "Pending Approvals" : (showList===0 ? "Rejected List":  "Approved List")}
                columns={NewColumn}
                data={showList===3 ? data : (showList===0 ? totalRejected :  totalApproved)}
              />
            </div>
          </div>
        </div>
      </Box>
      <ApproveListModal
        open={open}
        handleClose={handleClose}
        row={rows}
        moreData={more}
        handleSubmit={handleQCSubmit}
        // status={}
      />

      <RejectAndApproveListModal
      open={openRejectAndApproveListModal}
      column={NewColumn}
      Data={showList===0 ? totalRejected : totalApproved}
      handleClose={handleColseApprovalListModal}
      
      />
    </MainLayout>
  );
}
