import { Box, Typography, useTheme } from "@mui/material";
import { Button } from "@mui/material";
import { tokens } from "../../theme";
// import { mockProjectsData } from "../../data/mockdata";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import React, { useEffect, useState } from "react";
import axios from "axios";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
// Material Table
import MaterialTable from "@material-table/core";
import { useNavigate } from "react-router-dom";
import { api_rout } from "../../Api-Config/config";

const AllModulesList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [tableData, setTableData] = useState([]);
  const [clickedRow, setClickedRow] = React.useState();
  const navigate = useNavigate();
  const onButtonClick = (e, row) => {
    e.stopPropagation();
    setClickedRow(row);
  };

  useEffect(() => {
    fetch(`${api_rout}/domain/subcontractor/submodule/get/all`)
      .then((data) => data.json())
      .then((data) => setTableData(data));
  }, []);

  console.log(tableData);

  const columns = [
    // { field: "id", title: "ID", width: 30 },
    { field: "module", title: "Module Name", width: 120 },
    { field: "subModule", title: "Sub Module Name", width: 120 },
    {
      field: "action",
      title: "Sub Contractors",
      render: (params) => {
        let fullName = params.userId ? (
          params.firstName + " " + params.lastName
        ) : (
          <Box>
            <Button
              style={{
                border: "none",
                backgroundColor: "#0D6EFD",
                color: "white",
              }}
              className="btn btn-primery"
              onClick={() => navigate("/Exist_invite",{
                state:{
                  id:params.id,
                }
              })}
            >
              Invite Sub Contractor
            </Button>
          </Box>
        );
        return fullName;
      },
      width: 120,
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          "& button": {
            marginRight: "10px",
          },
          "& .MuiDataGrid-root": {
            fontSize: "14px",
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-root p": {
            margin: 0,
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none !important",
            backgroundColor: colors.grey[900],
            fontSize: "14px",
          },
          "& .name-column--cell": {
            color: colors.primary[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[900],
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.grey[600],
          },
          "& .MuiDataGrid-footerContainer": {
            borderColor: colors.grey[800],
            backgroundColor: colors.grey[900],
          },
          "& .user_list_title": {
            color: colors.grey[300],
            fontSize: "22px",
            fontWeight: 600,
            margin: 0
          },
          "& .user_list_gridWrap": {
            backgroundColor: colors.grey[800],
          },
          "& .user_list_search": {
            backgroundColor: colors.grey[900],
          },
          "& .user_list_search input": {
            color: colors.grey[200],
          },
          "& .grid-actionsWrap": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          },
          "& .grid-actionsWrap Button": {
            fontSize: "13px",
            padding: "4px",
            minWidth: "auto",
            marginRight: "5px",
            backgroundColor: "transparent",
            boxShadow: "none",
          },
          "& .user_list_gridWrap .css-ig9rso-MuiToolbar-root": {
            minHeight: "auto",
            padding: "8px 15px",
          },
          "& .user_list_gridWrap table td": {
            padding: "10px 16px",
          },
        }}
      >
        <div className="user_list_gridWrap">
          <MaterialTable
            style={{ border: "1px solid #ddd", boxShadow: "none" }}
            title={<h2 className="user_list_title">Modules</h2>}
            columns={columns}
            data={tableData.data}
            pageSize={25}
            // initialState={{
            //   pagination: {
            //     paginationModel: { page: 0, pageSize: 5 },
            //   },
            // }}
            // pageSizeOptions={[5, 10]}
            // checkboxSelection
            options={{
              pageSize: 20,
              pageSizeOptions: [10, 20, 30],
              rowStyle: {
                height: "12px",
              },
              headerStyle: {
                backgroundColor: "#01579b",
                color: "#FFF",
              },
            }}
          />
        </div>
      </Box>
    </Box>
  );
};

export default AllModulesList;
