import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Modal from "@mui/material/Modal";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "auto",
  overflow: "auto",
};

export default function ApproveListModal(props) {
  const { open, handleClose, row, moreData, handleSubmit,handleEdit } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = React.useState();
  const [comments, setComments] = React.useState("");

  console.log(moreData, "*** more data");
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        max-width="lg"
      >
        <Box
          sx={{
            ...style,
            maxWidth: "840px",
            width: "80%",
            borderRadius: "6px",
            position: "relative",
            "& .closeModal": {
              position: "absolute",
              top: "8px",
              right: "8px",
              cursor: "pointer",
              backgroundColor: "#333",
              lineHeight: "normal",
              textAlign: "center",
              borderRadius: "4px",
              color: "#fff",
              border: "2px solid #333",
              transition: "all 0.1s"
            },
            "& .closeModal:hover": {
              color: "#333",
              backgroundColor: "#fff"
            }
            // height: "80vh",
          }}
        >
          <div className="closeModal" onClick={() => {
                handleClose();
              }}>
            <CloseOutlinedIcon />
          </div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            <Grid
              container
              spacing={3}
              style={{
                backgroundColor: "#f6f6f6",
                paddingBottom: "15px",
                borderRadius: "4px",
                margin: "5px 0 0",
                width: "100%",
              }}
            >
              {row &&
                Object.entries(row).map(([Label, value]) => (
                  <Grid item md={4} sm={6} xs={12}>
                    <Box sx={{ alignItems: "center", gap: "1rem" }} key={Label}>
                      <h4
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          marginBottom: "3px",
                        }}
                      >
                        {Label.charAt(0).toUpperCase() +
                          Label.slice(1)
                            .split(/(?=[A-Z])/)
                            .join(" ")}
                        :
                      </h4>
                      {/* <h6> {isDate(value) === true ?  getonlyDate(value): value} </h6> */}
                      <h6 style={{ margin: 0 }}> {value} </h6>
                      {/* <h6> {Label} </h6> */}
                    </Box>
                  </Grid>
                ))}
            </Grid>

            { moreData ?  (moreData.status === "pending" || moreData.status === "resubmitted" ? ( <Box
                sx={{
                  width: "100%",
                  padding: "10px 15px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item md={6} sm={12} xs={12}>
                    <FormControl>
                      {/* <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        style={{
                          fontSize: "16px",
                          color: "#000",
                          fontWeight: "600",
                        }}
                      >
                        Approve
                      </FormLabel> */}
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                        style={{
                          display: "flex",
                          flexDirection: "initial",
                        }}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="Approve"
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="Reject"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    {value && (
                      <TextField
                      required
                        id="outlined-multiline-static"
                        label="QC Comments"
                        multiline
                        rows={2}
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                        fullWidth
                        // defaultValue="Default Value"
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>) : ""):""}

              {
                moreData ?  (moreData.status === "pending" || moreData.status === "resubmitted" ? ( <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    padding: "0 15px",
                    justifyContent: "end",
                  }}
                >
                  <Button
                  disabled={comments === "" ? true:false}
                    variant="contained"
                    onClick={() => handleSubmit(value, comments)}
                  >
                    Submit
                  </Button>


                  <Button variant="contained" onClick={handleClose}>
                    Cancel
                  </Button>
                </Box> ) : ""):""
              }
            {/* {moreData ? moreData.status === "pending" ? ( <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  padding: "0 15px",
                  justifyContent: "end",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => handleSubmit(value, comments)}
                >
                  Submit
                </Button>
                <Button variant="contained" onClick={handleClose}>
                  Cancel
                </Button>
              </Box>) : (  moreData.status === "resubmitted" ? :"" ):""
             } */}
            {location.pathname === "/subContractorDash" && (
              <Button variant="contained" onClick={handleEdit}>
                Edit
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
