import React, { useEffect, useState } from "react";
import { TextField, Button, Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MainLayout from "../../layout/MainLayout";
import { EditorState, convertToRaw, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItemMui from "@mui/material/MenuItem";
import EmailFunctionValue from "./email_function_value";
import { ContextMenuTrigger } from "react-contextmenu";
import { ContextMenu, MenuItem } from "react-contextmenu";

function Email_add() {
  const navigate = useNavigate();
  const intialValue = {
    name: "",
    subject: "",
    body: "",
    to: "",
    bcc: "",
    cc: "",
    value: "",
    functionName: "",
  };
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [formValues, setFormValues] = useState(intialValue);
  const [emailfunctions, setEmailFunctions] = useState([]);
  const [functionValue, setFunctionValues] = useState([]);
  const [selectedValuesArray, setSelectedValuesArray] = useState([]);

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setSelectedValuesArray((prevState) => [...prevState, event.target.value]);
      // adding checked value
      if (formValues.value) {
        const test = `${formValues.value},${event.target.value}`;
        setFormValues({ ...formValues, value: test });
      } else {
        setFormValues({ ...formValues, value: event.target.value });
      }
    } else {
      setSelectedValuesArray((prevState) =>
        prevState.filter((value) => value !== event.target.value)
      );
      // removeing unchecked value
      const stringToArray = formValues.value.split(",");
      const filteredArray = stringToArray.filter(
        (item) => item !== event.target.value
      );
      const stringWithoutUncheckedValue = filteredArray.join(",");
      setFormValues({ ...formValues, value: stringWithoutUncheckedValue });
    }
  };

  const handleSelectChange = (event) => {
    if (formValues.value.length > 0) {
      alert(
        "Can only use one function. if you want to select another function please uncheck all the values"
      );
      return;
    }
    const functionSelected = emailfunctions.filter(
      (value) => value.functionName === event.target.value
    );
    const stringToArray = functionSelected[0].values.split(",");
    setFunctionValues(stringToArray);
    setFormValues({ ...formValues, functionName: event.target.value });
  };

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const bodyValue = draftToHtml(
      convertToRaw(newEditorState.getCurrentContent())
    );

    setFormValues({ ...formValues, body: bodyValue });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = () => {
    axios
      .post("https://sig.eastus.cloudapp.azure.com/backend/email/create", {
        ...formValues,
      })
      .then((res) => {
        navigate("/email_list");
      });
  };

  const handleItemClick = (e, data) => {
    const selectionState = editorState.getSelection();
    const currentContent = editorState.getCurrentContent();
    const newValue = `<%= ${data.action.value} %>`;
    const newSelectionState = selectionState.set(
      "anchorOffset",
      selectionState.get("focusOffset")
    );
    // Create a new ContentState with the value you want to insert
    const newContentState = Modifier.insertText(
      currentContent,
      newSelectionState,
      newValue
    );

    // Create a new EditorState with the updated ContentState
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );

    // Set the new EditorState and force selection at the end
    setEditorState(newEditorState);
  };

  useEffect(() => {
    axios
      .get("https://sig.eastus.cloudapp.azure.com/backend/email/all/functions")
      .then((response) => {
        setEmailFunctions(response.data);
      });
  }, []);

  return (
    <MainLayout>
      <Box
        sx={{
          marginTop: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
          "& #images": {
            paddingLeft: "120px",
          },
          "& #video": {
            paddingLeft: "120px",
          },
        }}
      >
        <div className="pageFormWrap">
          <Typography component="h1" variant="h3">
            Add Email Template
          </Typography>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Tempalte Name"
                  name="name"
                  id="name"
                  fullWidth
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Subject"
                  name="subject"
                  id="subject"
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="To"
                  name="to"
                  id="to"
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="CC"
                  name="cc"
                  id="cc"
                  fullWidth
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="BCC"
                  name="bcc"
                  id="bcc"
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Function
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formValues.functionName}
                    label="Function"
                    onChange={handleSelectChange}
                    name="functionName"
                  >
                    {emailfunctions &&
                      emailfunctions.map((value) => (
                        <MenuItemMui key={value.id} value={value.functionName}>
                          {value.alias}
                        </MenuItemMui>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h5>Value</h5>
                {functionValue.length > 0 && (
                  <EmailFunctionValue
                    handleCheckboxChange={handleCheckboxChange}
                    values={functionValue}
                  />
                )}
                {functionValue.length <= 0 && <label>Select a function</label>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Value"
                  name="value"
                  id="value"
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  value={formValues.value}
                  hidden
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <h5>Body</h5>

                <div className="email_body_input">
                  <ContextMenuTrigger id="right-click-menu">
                    <Editor
                      editorState={editorState}
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      toolbarClassName="toolbarClassName"
                      onEditorStateChange={onEditorStateChange}
                      placeholder="Type here......"
                      toolbar={{
                        options: ["inline", "list", "textAlign", "history"],
                        inline: { inDropdown: false },
                        list: { inDropdown: false },
                        textAlign: { inDropdown: false },
                        link: { inDropdown: false },
                        history: { inDropdown: false },
                      }}
                    />
                    <ContextMenu
                      id="right-click-menu"
                      className="email_right_click_menu"
                    >
                      <p className="email_right_click_menu_head">values</p>
                      {selectedValuesArray.length > 0 &&
                        selectedValuesArray.map((value, index) => (
                          <MenuItem
                            onClick={handleItemClick}
                            key={index}
                            data={{ action: { value } }}
                          >
                            {value}
                          </MenuItem>
                        ))}
                      {selectedValuesArray <= 0 && (
                        <label>No values to select</label>
                      )}
                    </ContextMenu>
                  </ContextMenuTrigger>
                  <textarea
                    disabled
                    value={draftToHtml(
                      convertToRaw(editorState.getCurrentContent())
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Box>
    </MainLayout>
  );
}

export default Email_add;
