import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import { useParams,useLocation, useNavigate } from "react-router-dom";
import axios, { Axios } from "axios";
import { api_rout } from "../../Api-Config/config";
import { useDispatch, useSelector } from "react-redux";

export default function Add_schedule() {
    const { user, isLoading } = useSelector((state) => state.user);

const { state } = useLocation();
const nevigate = useNavigate();
  const [testName, setTestName] = useState("");
  const [testNameList, setTestNameList] = useState([
    {
      value: "test",
      label: "test",
    },
    {
      value: "test 2",
      label: "test 2",
    },
  ]);

  const [engineerName, setEngineerName] = useState("");
  const [engineerNameList, setEngineerNameList] = useState([
    {
      value: "engineerName",
      label: "engineerName",
    },
    {
      value: "engineerName 2",
      label: "engineerName 2",
    },
  ]);


  const [ScheduleFrom,setScheduleFrom] = useState("")
const [description,setDescription] = useState("")


  const handleChangeInputs = (sateState) => (e) => {
    console.log("click");
    console.log(e.target.value);
    sateState(e.target.value);
  };

  const handleAddSchedule = async() => {
    const payload = {
        "engineerId": engineerName,
        "subContractId": state.id,
        "workId": testName,
        "scheduleDate": ScheduleFrom,
        "createdBy":user.id,
        "description": description,
    }
    try{
        const response = await axios.post(`${api_rout}/schedule/create`, payload);
        console.log(response)
    }
    catch(err){
        console.log(err,"error");
    }
  }

  const getAllWork = async() => {
    try{
        const response = await axios.get(`${api_rout}/domain/work/get/all`);
        const data = response.data.map((item)=> {
            
            return {
            value: item.id,
            label: item.taskName,
          }
        });

        setTestNameList(data)
    }
    catch(err){
        console.log(err,"error");
    }
  }

  const getEngeneer = async() => {
    try{
        let projectID = localStorage.getItem("projectId");
        const response = await axios.get(`${api_rout}/user/get/by/role/${projectID}/6`);
        console.log(response.data,"engeneer name");
        const data = response.data?.map((item)=> {
          return {
            value: item.id,
            label: item.firstName+" "+ item.lastName,
          }
         
        })
        setEngineerNameList(data)

    }
    catch(error){
        console.log(error,"error");
    }
  }


  useEffect(()=>{
    getAllWork();
    getEngeneer();
  },[])

  return (
    <MainLayout>
      <Box
        sx={{
          marginTop: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
          "& #images": {
            paddingLeft: "120px",
          },
          "& #video": {
            paddingLeft: "120px",
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={testNameList}
              // value={trialFinal}
              onChange={(newValue, e) => {
                console.log("value", newValue, e);
                if (!e) {
                  setTestName("");
                } else {
                  setTestName(e.value);
                }
              }}
              renderInput={(params) => {
                // console.log(params,"selected")
                return (
                  <TextField
                    {...params}
                    label="Test Name"
                    fullWidth
                    margin="normal"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={engineerNameList}
              // value={trialFinal}
              onChange={(newValue, e) => {
                console.log("value", newValue, e);
                if (!e) {
                  setEngineerName("");
                } else {
                  setEngineerName(e.value);
                }
              }}
              renderInput={(params) => {
                // console.log(params,"selected")
                return (
                  <TextField
                    {...params}
                    label="Engineer name"
                    fullWidth
                    margin="normal"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              helperText="Schedule Date"
              type="datetime-local"
              name="ScheduleFrom"
              id="ScheduleFrom"
              value={ScheduleFrom}
              onChange={handleChangeInputs(setScheduleFrom)}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
          <TextField
          id="outlined-multiline-static"
          label="Description"
          multiline
          rows={4}
          value={description}
          onChange={handleChangeInputs(setDescription)}
          />

          </Grid>

          <Grid item xs={12}>
                <Button onClick={handleAddSchedule} variant="contained" color="primary">
                  Submit
                </Button>
                <Button onClick={()=> nevigate(-1)} variant="contained" color="primary">
                  Cancel
                </Button>
              </Grid>
              
        </Grid>
      </Box>
    </MainLayout>
  );
}
