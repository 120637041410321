import { Box, Typography, useTheme } from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import { api_rout } from "../../Api-Config/config";

const DataManagerRejCard = (props) => {
  const {
    totalVerifier,
    totalApproved,
    totalRejected,
    totlaPending,
    setOpen,
    setShowList,
  } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [roleData, setRoleData] = useState([]);
  const [approveData, setApproveData] = useState([]);
  const [userFirstData, setUserFirstData] = useState("");
  const [userLastData, setUserLastData] = useState("");
  const [userId, setUserId] = useState(null);
  const [pendingCount, setPendingCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);

  const [select, setSelect] = useState(3);

  const navigate = useNavigate();

  const hanleOpen = (value) => {
    console.log(value);
    // setOpen(true);
    setShowList(value);
    setSelect(value)
  };

  useEffect(() => {
    fetch(`${api_rout}/approval/get/all`)
      .then((data) => data.json())
      .then((data) => {
        setApproveData(data.data);
        console.log(data.data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    fetch(`${api_rout}/assignment/get/all`)
      .then((data) => data.json())
      .then((data) => {
        setRoleData(data);
        const rowWithRole = data.find((row) => row.roleId === 7);
        if (rowWithRole) {
          setUserId(rowWithRole.userId);
        } else {
          setUserId(null);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (userId) {
      fetch(`${api_rout}/user/find/${userId}`)
        .then((data) => data.json())
        .then((data) => {
          setUserFirstData(data.firstName);
          setUserLastData(data.lastName);
        })
        .catch((error) => console.error(error));
    }
  }, [userId]);

  useEffect(() => {
    const pendingCount = approveData.filter(
      (row) => row.status === "pending"
    ).length;
    const completedCount = approveData.length;
    console.log(pendingCount + completedCount, "All counts");
    setPendingCount(pendingCount);
    setCompletedCount(completedCount);
  }, [approveData]);

  return (
    <Box
      sx={{
        p: 2,
        border: "1px solid #ddd",
        borderRadius: "4px",
        backgroundColor: "#fff",
        "& .user_card": {
          fontSize: "14px",
          color: colors.grey[300],
          padding: 0,
        },
        "& .user_card_btn": {
          borderColor: colors.grey[500],
          color: colors.grey[300],
        },
        "& .user_card_btn:hover": {
          borderColor: "#3d96f7",
          color: colors.grey[900],
        },
        "& .user_card_rt .icon_wrap": {
          color: colors.grey[400],
        },
        "& .user_card_rt .icon_wrap:hover": {
          color: colors.grey[300],
        },
        "& .user_card_count": {
          marginTop: "15px",
        },
        "& .userCardBtn": {
          padding: "5px",
          backgroundColor: "#eee",
          border: "1px solid #ddd",
          borderRadius: "3px",
          lineHeight: "normal",
          width: "100%",
          textAlign: "center",
          color: "#333",
          marginTop: "21px"
        },
        "& .userCardBtn h4": {
          margin: 0,
        },
        "& .userCardBtn.activeGreenLink": {
          backgroundColor: select === 1 ? "#3ebd6b" :"#e3f1e3",
          border: select === 1 ? "1px solid #3ebd6b": "1px solid #c2dfc2",
          color: select === 1 ? "#fff" :"black",
        },
        "& .userCardBtn.activeGreenLink:hover": {
          backgroundColor: "#3ebd6b",
          borderColor: "#3ebd6b",
          color: "#fff",
        },
        "& .userCardBtn.activeLtOrangeLink": {
          backgroundColor: select === 3 ? "#f4a442" :"#fff2e2",
          border: select === 3 ? "1px solid #f4a442" : "1px solid #ffdaae",
          color: select === 3 ? "#fff" :"black",
        },
        "& .userCardBtn.activeLtOrangeLink:hover": {
            backgroundColor: "#f4a442",
            borderColor: "#f4a442",
            color: "#fff",},
        "& .userCardBtn.activeOrangeLink": {
          backgroundColor: select === 0 ? "#cf4668" :"#ffe7ed",
          border: select === 0 ? "1px solid #cf4668" : "1px solid #ffcbd8",
          color: select === 0 ? "#fff" :"black",
        },
        "& .userCardBtn.activeOrangeLink:hover": {
            backgroundColor: "#cf4668",
            borderColor: "#cf4668",
            color: "#fff",},
      }}
    >
      <div className="user_card">
        <div className="row">
          <div className="col-8">
            <div className="icon_wrap">
              <h3>
                <HistoryOutlinedIcon /> Approval History
              </h3>
            </div>
          </div>
          {/* <div className="col-4">
            <button className="btn btn-primary float-end">View</button>
          </div> */}

          <div className="col-12">
            <div className="user_card_lt">
              <div className="user_card_count d-flex align-items-baseline mb-2">
                <h4 className="me-2 mb-0">Total Approvals received</h4>
                <h3 style={{ lineHeight: "20px" }}>{totalVerifier.length}</h3>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row me-0">
              <div className="col-4 pe-0">
                <div className="user_card_lt">
                  <div
                    className="user_card_count userCardBtn activeGreenLink"
                    onClick={() => hanleOpen(1)}
                    style={{ cursor: "pointer" }}
                  >
                    <h4
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      Approved &nbsp;<b>{totalApproved.length}</b>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-4 pe-0">
                <div className="user_card_lt">
                  <div
                    className="user_card_count userCardBtn activeLtOrangeLink"
                    onClick={() => hanleOpen(3)}
                    style={{ cursor: "pointer" }}
                  >
                    <h4
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      Pending &nbsp;<b>{totlaPending.length}</b>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-4 pe-0">
                <div className="user_card_lt">
                  <div
                    className="user_card_count userCardBtn activeOrangeLink"
                    onClick={() => hanleOpen(0)}
                    style={{ cursor: "pointer" }}
                  >
                    <h4
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      Rejected &nbsp;<b>{totalRejected.length}</b>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default DataManagerRejCard;
