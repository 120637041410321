import React from 'react'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";


import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import contentImage from "../../../images/content.png"


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


export default function ProjectCards(props) {
  const navigate = useNavigate();

  const {projectName,role,projectId,roleId,rolePriority,description} = props;

  const handleButtonClick = (projectNo) => {

    // Do something with the project ID
    console.log("Clicked project ID:", projectNo);
    localStorage.setItem("projectId", projectId);
    localStorage.setItem("role",role);
    localStorage.setItem("roleId",roleId);
    localStorage.setItem("rolePriority",rolePriority);


    if(role === "client"){
      navigate("/projectView");
    }
    else if(role === "contractor"){
      navigate("/contractorDash")
    }
    else if(role === "sub contractor"){
      navigate("/subContractorDash")
    }
    else if(role === "QC"){
      navigate("/QCDash")
    }
    else if(role === "QA"){
      navigate("/QADash")
    }
    else{
      navigate("/engineerDash")
    }
  };
  
  return (
//     <div style={{height:"200px",width:"500px",border:"1px solid",padding:"10px",display:"flex",justifyContent:"space-between"}}>
//         <div style={{width:"50%"}}>
//             <img style={{height:"130px",width:"250px"}} src="https://smartinfrastructure.sharepoint.com/_api/v2.1/sites/smartinfrastructure.sharepoint.com,8f405a62-3d33-4883-a6d9-907aaa00700d,9456ed1d-235a-4a05-a483-518a27e8edec/items/18ccb145-5246-45b6-bb33-32db75b541cf/driveItem/thumbnails/0/c1600x99999/content?prefer=noRedirect,extendCacheMaxAge&clientType=modernWebPart"/>
        
//         <div>
//             <h6 style={{textAlign:"center"}}>Progress 50%</h6>
//             <BorderLinearProgress variant="determinate" value={50} />

//         </div>
//         </div>
//         <div style={{width:"50%"}}>
//         <h6 style={{textAlign:"center"}}>Project Name : {projectName}</h6>
//         <h6 style={{textAlign:"center"}}>Role : {role}</h6>
//         <div>

//         <Button
//                 onClick={() => handleButtonClick(projectId)}
//                 variant="contained"
//               >
// <VisibilityOutlinedIcon />
//               </Button>
//         </div>
//         </div>
//     </div>

<Card sx={{ maxWidth: 345 }}
onClick={() => handleButtonClick(projectId)}
>
<CardActionArea>
  <CardMedia
    component="img"
    height="140"
    image={contentImage}
    alt="green iguana"
  />
  <CardContent>
    <Typography gutterBottom variant="h5" component="div">
    Project Name : {projectName}
    </Typography>
    <Typography variant="body2" color="text.secondary">
    <Typography gutterBottom variant="h5" component="div">Role : {role}</Typography>
    
  {description}
    <div>
           <Typography gutterBottom variant="h5" component="div">Progress : 50%</Typography>
            <BorderLinearProgress variant="determinate" value={50} />

        </div>
      
    </Typography>
  </CardContent>
</CardActionArea>
</Card>
  )
}
