import React from "react";
import MainLayout from "../../layout/MainLayout";
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { api_rout } from "../../Api-Config/config";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export default function Create_subCotractor() {
  const { user, isLoading } = useSelector((state) => state.user);

  const [workList, setWorkList] = useState([]);
  const [workID, setWorkId] = useState("");
  const [companyName, setCompanyName] = useState("")
const [description, setDescription] = useState("")

const [conTractId,setContractId] = useState()

const nevigate = useNavigate();

  const getWorkList = async () => {
    try {
      const response = await axios.get(`${api_rout}/domain/civilwork/get/all`);
      const data = response.data.map((item)=> {
        const payload = {
          value: item.id,
          label: item.workName
        }
        return payload;
      })
      console.log(data);
      setWorkList(data)
    } catch (error) {
      console.log(error);
    }
  };

  const getContractIdByProjectId = async()=> {
    try{
      const projectId = await localStorage.getItem('projectId');
      const response = await axios.get(`${api_rout}/contract/by/project/${projectId}`);
      setContractId(response.data);
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getContractIdByProjectId()
    getWorkList();
  }, []);

  const handleInviteSubmit = async () => {
    try{
      const payload = {
        "contractId": conTractId ? conTractId[0].id : "",
            "workId": workID,
            "companyName": companyName,
            "description": description,
            "createBy": user.id
    }
      const response = await axios.post(`${api_rout}/subcontract/create`, payload);
      console.log(response)
    }
    catch (error) {
      console.error(error);
    }
  }

  return (
    <MainLayout>
      <Box
        sx={{
          marginTop: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 70px)",
        }}
      >
        <div className="pageFormWrap">
          <Typography component="h1" variant="h3">
            Create sub contract
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                id="combo-box-demo"
                options={workList}
                // value={trialFinal}
                onChange={(newValue, e) => {
                  console.log("value", newValue, e);
                  if (!e) {
                    setWorkId("");
                  } else {
                    setWorkId(e.value);
                  }
                }}
                renderInput={(params) => {
                  // console.log(params,"selected")
                  return (
                    <TextField
                      {...params}
                      label="Work name"
                      fullWidth
                      margin="normal"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Company name"
                name="companyName"
                value={companyName}
                fullWidth
                margin="normal"
                onChange={(e)=> setCompanyName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Description"
                name="companyName"
                value={description}
                fullWidth
                margin="normal"
                multiline
                rows={4}
                onChange={(e)=> setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button variant="contained" onClick={handleInviteSubmit}>
                Submit
              </Button>

              <Button variant="contained" onClick={() => nevigate(-1)}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </Box>
    </MainLayout>
  );
}
