import React from 'react'
import MainLayout from '../layout/MainLayout';
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useMode, tokens } from "../theme";
import axios from 'axios';
import { api_rout } from '../Api-Config/config';
import { useState } from 'react';
import { useEffect } from 'react';
import View_schedule from '../components/schedule/view_schedule';

export default function Schedule() {
    const [theme, colorMode] = useMode();
    const colors = tokens(theme.palette.mode);
    const [allScheduleData,setAllScheduleData] = useState([])
    
    const fetchScheduledata = async () => {
        try{

            const response = await axios.get(`${api_rout}/schedule/get/all`);
            const data = await response.data;
            console.log(data);
            setAllScheduleData(data)
        }
        catch(error){
                    console.log(error);
                }
    }

    useEffect(() => {
            fetchScheduledata();
        }, [])


  return (
    <MainLayout>
        <Box m="20px">
         <Box
          sx={{
            "& .grid-actionsWrap": {
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            },
            "& .grid-actionsWrap Button": {
              fontSize: "13px",
              padding: "4px",
              minWidth: "auto",
              marginRight: "5px",
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
        <h1 className="page_title">Schedule Management</h1>
          <View_schedule
            scheduleData = {allScheduleData}
          />
        </Box>
        </Box>
    </MainLayout>
  )
}
