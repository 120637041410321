import React, { useEffect, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import apiService from "../../data/apiService";
import ProjectMap from "../maps/projectMap";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ProjectIfoOnAccordion = () => {
  const location = useLocation();
  console.log(location.pathname);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const projectId = localStorage.getItem("projectId");
  const nevigate = useNavigate();
  const [projectData, setProjectData] = useState("");

  // role Id from Local storage
  const roleId = localStorage.getItem("roleId");

  const fetchProjectData = async () => {
    try {
      const response = await apiService.projectDetails(projectId);
      setProjectData(response.data);
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  useEffect(() => {
    fetchProjectData();
  }, [projectId]);

  return (
    <Box
      sx={{
        // p: 2,
        // border: "1px solid #ddd",
        borderRadius: "4px",
        "& h5": {
          fontSize: "16px",
          fontWeight: "600",
        },
        "& .css-o4b71y-MuiAccordionSummary-content":{
          margin: "8px 0"
        }
      }}
    >
      <Accordion style={{ border: "1px solid #ddd", boxShadow: "none"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography variant="h3" style={{margin: "2.6px 0"}}>
              {projectData.projectName &&
                projectData.projectName.charAt(0).toUpperCase() +
                  projectData.projectName.slice(1)}
            </Typography>
            <div className="d-flex"><h5 style={{ margin: 0, fontSize: "13px", lineHeight: "normal"}}>Project Number: </h5>
                    <p style={{ margin: "0 0 0 5px", fontSize: "13px", lineHeight: "normal"}}>{projectData.projectNo}</p></div>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row" style={{}}>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  {/* {
              location.pathname === "/contractorDash" ? " " :(location.pathname === "/subContractorDash" ? " " : (location.pathname === "/engineerDash" ? " ": <button className="btn btn-primary float-end">Edit</button>)) 
            } */}
                  {location.pathname === "/projectView" ? (
                    <button className="btn btn-primary float-end">Edit</button>
                  ) : (
                    " "
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div style={{ height: "100%", width: "100%" }}>
                <ProjectMap
                  latitude={projectData.latitude}
                  longitude={projectData.longitude}
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="row">
                {/* <div className="col-md-3 mb-1">
                  <div className="projectView_txt">
                    <h5>Project Number</h5>
                    <p>{projectData.projectNo}</p>
                  </div>
                </div> */}
                <div className="col-md-3 mb-1">
                  <div className="projectView_txt">
                    <h5>Associated with</h5>
                    <p>{projectData.clientId}</p>
                  </div>
                </div>
                <div className="col-md-3 mb-1">
                  <div className="projectView_txt">
                    <h5>Sector</h5>
                    <p>{projectData.sectorId}</p>
                  </div>
                </div>
                <div className="col-md-3 mb-1">
                  <div className="projectView_txt">
                    <h5>Phase</h5>
                    <p>{projectData.phaseId}</p>
                  </div>
                </div>
                <div className="col-md-3 mb-1">
                  <div className="projectView_txt">
                    <h5>City</h5>
                    <p>{projectData.city}</p>
                  </div>
                </div>
                <div className="col-md-3 mb-1">
                  <div className="projectView_txt">
                    <h5>County/District</h5>
                    <p>{projectData.countyOrDistrict}</p>
                  </div>
                </div>
                <div className="col-md-3 mb-1">
                  <div className="projectView_txt">
                    <h5>State</h5>
                    <p>{projectData.stateId}</p>
                  </div>
                </div>
                <div className="col-md-3 mb-1">
                  <div className="projectView_txt">
                    <h5>Country</h5>
                    <p>{projectData.countryId}</p>
                  </div>
                </div>

                {roleId === "2" ? (
                <div className="col-12 text-center">
                  <Button className=""
                    // disabled={roleId === "2" ? false : true}
                    variant="contained"
                  >
                    Manage Project
                  </Button>
                  </div>
                ) : null}
                {/* <div className="col-md-3 mb-2">
            <div className="projectView_txt">
              <h5>Latitude</h5>
              <p>{projectData.latitude}</p>
            </div>
          </div>
          <div className="col-md-3 mb-2">
            <div className="projectView_txt">
              <h5>Longitude</h5>
              <p>{projectData.longitude}</p>
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <div className="projectView_txt">
              <h5>Description</h5>
              <p>{projectData.description}</p>
            </div>
          </div> */}
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ProjectIfoOnAccordion;
