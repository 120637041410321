import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import MainLayout from "../../layout/MainLayout";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import ViewConcreteMix from "../ViewConcreteMix";

export default function ViewConcreteMixDesign(){
    const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return(
    <Box>
        <MainLayout>
            <Box sx={{ padding: "15px" }}>
                <h2>View Concrete Mix Design</h2>
                <Box>
                    <ViewConcreteMix />
                </Box>
            </Box>
        </MainLayout>
    </Box>
  )

}