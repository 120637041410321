import {  GET_USER_FAILURE, GET_USER_REQUEST, GET_USER_SUCCESS } from "./user.actionType"


export const get_user_request = ()=>{
    return{
        type :GET_USER_REQUEST
    }
}

export const get_user_success = (payload)=>{
    return{
        type : GET_USER_SUCCESS,
        payload : payload
    }
}
export const get_user_failure = ()=>{
    return{
        type : GET_USER_FAILURE
    }
}