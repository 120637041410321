import {
  Box,
  IconButton,
  InputBase,
  Stack,
  Toolbar,
  useTheme,
} from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import AccountDropdown from "./account_dropdown";
import { useNavigate } from "react-router-dom";
import EmergencyRecordingIcon from "@mui/icons-material/EmergencyRecording";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileApi } from "../../Redux/GetUserDetails/userAPI";
import { useEffect } from "react";
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AlertsMenu from "./alertsMenu";
import Mainsidebar from "./Sidebar";

const Topbar = ({ rem_token }) => {
  const dispatch = useDispatch();
  let userId = localStorage.getItem("userId");
  const { user, isLoading } = useSelector((state) => state.user);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { collapseSidebar } = useProSidebar();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getUserProfileApi(userId));
  }, []);
  return (
    <Box
      // style={{
      //   position: "fixed",
      //   zIndex: 1,
      //   width: "100vw",

      // }}

      display="flex"
      justifyContent="space-between"
      p="10px"
      backgroundColor={colors.grey[900]}
      borderBottom={1}
      borderColor={colors.grey[700]}
      sx={{
        "& .css-ray9a1": {
          backgroundColor: `${colors.grey[800]} !important`,
        },
        "& .header_dropdown": {
          position: "absolute",
          width: "120px",
          right: 0,
          backgroundColor: `${colors.grey[100]}`,
          color: `${colors.grey[900]}`,
          padding: "6px",
          borderRadius: "4px",
          bottom: "-35px",
          display: "none",
          fontSize: "14px",
        },
        "& .lock_btn:hover .header_dropdown": {
          display: "block",
        },
        "& .h_dropdown_item": {
          cursor: "pointer",
          lineHeight: "normal",
          display: "flex",
          padding: "4px",
        },
        "& .h_dropdown_item:hover": {
          backgroundColor: `${colors.grey[200]}`,
          borderRadius: "3px",
        },
        "& .header_dropdown svg": {
          marginRight: "8px",
          width: "18px",
          height: "18px",
        },
        "& .alertsListWrap": {
          position: "absolute",
          width: "280px",
          left: "-240px",
          backgroundColor: "#fff",
          color: "#333",
          padding: "6px",
          borderRadius: "4px",
          top: "35px",
          display: "none",
          fontSize: "13px",
          boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
        },
        "& .alertBtn:hover .alertsListWrap": {
          display: "block",
        },
        "& .alertsListWrap ul": {
          listStyle: "none",
          margin: 0,
          padding: 0,
        },
        "& .alertsListWrap li": {
          border: "1px solid #eee",
          backgroundColor: "#eee",
          borderRadius: "4px",
          padding: "8px",
          marginBottom: "5px",
        },
        "& .alertsListWrap li:hover": {
          backgroundColor: "#e6f0ff",
        },
        "& .alertItemWrap": {
          display: "flex",
          textAlign: "left",
        },
        "& .alertItemWrap h4": {
          fontSize: "14px",
          marginBottom: "3px",
        },
        "& .alertItemWrap h5": {
          fontSize: "14px",
          marginBottom: "0",
          fontWeight: "600",
        },
        "& .alertItemWrap p": {
          marginBottom: 0,
        },
        "& .alertItemIcon": {
          lineHeight: "52px",
          padding: "0 8px 0 2px",
        },
        "& .alertItemIcon svg": {
          width: "30px",
          height: "30px",
        },
      }}
    >
      <Box display="flex">
        <Mainsidebar />
        {/* <IconButton>
          <MenuOutlinedIcon
            onClick={() => {
              collapseSidebar();
            }}
          />
        </IconButton> */}
        {/* <Box
          display="flex"
          borderRadius="3px"
          backgroundColor={colors.grey[800]}
        >
          <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box> */}
        <Box sx={{
padding: "6px 10px",
color: "#555",
backgroundColor: "#f3f3f3",
borderRadius: "3px",
cursor: "pointer",
"&:hover" :{
  backgroundColor: "rgb(63 138 221)",
  color: "#fff"
}
        }} 
        onClick={()=>navigate("/dashboard")}>
          <HomeOutlinedIcon />
        </Box>
      </Box>

      <Box display="flex">
        {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "light" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton> */}
        {/* <IconButton onClick={()=>navigate("/camera")}>
          <AddAPhotoOutlinedIcon />
        </IconButton>
        <IconButton onClick={()=>navigate("/Video")}>
          <EmergencyRecordingIcon />
        </IconButton> */}
        <IconButton>
          <SupportOutlinedIcon />
        </IconButton>
        <IconButton style={{ position: "relative" }} className="alertBtn">
          <NotificationsOutlinedIcon />
          <AlertsMenu />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <IconButton className="lock_btn">
            <PersonOutlineOutlinedIcon />
            <AccountDropdown
              // rem_token={rem_token}
              firstName={user && user.firstName}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
