import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { TextField, Button, Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItemMui from "@mui/material/MenuItem";
import MainLayout from "../../layout/MainLayout";
import InputLabel from "@mui/material/InputLabel";
import axios from "axios";
import { notification } from "antd";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Invite_user() {
  const searchQuary = useSearchParams()[0];
  
  // nevigate from react router dom
  const nevigate = useNavigate();

  console.log(searchQuary.get("name"));
let query = searchQuary.get("name");
  // const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [formValues, setFormValues] = useState({
    roleId: "",
    projectId: "",
    email: "",
    firstName: "",
    lastName: "",
  });
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    axios
      .get("https://sig.eastus.cloudapp.azure.com/backend/role/all")
      .then((res) => {
        setRoles(res.data);
        console.log(res.data, "res data");
        let fltdata = res.data.filter((item)=> item.name === query);
        console.log(fltdata, "flt data");
        formValues.roleId = query ? fltdata[0].id : "";
      })
      .catch((error) => {
        alert("error");
        console.log(error);
      });
    axios
      .get("https://sig.eastus.cloudapp.azure.com/backend/project/get/all")
      .then((res) => {
        setProjects(res.data);
      })
      .catch((error) => {
        alert("error");
        console.log(error);
      });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = () => {
    axios
      .post(
        "https://sig.eastus.cloudapp.azure.com/backend/common/add/by/invite",
        {
          ...formValues,
        }
      )
      .then((res) => {
        notification.success({
          message: "Success",
          description: "Invite send successfully",
          placement: "topRight",
          duration: 2,
          style: {
            marginTop: "50px",
            textTransform: "capitalize",
          },
        });
      })
      .catch((error) => {
        console.log(error);
        notification.error({
          message: "Erro",
          description: error.response.data,
          placement: "topRight",
          duration: 3,
          style: {
            marginTop: "50px",
            textTransform: "capitalize",
          },
        });
      });
  };

  return (
    <MainLayout>
      <Box
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 70px)",
          backgroundColor: "#f9f9f9"
        }}
      >
        <div className="pageFormWrap container bg-white p-3">
          <Typography component="h1" variant="h3">
            Send Invite
          </Typography>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Emial"
                  name="email"
                  size="small"
                  value={formValues.email}
                  fullWidth
                  margin="normal"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="First Name"
                  name="firstName"
                  fullWidth
                  margin="normal"
                  size="small"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Last Name"
                  name="lastName"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" 
                size="small">Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    value={formValues.roleId}
                    onChange={handleChange}
                    label="Role"
                    name="roleId"
                  >
                    {roles &&
                      roles.map((value) => (
                        <MenuItemMui key={value.id} value={value.id}>
                          {value.name}
                        </MenuItemMui>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" 
                size="small" >Project</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    value={formValues.projectId}
                    onChange={handleChange}
                    label="Project"
                    name="projectId"
                  >
                    {projects &&
                      projects.map((value) => (
                        <MenuItemMui key={value.id} value={value.id}>
                          {value.projectName}
                        </MenuItemMui>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
             
              <Grid item xs={12} className="d-flex justify-content-end">
              <Button variant="contained" onClick={() => nevigate(-1)} className="me-2">
                Back
              </Button>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  SEND INVITE
                </Button>
                
              </Grid>
            </Grid>
          </form>
        </div>
      </Box>
    </MainLayout>
  );
}

export default Invite_user;
