import { Box, useTheme } from "@mui/material";
import { useMode, tokens } from "../../theme";
import MainLayout from "../../layout/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import ProjectDetails from "./clientDash";
import ProjectInfo from "./projectInfo";
import Sub_contractlist from "./Sub_contractlist";
import SchecudleList from "./SchecudleList";

const ProjectView = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user, isLoading } = useSelector((state) => state.user);

    return(
        <MainLayout>
                <div className="user_cards_wrap">
                  <div className="row">
                    <div className="col-md-12 col-12 mb-3">
                        <ProjectDetails />
                    </div>
                  </div>
                </div>
        </MainLayout>
    )
}

export default ProjectView;