import Users from "../pages/users";
import MainLayout from "../layout/MainLayout";
import { Box } from "@mui/material";
import Project_card from "../components/projects/projects_card";
import User_card from "../components/users/user_card";
import Device_card from "../components/devices/devices_card";
import Projects_list from "../components/projects/projects_list";
import User_list from "../components/users/user_list";
import ClientsCard from "../components/clients/clientsCard";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmployeeDashBoard from "../components/EmployeeDashBoardView/EmployeeDashBoard";

export default function Dashboard() {
  const { user, isLoading } = useSelector((state) => state.user);


//   const getData = async() => {
//       try{
//       const data = await axios.get("http://localhost:8000/api/GetHelpInfoForSampleParameters");
//       console.log(data);
//       }
//       catch(err){
//         console.log(err);
//       }
//   }

// useEffect(() => {
//   getData();
// },[])
  return (
    <MainLayout>
      <div className="container">
      {
        user.designation === "Admin" &&  <Box m="20px">
        <h1 className="page_title">Dashboard</h1>
        <div className="moduleTabsWrap"></div>
        <div className="user_cards_wrap">
          <div className="row">
            <div className="col-md-3">
              <Project_card />
            </div>
            <div className="col-md-3">
              <ClientsCard />
            </div>
            <div className="col-md-3">
              <User_card />
            </div>
            <div className="col-md-3">
              <Device_card />
            </div>
          </div>
        </div>
        <Projects_list />
      </Box>
      }
     {
      user.designation === "Employee" ?      <EmployeeDashBoard/> : <Box m="20px">
      <h1 className="page_title">Dashboard</h1> </Box>
     }
     </div>
    </MainLayout>
  );
}
