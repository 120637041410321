import React, { useEffect, useState } from 'react'
import MainLayout from '../layout/MainLayout'
import { RecordWebcam, useRecordWebcam } from "react-record-webcam";
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import RestoreIcon from '@mui/icons-material/Restore';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import axios from 'axios';
import {Box} from '@mui/material';
import Typography from "@mui/material/Typography";
import { useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { api_rout } from '../Api-Config/config';

const OPTIONS = {
  fileName: "test-filename",
  mimeType: "video/x-matroska;codecs=avc1",
  width: 1280,
  height: 720,
  disableLogs: true,
};
export default function VideoRecoder() {

 // get user datta from redux
 const {user} = useSelector((state)=> state.user)
 console.log(user)

  const { state } = useLocation();
  console.log(state);

  const navigate = useNavigate();

  const playerRef = React.useRef();
  const recordWebcam = useRecordWebcam(OPTIONS);
  console.log(recordWebcam, RecordWebcam)
  const [projects, setProjects] = useState([]);
  const [selectProject, setSelectProject] = useState("");
  const [selectProjectName, setSelectProjectName] = useState("");
  const [currentLatitude, setCurrentLatitude] = useState("");
  const [currentLongitude, setCurrentLongitude] = useState("");

  const [value, setValue] = React.useState();


  const handleChange = (event) => {
    setValue(event.target.value);
  };


  const fetchProjects = async () => {
    try {
      const response = await fetch(
        "https://sig.eastus.cloudapp.azure.com/backend/project/get/all"
      );
      const data = await response.json();
      console.log(data);
      setProjects(data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };
  const getfetures = async () => {
    let features = {
      audio: true,
      video: {
        width: { ideal: 1800 },
        height: { ideal: 900 }
      }
    };
    let display = await navigator.mediaDevices
      .getUserMedia(features);

    // Returns a sequence of MediaStreamTrack objects 
    // representing the video tracks in the stream


    let settings = display.getVideoTracks()[0]
      .getSettings();

    let width = settings.width;
    let height = settings.height;

    console.log('Actual width of the camera video: '
      + width + 'px');
    console.log('Actual height of the camera video:'
      + height + 'px');
  }
  useState(() => {
    // fetchProjects();
    getfetures()
  }, []);
  const getRecordingFile = async () => {
    // console.log(recordWebcam.getRecording());
    const blob = recordWebcam.getRecording();
    console.log({ blob });
    // var reader = new FileReader();
    // reader.onload = await function (event) {
    //   console.log(event.target);
    // }; // data url!
    // var source = reader.readAsBinaryString(blob);
    // console.log("fn Res", source);
  };
  const getBlob = async (blob) => {
    console.log({ blob });
    // var reader = new FileReader();
    // reader.onload = await function (event) {
    //   console.log(event.target);
    // }; // data url!
    // var source = reader.readAsBinaryString(blob);
    // console.log("fn Res", source);
    // if (!selectProject) {
    //   alert("Please select a project.");
    //   return;
    // }
    if(value === undefined){
      alert("Please check  Do you want to capture More video ?");
      return;
    }

    let payload = {
      "videos": blob,
      "projectId": Number(localStorage.getItem("projectId")),
      "createdBy":  user.id,
      "latitude": currentLatitude,
      "longitude": currentLongitude,
      "scheduleId":state.id,
      "qcVerifier": "Hakuna",
      "qaVerifier":"Matata",
      "submit": value,
    }

    const formData = new FormData();
    formData.append("projectId", Number(localStorage.getItem("projectId")));
    formData.append("latitude", currentLatitude);
    formData.append("longitude", currentLongitude);
    formData.append("videos", blob);
    formData.append("scheduleId",state.id);
    formData.append("createdBy", user.id);
    // The qcvrifaier and qaVerifier is demo only
    formData.append("qcVerifier", "Hakuna");
    formData.append("qaVerifier", "Matata");

    formData.append("submit", value)
    console.log(payload);
    // try {
    //   // Make API request to submit the form data
    //   const response = await axios.post(
    //     "https://sig.eastus.cloudapp.azure.com/backend/onsitevideo/create",
    //     payload,
    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     }
    //   );

    //   console.log("Form submitted successfully:", response.data);
    //   alert("Form submitted successfully!");

    // } 
   try{
    const response = await axios.post(`${api_rout}/onsitephotovideo/add`,formData);
       console.log("Video Uploaded successfully:", response.data);
      alert("Form submitted successfully!");
   }
    catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form");
    }
  };

  const handleProjectChange = (event) => {
    console.log(event.target.value)
    setSelectProject(event.target.value);
    const selectedIndex = event.target.selectedIndex;
    const selectedOption = event.target.options[selectedIndex];
    const selectedText = selectedOption.text;
    setSelectProjectName(selectedText);
  };
  useEffect(() => {
    recordWebcam.open();
    if ("geolocation" in navigator) {
      // Retrieve the location data using the Geolocation API
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          // Do something with the location data
          console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
          setCurrentLatitude(latitude);
          setCurrentLongitude(longitude);
        },
        (error) => {
          console.error("Error retrieving location:", error);
          return;
        }
      );
    } else {
      console.error("Geolocation API not supported in this browser.");
      return;
    }
  }, []);

  return (
    // <MainLayout>
    <MainLayout>
    <Box
      sx={{
        display: "flex",
        padding: "10px 0 0",
        marginLeft: "20px",
        justifyContent: "space-between",
        "& button": { marginRight: "20px" },
      }}
    >
      <button
        type="button"
        class="btn btn-primary"
        onClick={() =>
          navigate(-1)
        }
      >
        Back
      </button>
      <Typography component="h1" variant="h3">
            Capture Video
          </Typography>
      <button
        type="button"
        class="btn btn-primary"
        onClick={() =>
          navigate("/camera", {
            state: {
              id: state.id,
            },
          })
        }
      >
        Take Photo
      </button>
    </Box>
      <div className="demo-section">
        {/* <h1>Hooks demo</h1>
        <p>Camera status: {recordWebcam.status}</p> */}
        {/* <div>
          <button
            disabled={
              recordWebcam.status === "OPEN" ||
              recordWebcam.status === "RECORDING" ||
              recordWebcam.status === "PREVIEW"
            }
            onClick={recordWebcam.open}
          >
            Open camera
          </button>
          <button
            disabled={recordWebcam.status === "CLOSED"}
            onClick={recordWebcam.close}
          >
            Close camera
          </button>
        </div> */}
        <div style={{ textAlign: "center", }} className='mainCamWrap mainVideo'>
          <video
            ref={recordWebcam.webcamRef}
            style={{
              display: `${recordWebcam.status === "OPEN" ||
                recordWebcam.status === "RECORDING"
                ? "block"
                : "none"
                }`,
              paddingBottom: "15px"
            }}
            autoPlay
            muted
          />
          <video
          
            ref={recordWebcam.previewRef}
            style={{
              display: `${recordWebcam.status === "PREVIEW" ? "block" : "none"}`,
              height: "90vh",
              width: "100%",

            }}
            autoPlay
            muted
            loop
          />
          <div>

            <Button
              variant="outlined"
              disabled={
                recordWebcam.status === "CLOSED" ||
                recordWebcam.status === "RECORDING" ||
                recordWebcam.status === "PREVIEW"
              }
              onClick={recordWebcam.start}
              sx={{ color: "red" }}
            >
              <PlayArrowIcon />
            </Button>
            <Button
              sx={{ color: "red" }}
              variant="outlined"
              disabled={recordWebcam.status !== "RECORDING"}
              onClick={recordWebcam.stop}
            >
              <PauseIcon />
            </Button>
            <Button
              sx={{ color: "red" }}
              variant="outlined"
              disabled={recordWebcam.status !== "PREVIEW"}
              onClick={recordWebcam.retake}
            >
              <RestoreIcon />
            </Button>
            <Button
              sx={{ color: "white" }}
              variant="outlined"
              disabled={recordWebcam.status !== "PREVIEW"}
              onClick={recordWebcam.download}
            >
              <FileDownloadIcon />
            </Button>

            {/* <button
            disabled={recordWebcam.status !== "PREVIEW"}
            onClick={getRecordingFile}
          >
            Get recording
          </button> */}
            {/* <Button
              variant="outlined"

              disabled={recordWebcam.status !== "PREVIEW"}
              onClick={async () => {
                const blob = await recordWebcam.getRecording();
                getBlob(blob);
              }}
            >
              <CheckIcon/>
            </Button> */}
          </div>
        </div>

      </div>
      <div
        style={{
          display: `${recordWebcam.status === "PREVIEW" ? "block" : "none"}`,

        }}
        className="">
        <form
        // onSubmit={handleSubmit}

        >
          {/* <select
            value={selectProject} onChange={handleProjectChange}
          >
            <option value="">Select Project</option>
            {projects.map((project) => (
              <option key={project.projectNo} value={project.projectNo}>
                {project.projectName}
              </option>
            ))}
          </select> */}
          <div className="mainCamFormBtnWrap">
            {/* <button type="button" 
            onClick={resetPhoto}>
              Reset
            </button> */}
            <div>
      <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Do you want to capture Video ?
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel value={0} control={<Radio />} label="Yes" />
              <FormControlLabel value={1} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
      </div>
            <button onClick={async (event) => {
              event.preventDefault();
              const blob = await recordWebcam.getRecording();
              getBlob(blob);
            }} >Submit</button>
          </div>
        </form>
      </div>

      {/* </MainLayout > */}
    </MainLayout>

  )
}
