import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import MainLayout from "../../layout/MainLayout";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddConcreteMix from "../records/concreteMix";
import AddConcreteBatch from "../records/concreteBatch";
import { useParams } from "react-router-dom";
import Mix_List from "../projects/Mix_List";
import ViewConcreteMix from "../../pages/ViewConcreteMix";

// let steps = ['Create Concrete Mix Design', 'Finalize Concrete Mix Design', 'Create Concrete Batch'];

let stepsForWorkID1 = [
  "Create Concrete Mix Design",
  "Finalize Concrete Mix Design",
];
let stepsForOtherWorkIDs = ["Create Concrete Mix Design", "Mix List"];

export default function WorkSteps() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // get role id from local storage

  const roleID = localStorage.getItem('roleId');

  const { WorkID, CurrentStep } = useParams();
  const startingStepIndex = parseInt(CurrentStep) || 0;

  const getStepsForWorkID = (WorkID) => {
    if (WorkID === "1") {
      return stepsForWorkID1;
    } else {
      return stepsForOtherWorkIDs;
    }
  };

  const steps = getStepsForWorkID(WorkID);

  const [activeStep, setActiveStep] = React.useState(startingStepIndex);
  const [skipped, setSkipped] = React.useState(new Set());

  const getStepContent = (step) => {
    if (WorkID == 1) {
      switch (step) {
        case 0:
          return (
            <Box>
            {/* <div
              style={{
                border: "1px solid #e9e9e9",
              }}
              className="mt-4 rounded"
            >
              <AddConcreteMix />
            </div> */}
              <Mix_List />
            </Box>
          );
        case 1:
          return (
            <div>
              <ViewConcreteMix />
              <Mix_List />
            </div>
          );
        case 2:
          return (
            <div
              style={{
                border: "1px solid #e9e9e9",
              }}
              className="mt-4 rounded"
            >
              <AddConcreteBatch />
            </div>
          );
        default:
          return "Unknown step";
      }
    } else {
      switch (step) {
        case 0:
          return <AddConcreteMix />;
        case 1:
          return <Mix_List />;
        default:
          return "Unknown step";
      }
    }
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {};

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <MainLayout>
      <Box sx={{ width: "100%", padding: "30px", "& .css-1roi1av-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": { color: "#14c508"}, "& h1": { fontSize: "30px", lineHeight: "30px", fontWeight: "600"} }}>
        <h1 className="mb-4">Concrete Mix Design</h1>
        <Stepper activeStep={activeStep} sx={{ backgroundColor: "#f3f3f3", padding: "15px 10px", borderRadius: "6px"}}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {getStepContent(activeStep)}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1, backgroundColor: "#666", color: "#fff !important", padding: "6px 30px" }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button 
              disabled={roleID === "6" ? true : false}
              onClick={handleNext} sx={{ backgroundColor: "#333", color: "#fff", padding: "6px 30px" }}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </MainLayout>
  );
}
