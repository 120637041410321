import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Reports = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        p: 2,
        border: "1px solid #ddd",
        borderRadius: "4px",
        "& .user_card": {
          fontSize: "14px",
          color: colors.grey[300],
          padding: 0
        },
        "& .user_card_btn": {
          borderColor: colors.grey[500],
          color: colors.grey[300],
        },
        "& .user_card_btn:hover": {
          borderColor: "#3d96f7",
          color: colors.grey[900],
        },
        "& .user_card_rt .icon_wrap": {
          color: colors.grey[400],
        },
        "& .user_card_rt .icon_wrap:hover": {
          color: colors.grey[300],
        },
      }}
    >
      <div className="user_card">
        <div className="row">
          <div className="col-8">
            <div className="icon_wrap">
              <h3>
                <ApartmentIcon /> Reports
              </h3>
            </div>
          </div>
          <div className="col-4">
            <button className="btn btn-primary float-end">View</button>
          </div>
          <div className="col-12">
            <div className="user_card_lt">
              <div className="user_card_count">
                <h4>Total</h4>
                <h3>245</h3>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-6">
                <div className="user_card_lt">
                  <div className="user_card_count d-flex" >
                    <h4 style={{ fontSize: "18px", color: "#999", marginRight: "10px"}}>Approved</h4>
                    <h3 style={{ fontSize: "18px", color: "#666"}}>213</h3>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="user_card_lt">
                  <div className="user_card_count d-flex">
                    <h4 style={{ fontSize: "18px", color: "#999", marginRight: "10px"}}>Pending</h4>
                    <h3 style={{ fontSize: "18px", color: "#666"}}>32</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Reports;
