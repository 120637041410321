import MaterialTable from "@material-table/core";
import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import { useEffect } from "react";
import axios from "axios";
import { api_rout } from "../../Api-Config/config";

export default function TestList() {
  const [data, setData] = useState();
  const [clickedRow, setClickedRow] = React.useState();
  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const projectId = localStorage.getItem("projectId");

  const onButtonClick = (e, row) => {
    e.stopPropagation();
    setClickedRow(row);
  };
  const handleButtonClick = (projectNo) => {
    // Do something with the project ID
    console.log("Clicked project ID:", projectNo);
    // localStorage.setItem("projectId", projectNo);
    navigate("/view_project");
  };

  const getCocreteBetchTicketByProjectID = async () => {
    try {
      const response = await axios.get(
        `${api_rout}/concbatchtickets/get/by/construction/${projectId}`
      );
      console.log(response.data, "getCocreteBetchTicketByProjectID");
      setData(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCocreteBetchTicketByProjectID();
  }, []);

  const CSMcolumn = [
    { field: "constId", title: "ID", width: 5 },
    { field: "mixId", title: "Mix ID", width: 10 },
    { field: "plantId", title: "Plant ID", width: 10 },
    { field: "mixerId", title: "Mixer ID", width: 10 },
    { field: "batchNum", title: "Batch Number", width: 10 },
    { field: "ticketNum", title: "Ticket Number", width: 10 },
    { field: "truckId", title: "Truck ID", width: 10 },
    {
      field: "timeBatched",
      title: "Time Batched",
      width: 10,
      render: (params) => {
        return params.timeBatched.slice(0, 10);
      },
    },
    { field: "placementLocation", title: "Placement Location", width: 10 },
    {
      field: "action",
      title: "Actions",
      width: 100,
      // valueGetter: (params) => params.row.id,
      render: (params) => {
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "100px",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                minWidth: "auto",
                marginRight: "5px",
                color: colors.grey[300],
                // backgroundColor: colors.primary[500],
              },
            }}
          >
            <div className="grid-actionsWrap">
              <Button
                onClick={() => handleButtonClick(params.projectNo)}
                variant="contained"
              >
                <VisibilityOutlinedIcon />
              </Button>
              <Button
                onClick={(e) => onButtonClick(e, params)}
                variant="contained"
              >
                <EditOutlinedIcon />
              </Button>
            </div>
          </Box>
        );
      },
      width: 120,
    },
  ];
  return (
    <Box>
      <Box
        m="40px 0 0 0"
        sx={{
          "& .MuiDataGrid-root": {
            fontSize: "14px",
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-root p": {
            margin: 0,
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none !important",
            backgroundColor: colors.grey[900],
            fontSize: "14px",
          },
          "& .name-column--cell": {
            color: colors.primary[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[900],
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.grey[600],
          },
          "& .MuiDataGrid-footerContainer": {
            borderColor: colors.grey[800],
            backgroundColor: colors.grey[900],
          },
          "& .user_list_title": {
            color: colors.grey[300],
            fontSize: "22px",
            fontWeight: 600,
          },
          "& .user_list_gridWrap": {
            backgroundColor: colors.grey[800],
          },
          "& .user_list_search": {
            backgroundColor: colors.grey[900],
          },
          "& .user_list_search input": {
            color: colors.grey[200],
          },
          "& .grid-actionsWrap": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          },
          "& .grid-actionsWrap Button": {
            fontSize: "13px",
            padding: "4px",
            minWidth: "auto",
            marginRight: "5px",
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <MaterialTable
          title={<h2 className="user_list_title">Test List</h2>}
          columns={CSMcolumn}
          data={data}
        />
      </Box>
    </Box>
  );
}
