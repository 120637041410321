import { Box, IconButton, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';


const AccountDropdown = ({firstName}) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const rem_token = () => {
    console.log("clickhere");
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <div className="header_dropdown">
      <div className="h_dropdown_item" onClick={()=> navigate("/profile")}>
       <PersonIcon/> {firstName}
      </div>
      <div className="h_dropdown_item" onClick={rem_token}>
        <PowerSettingsNewOutlinedIcon /> Log Out
      </div>
      <div className="h_dropdown_item" onClick={()=> navigate("/profile")}>
        <AccountCircleIcon /> Profile
      </div>
    </div>
  );
};

export default AccountDropdown;
