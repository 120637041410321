import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useMode, tokens } from "../theme";
import MainLayout from "../layout/MainLayout";
import User_list from "../components/users/user_list";
import Roles_list from "../components/roles/roles_list";
import Modules_list from "../components/modules/modules_list";
import User_card from "../components/users/user_card";
import Role_card from "../components/roles/role_card";
import Module_card from "../components/modules/module_card";

const Users = () => {
  const [theme, colorMode] = useMode();
  const colors = tokens(theme.palette.mode);

  return (
    <MainLayout>
      <Box m="20px">
        <Box
          sx={{
            "& .grid-actionsWrap": {
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            },
            "& .grid-actionsWrap Button": {
              fontSize: "13px",
              padding: "4px",
              minWidth: "auto",
              marginRight: "5px",
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          <h1 className="page_title">User Management</h1>
          <div className="user_cards_wrap">
            <div className="row">
              <div className="col-md-4">
                <User_card />
              </div>
              <div className="col-md-4">
                <Role_card />
              </div>
              <div className="col-md-4">
                <Module_card />
              </div>
            </div>
          </div>

          <User_list />
          <Roles_list />
          <Modules_list />
        </Box>
      </Box>
    </MainLayout>
  );
};

export default Users;
