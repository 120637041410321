import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Badge from '@mui/material/Badge';
import axios from "axios";
import { api_rout } from "../../Api-Config/config";

const Schedule_card = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [allScheduleData,setAllScheduleData] = useState([])

  const fetchScheduledata = async () => {
    try{

        const response = await axios.get(`${api_rout}/schedule/get/all`);
        const data = await response.data;
        console.log(data);
        setAllScheduleData(data)
    }
    catch(error){
                console.log(error);
            }
}
  useEffect(() => {
    fetchScheduledata();
  }, []);

  const ScheduleLength = tableData.length;

  return (
    <Box
    sx={{
      "& .user_card": {
        fontSize: "14px",
        color: colors.grey[300],
        backgroundColor: colors.grey[800],
      },
      "& .user_card_btn": {
        borderColor: colors.grey[500],
        color: colors.grey[300],
      },
      "& .user_card_btn:hover": {
        borderColor: "#3d96f7",
        color: colors.grey[900],
      },
      "& .user_card_rt .icon_wrap": {
        color: colors.grey[400],
      },
      "& .user_card_rt .icon_wrap:hover": {
        color: colors.grey[300],
      },
    }}
    >
            <div className="user_card" id="tot_user_card">
              <div className="row">
                <div className="col-6">
                  <div className="user_card_lt">
                    <div className="icon_wrap">
                    <Badge color="secondary" badgeContent={allScheduleData.length}>
                      <CalendarMonthOutlinedIcon />
                    </Badge>
                    </div>
                    <div className="user_card_count">
                      <h4>Total Schedules</h4>
                      <h3>{allScheduleData.length}</h3>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="user_card_rt">
                    <div className="icon_wrap">
                      <MoreVertOutlinedIcon />
                      <div className="user_card_dropmenu">
                        <ul>
                          <li>
                            <a href="">Edit Schedule</a>
                          </li>
                          <li>
                            <a href="">Delete Schedule</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="user_card_btn" onClick={()=>navigate("/create")}>
                      <AddOutlinedIcon /> Add Schedule
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </Box>
  );
};

export default Schedule_card;
