import React, { useEffect, useState } from "react";
import "./addFieldActivity.css";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

function AddFieldActivity({ sid, tid, refresh, setStep, reschedule }) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const currentDateTime = new Date().toLocaleString("sv", { hour12: false });
  const initialValue = {
    scheduleId: sid,
    startTime: currentDateTime,
    endTime: currentDateTime,
    travelTime: "",
    truckNo: "",
    startMileage: "",
    endMileage: "",
    ambientTemperature: "",
    weather: "",
    humidity: "",
  };

  const [formValues, setFormValues] = useState(initialValue);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = () => {
    setOpen(true);
    axios
      .post("https://sig.eastus.cloudapp.azure.com/backend/field/activity/create", {
        ...formValues,
        createdBy: localStorage.getItem("user"),
      })
      .then((res) => {
        console.log(res.data);
        setOpen(false);
        setSubmitted(true);
        axios.patch(`https://sig.eastus.cloudapp.azure.com/backend/schedule/status/${sid}`, {
          status: "In field",
        });
      });
  };

  useEffect(() => {
    setOpen(true);

    axios
      .get(
        `https://sig.eastus.cloudapp.azure.com/backend/field/activity/get/by/schedule/${sid}`
      )
      .then((res) => {
        console.log();
        if (res.data.length > 0) {
          const data = res.data[0];
          setFormValues({ ...data });
          console.log(data);
          setSubmitted(true);
        }
        setOpen(false);
      });
  }, [submitted, refresh]);

  return (
    <div className="field-div">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="field-head">
        <h1>Field Activity</h1>
      </div>
      <div className="field-cols">
        <div className="field-c field-cOne">
          <div className="field-row">
            <div>
              <label htmlFor="">Start Time</label>
              <input
                type="datetime-local"
                name="startTime"
                onChange={handleChange}
                value={formValues.startTime}
                min={!submitted ? currentDateTime : ""}
                disabled={
                  submitted ||
                  tid != localStorage.getItem("user") ||
                  reschedule.status
                }
              />
            </div>
            <div>
              End Time
              <input
                type="datetime-local"
                name="endTime"
                onChange={handleChange}
                value={formValues.endTime}
                min={formValues.startTime}
                disabled={
                  submitted ||
                  tid != localStorage.getItem("user") ||
                  reschedule.status
                }
              />
            </div>
          </div>
          <div className="field-row">
            <div>
              Travel Time (hrs)
              <input
                type="text"
                name="travelTime"
                onChange={handleChange}
                value={formValues.travelTime}
                disabled={
                  submitted ||
                  tid != localStorage.getItem("user") ||
                  reschedule.status
                }
              />
            </div>
            <div>
              Truck #:
              <input
                type="text"
                name="truckNo"
                onChange={handleChange}
                value={formValues.truckNo}
                disabled={
                  submitted ||
                  tid != localStorage.getItem("user") ||
                  reschedule.status
                }
              />
            </div>
          </div>
          <div className="field-row">
            <div>
              Start Mileage
              <input
                type="text"
                name="startMileage"
                onChange={handleChange}
                value={formValues.startMileage}
                disabled={
                  submitted ||
                  tid != localStorage.getItem("user") ||
                  reschedule.status
                }
              />
            </div>
            <div>
              End Mileage
              <input
                type="text"
                name="endMileage"
                onChange={handleChange}
                value={formValues.endMileage}
                disabled={
                  submitted ||
                  tid != localStorage.getItem("user") ||
                  reschedule.status
                }
              />
            </div>
          </div>
        </div>
        <div className="field-c field-cTwo">
          <div className="field-row">
            <div>
              Ambient Temperature (F)
              <input
                type="text"
                name="ambientTemperature"
                onChange={handleChange}
                value={formValues.ambientTemperature}
                disabled={
                  submitted ||
                  tid != localStorage.getItem("user") ||
                  reschedule.status
                }
              />
            </div>
          </div>
          <div className="">
            <div>
              Weather
              <div>
                <input
                  type="radio"
                  name="weather"
                  onChange={handleChange}
                  checked={formValues.weather === "Cloudy"}
                  value="Cloudy"
                  disabled={
                    submitted ||
                    tid != localStorage.getItem("user") ||
                    reschedule.status
                  }
                />{" "}
                Cloudy
                <input
                  type="radio"
                  name="weather"
                  onChange={handleChange}
                  checked={formValues.weather === "Overcast"}
                  value="Overcast"
                  disabled={
                    submitted ||
                    tid != localStorage.getItem("user") ||
                    reschedule.status
                  }
                />{" "}
                Overcast
                <input
                  type="radio"
                  name="weather"
                  onChange={handleChange}
                  checked={formValues.weather === "Sunny"}
                  value="Sunny"
                  disabled={
                    submitted ||
                    tid != localStorage.getItem("user") ||
                    reschedule.status
                  }
                />{" "}
                Sunny
              </div>
            </div>
            <div>
              Humidity
              <div>
                <input
                  type="radio"
                  name="humidity"
                  onChange={handleChange}
                  checked={formValues.humidity === "Dry"}
                  value="Dry"
                  disabled={
                    submitted ||
                    tid != localStorage.getItem("user") ||
                    reschedule.status
                  }
                />{" "}
                Dry
                <input
                  type="radio"
                  name="humidity"
                  onChange={handleChange}
                  checked={formValues.humidity === "Humid"}
                  value="Humid"
                  disabled={
                    submitted ||
                    tid != localStorage.getItem("user") ||
                    reschedule.status
                  }
                />{" "}
                Humid
                <input
                  type="radio"
                  name="humidity"
                  onChange={handleChange}
                  checked={formValues.humidity === "Rain"}
                  value="Rain"
                  disabled={
                    submitted ||
                    tid != localStorage.getItem("user") ||
                    reschedule.status
                  }
                />{" "}
                Rain
              </div>
            </div>
          </div>
          {!submitted && reschedule.status === false && (
            <button
              style={{ width: "100%", marginTop: "25px" }}
              type="button"
              onClick={handleSubmit}
              disabled={submitted || tid != localStorage.getItem("user")}
            >
              Submit
            </button>
          )}
          {submitted && (
            <label>
              Submitted by {formValues.firstName} {formValues.lastName}
            </label>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddFieldActivity;
