import React from "react";
import { ColorModeContext, useMode, tokens } from "../theme";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Mainsidebar from "../general/global/Sidebar";
import Topbar from "../general/global/topbar";

function MainLayout({ children }) {
  const [theme, colorMode] = useMode();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  });

  const rem_token = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            "& .ps-sidebar-root": {
              border: "none",
            },
            "& .ps-sidebar-container": {
              // backgroundColor: `${colors.grey[800]} !important`,
              backgroundColor: `transparent !important`,
            },
            "& .ps-menu-button:hover": {
              backgroundColor: `${colors.grey[700]} !important`,
            },
          }}
        >
          <div id="app" style={({ height: "100vh"}, { display: "flex" })}>
            {/* <Mainsidebar /> */}
            <main className="content">
              <Topbar rem_token={rem_token} />
              {children}
            </main>
          </div>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default MainLayout;
