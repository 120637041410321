import React, { useEffect, useState } from "react";
import MainLayout from "../../../layout/MainLayout";
import { Box, Button, Paper, Typography } from "@mui/material";
import ProjectInfo from "../../../components/projects/projectInfo";
import Reports from "../../../components/projects/cards/reports";
import ApproveList from "../../../components/ApproveListForQCandQA";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { api_rout } from "../../../Api-Config/config";
import ApproveListModal from "../../../components/ApproveListForQCandQA/ApproveListModal";
import ProjectIfoOnAccordion from "../../../components/projects/ProjectIfoOnAccordion";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export const QCVirifivcationList = () => {
  const { user } = useSelector((state) => state.user);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { state } = useLocation();

  const [data, setData] = useState([]);
  const [rows, setRows] = useState();

  // State for Open Modal
  const [open, setOpen] = useState(false);

  // function for close the modal

  const handleClose = () => {
    setOpen(false);
  };

  // function for get the data of verification list
  const getAllWorkData = async () => {
    try {
      //https://sig.eastus.cloudapp.azure.com/backend/concmixdesign/get/by/id/2
      const response = await axios.get(
        `${api_rout}/concmixdesign/get/by/id/${state.id}`
      );
      console.log(response.data);
      const data = response.data.map((item, i) => {
        return { ...item, slNo: i + 1 };
      });

      setData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllWorkData();
  }, []);

  const column = [
    { field: "slNo", title: "Sl No.", width: 5 },
    { field: "mixDescription", title: "Mix Description", width: 10 },
    { field: "mixId", title: "mixId", width: 10 },
    { field: "version", title: "version", width: 10 },
    { field: "wcRatio", title: "Wc Ratio", width: 10 },
    { field: "water", title: "Water", width: 10 },
    { field: "sand", title: "Sand", width: 10 },
    { field: "sNo8", title: "SNo8", width: 10 },
    { field: "sNo67", title: "SNo67", width: 10 },
    { field: "sNo4", title: "SNo4", width: 10 },
    { field: "s3In", title: "S3 In", width: 10 },
    { field: "flyAsh", title: "Fly Ash", width: 10 },
    { field: "Comments", title: "Comments", width: 10 },
    { field: "sand", title: "Mix Description", width: 10 },
    {
      field: "qcStatus",
      title: "Status",
      width: 10,
      render: (params) => {
        let color;
        if (params.qcStatus) {
          color = "green";
        } else if (params.qcStatus === null) {
          color = "#F29339";
        } else {
          color = "red";
        }
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "100px",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                minWidth: "auto",
                marginRight: "5px",
                color: colors.grey[300],
                // backgroundColor: colors.primary[500],
              },
            }}
          >
            {params.qcStatus === 1 ? (
              <div
                style={{
                    background: color,
                    borderRadius: "20px",
                    textAlign: "center",
                    padding:"5px"
                }}
              >Approve</div>
            ) : params.qcStatus === null ? (
              <div
                style={{
                //   height: "20px",
                  background: color,
                  borderRadius: "20px",
                  textAlign: "center",
                  padding:"5px"
                }}
              >Not Approve</div>
            ) : 
            <div
            style={{
                background: color,
                borderRadius: "20px",
                textAlign: "center",
                padding:"5px"
            }}
          >Disapprove</div>
            }
          </Box>
        );
      },
    },

    // {
    //   field: "action",
    //   title: "View",
    //   width: 100,
    //   // valueGetter: (params) => params.row.id,
    //   render: (params) => {
    //     return (
    //       <Box
    //         sx={{
    //           "& .grid-actionsWrap": {
    //             display: "flex",
    //             justifyContent: "space-between",
    //             width: "100px",
    //           },
    //           "& .grid-actionsWrap Button": {
    //             fontSize: "13px",
    //             padding: "4px",
    //             minWidth: "auto",
    //             marginRight: "5px",
    //             color: colors.grey[300],
    //             // backgroundColor: colors.primary[500],
    //           },
    //         }}
    //       >
    //         <div className="grid-actionsWrap">
    //           <Button
    //             onClick={() =>
    //               //   navigate("/subContractorDash", {
    //               //     state: {
    //               //       id: params.subContractorId,
    //               //     },
    //               //   })
    //               {
    //                 setOpen(true);
    //                 const {
    //                   mixDescription,
    //                   wcRatio,
    //                   water,
    //                   sand,
    //                   sNo8,
    //                   sNo67,
    //                   sNo4,
    //                   s3In,
    //                   flyAsh,
    //                   comments,
    //                 } = params;

    //                 setRows({
    //                   mixDescription,
    //                   wcRatio,
    //                   water,
    //                   sand,
    //                   sNo8,
    //                   sNo67,
    //                   sNo4,
    //                   s3In,
    //                   flyAsh,
    //                   comments,
    //                 });
    //               }
    //             }
    //             variant="contained"
    //           >
    //             <VisibilityOutlinedIcon />
    //           </Button>
    //           {/* <Button
    //             onClick={(e) => onButtonClick(e, params)}
    //             variant="contained"
    //           >
    //             <EditOutlinedIcon />
    //           </Button> */}
    //         </div>
    //       </Box>
    //     );
    //   },
    //   width: 120,
    // },
  ];

  return (
    <MainLayout>
      <Box
        sx={{
          p: 3,
          "& h5": {
            fontSize: "16px",
            fontWeight: "600",
          },
        }}
      >
        <div className="user_cards_wrap">
          <div className="row  mb-3">
            <div className="col-md-9 col-12 mb-3">
              <ProjectIfoOnAccordion />
            </div>
            <div className="col-md-3 col-12 mb-3">
            <Paper  sx={{ padding: "10px",display:"flex",alignItems:"center",gap:"10px" }}>
                <Typography variant="h3" gutterBottom>
                  {+" " + user && user.firstName + " " + user.lastName}
                </Typography>
                <Typography variant="h4" gutterBottom>
                ({localStorage.getItem("role") ? localStorage.getItem("role").charAt(0).toUpperCase()+ localStorage.getItem("role").slice(1): ""})
                </Typography>
              </Paper>
            </div>
            {/* <ProjectInfo /> */}
          </div>
          <div className="col-md-3 col-12 mb-3">
            <Reports />
          </div>
          <div className="col-md-12 col-12 mb-3">
            <ApproveList title="Approve List" columns={column} data={data} />
          </div>
        </div>
      </Box>
      <ApproveListModal open={open} handleClose={handleClose} row={rows} />
    </MainLayout>
  );
};
