import React, { useEffect } from "react";
import { TextField, Button, FormControlLabel, Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useFormik } from "formik";
import MainLayout from "../../layout/MainLayout";
import { useState } from "react";
import apiService from "../../data/apiService";
import { openDB } from "idb";

const Add_project = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [projectsList, setProjectsList] = useState("");

  const [projectNo, setProjectNo] = useState("");
  const [projectName, setProjectName] = useState("");
  const [clientId, setClientId] = useState("");
  const [contactphone, setContactphone] = useState("");
  const [sectorId, setSectorId] = useState("");
  const [phaseId, setPhaseId] = useState("");
  const [stateId, setStateId] = useState("");
  const [countyOrDistrict, setCountyOrDistrict] = useState("");
  const [city, setCity] = useState("");
  const [countryId, setCountryId] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [description, setDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleProjectNoChange = (event) => {
    setProjectNo(event.target.value);
  };
  const handleProjectNameChange = (event) => {
    setProjectName(event.target.value);
  };
  const handleClientIdChange = (event) => {
    setClientId(event.target.value);
  };
  const handleContactphoneChange = (event) => {
    setContactphone(event.target.value);
  };
  const handleSectorIdChange = (event) => {
    setSectorId(event.target.value);
  };
  const handlePhaseIdChange = (event) => {
    setPhaseId(event.target.value);
  };
  const handleStateIdChange = (event) => {
    setStateId(event.target.value);
  };
  const handleCountyOrDistrictChange = (event) => {
    setCountyOrDistrict(event.target.value);
  };
  const handleCityChange = (event) => {
    setCity(event.target.value);
  };
  const handleCountryIdChange = (event) => {
    setCountryId(event.target.value);
  };
  const handleLatitudeChange = (event) => {
    setLatitude(event.target.value);
  };
  const handleLongitudeChange = (event) => {
    setLongitude(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };
  const handleVideoChange = (event) => {
    const vidfile = event.target.files[0];
    setSelectedVideo(vidfile);
  };

  // Check whether Online/Offline
  useEffect(() => {
    const handleNetworkChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('offline', handleNetworkChange);

    return () => {
      window.removeEventListener('online', handleNetworkChange);
      window.removeEventListener('offline', handleNetworkChange);
    };
  }, []);
  console.log(navigator.onLine);

  // IndexedDB
  const addProject = async () => {
    console.log("entered addProject");
    const db = await openDB("projectsDB", 1);
    const transaction = db.transaction("projects", "readwrite");
    const objectStore = transaction.objectStore("projects");
    const newProject = {
      id: 'id',
      projectNo,
      projectName,
      clientId,
      contactphone,
      sectorId,
      phaseId,
      stateId,
      countyOrDistrict,
      city,
      countryId,
      latitude,
      longitude,
      description
    };
    await objectStore.add(newProject);

    console.log("end addProject");
  };

  // Register Event for online sync
  const syncData = async () => {
    if ('serviceWorker' in navigator && 'SyncManager' in window) {
      try {
        await navigator.serviceWorker.ready;
        await navigator.serviceWorker.ready.then((registration) =>
          registration.sync.register('sync-projects')
        );
        console.log('Sync event registered.');
      } catch (error) {
        console.error('Error registering sync event:', error);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(navigator.onLine){
      console.log("online")

    const formData = new FormData();
    formData.append("projectNo", projectNo);
    formData.append("projectName", projectName);
    formData.append("clientId", clientId);
    formData.append("contactphone", contactphone);
    formData.append("sectorId", sectorId);
    formData.append("phaseId", phaseId);
    formData.append("stateId", stateId);
    formData.append("countyOrDistrict", countyOrDistrict);
    formData.append("city", city);
    formData.append("countryId", countryId);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("description", description);
    formData.append("images", selectedImage);
    formData.append("video", selectedVideo);

    console.log(formData);
    addProject();

    try {
      // Make API request to submit the form data
      const response = await apiService.addNewProject(formData);
    } catch (error) {
      console.log("offline");
      console.error("Error submitting form:", error);
    }
  }else{
    console.log("offline");
    addProject();
    syncData();
  }
  };

  return (
    <MainLayout>
      <Box
        sx={{
          marginTop: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
          "& #images": {
            paddingLeft: "120px",
          },
          "& #video": {
            paddingLeft: "120px",
          },
        }}
      >
        <div className="pageFormWrap">
          <Typography component="h1" variant="h3">
            Add Project
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Project Number"
                  name="projectNo"
                  id="projectNo"
                  value={projectNo}
                  onChange={handleProjectNoChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Project Name"
                  name="projectName"
                  id="projectName"
                  value={projectName}
                  onChange={handleProjectNameChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Client Name"
                  name="clientId"
                  id="clientId"
                  value={clientId}
                  onChange={handleClientIdChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Contact Number"
                  name="contactphone"
                  id="contactphone"
                  value={contactphone}
                  onChange={handleContactphoneChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Sector"
                  name="sectorId"
                  id="sectorId"
                  value={sectorId}
                  onChange={handleSectorIdChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Phase"
                  name="phaseId"
                  id="phaseId"
                  value={phaseId}
                  onChange={handlePhaseIdChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="State"
                  name="stateId"
                  id="stateId"
                  value={stateId}
                  onChange={handleStateIdChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="County"
                  name="countyOrDistrict"
                  id="countyOrDistrict"
                  value={countyOrDistrict}
                  onChange={handleCountyOrDistrictChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="City"
                  name="city"
                  id="city"
                  value={city}
                  onChange={handleCityChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Country"
                  name="countryId"
                  id="countryId"
                  value={countryId}
                  onChange={handleCountryIdChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Latitude"
                  name="latitude"
                  id="latitude"
                  value={latitude}
                  onChange={handleLatitudeChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Longitude"
                  name="longitude"
                  id="longitude"
                  value={longitude}
                  onChange={handleLongitudeChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Description"
                  name="description"
                  id="description"
                  value={description}
                  onChange={handleDescriptionChange}
                  fullWidth
                  margin="normal"
                  multiline
                  maxRows={3}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Uplaod Image"
                  name=""
                  id="images"
                  type="file"
                  onChange={handleImageChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Uplaod Video"
                  name=""
                  id="video"
                  type="file"
                  onChange={handleVideoChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Box>
    </MainLayout>
  );
};

export default Add_project;
