import React from "react";
import { useState } from "react";
import { TextField, Button, FormControlLabel, Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useFormik } from "formik";
import * as yup from "yup";
import MainLayout from "../../layout/MainLayout";

export default function SlumpHCC() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [BatchIdList, setbatchIdList] = useState([]);

  const formik = useFormik({
    initialValues: {},
    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      try {
        // Call the API to submit the form data
        const response = await fetch(
          "https://sig.eastus.cloudapp.azure.com/backend/concmixdesign/create",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          }
        );

        if (response.ok) {
          // Handle successful API response
          console.log("User added successfully");
          console.log(values);
        } else {
          // Handle API error
          console.error("Failed to add user");
        }
      } catch (error) {
        // Handle network or other errors
        console.error("Error occurred:", error);
      }
    },
  });

  return (
    <Box>
      <MainLayout>
        <Box sx={{ padding: "15px" }}>
          <h2>Slump and Hydraulic-Cement Concrete</h2>
          <Box sx={{ padding: "15px", border: "1px solid #eee" }}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Sample Id/Name"
                    name="testId"
                    value={formik.values.testId}
                    onChange={formik.handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Batch Id"
                    name="batchId"
                    value={formik.values.batchId}
                    onChange={formik.handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                <TextField
                    label="QA/QC"
                    name="qaqc"
                    value={formik.values.qaqc}
                    onChange={formik.handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                <TextField
                    label="Pass/Fail"
                    name="passFail"
                    value={formik.values.passFail}
                    onChange={formik.handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Slump to the Nearest 0.25 inch"
                    name="slump"
                    value={formik.values.slump}
                    onChange={formik.handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Slump Retest"
                    name="slumpRT"
                    value={formik.values.slumpRT}
                    onChange={formik.handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Average Slump Value"
                    name="slumpAvg"
                    value={formik.values.slumpAvg}
                    onChange={formik.handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Tester"
                    name="tester"
                    value={formik.values.tester}
                    onChange={formik.handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Date Tested"
                    name="dateTested"
                    value={formik.values.dateTested}
                    onChange={formik.handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    label="Comment"
                    name="comments"
                    value={formik.values.comments}
                    onChange={formik.handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="QC Verifier"
                    name="qcVerifier"
                    value={formik.values.qcVerifier}
                    onChange={formik.handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="QA Verifier"
                    name="qaVerifier"
                    value={formik.values.qaVerifier}
                    onChange={formik.handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}><Button type="submit" variant="contained" color="primary">Submit</Button></Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </MainLayout>
    </Box>
  );
}
