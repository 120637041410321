import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Modal from "@mui/material/Modal";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "500px",
  overflow: "auto",
};

export const WorkEditModalForSubContractor = (props) => {
  const {
    open,
    handleClose,
    InputFildes,
    rejectData,
    setFormData,
    formData,
    handleformSubmit,
  } = props;
  // const [formData, setFormData] = React.useState(rejectData? rejectData:{});
  const [edit, setEdit] = React.useState(true);

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "file") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      // const {value} = event.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        max-width="lg"
      >
        <Box
          sx={{
            ...style,
            maxWidth: "840px",
            width: "80%",
            borderRadius: "6px",
            position: "relative",
            height: "auto",
            "& .closeModal": {
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
              backgroundColor: "#333",
              lineHeight: "normal",
              textAlign: "center",
              borderRadius: "4px",
              color: "#fff",
              border: "2px solid #333",
              transition: "all 0.1s"
            },
            "& .closeModal:hover": {
              color: "#333",
              backgroundColor: "#fff"
            }
            // height: "80vh",
          }}
        >
          <div className="closeModal" onClick={() => {
                handleClose();
              }}>
            <CloseOutlinedIcon />
          </div>
          {/* <form onSubmit={handleSubmit}> */}
          <Grid container spacing={3} className="pt-4">
            {InputFildes?.map((item, i) =>
              item.details.type === "file" ? (
                <Grid item xs={12} sm={4} md={4} className="pt-0">
                  <TextField
                    // disabled={edit}
                    type={item.details.type}
                    helperText={item.label}
                    size="small"
                    name={item.name}
                    // id="author"
                    // value={formData[item.clomName] || ''}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
              ) : item.details.type === "select" ? (
                <Grid item xs={12} sm={4} md={4} className="pt-0">
                  <TextField
                    //  disabled={edit}
                    id="outlined-select-currency"
                    select
                    size="small"
                    fullWidth
                    margin="normal"
                    label={item.details.label}
                    value={formData[item.name]}
                    onChange={handleInputChange}
                    name={item.name}
                    defaultValue="0"
                  >
                    <MenuItem value={""}>"Select options"</MenuItem>
                    {item.details.option &&
                      item.details.option.data.map((options) => (
                        <MenuItem key={options.value} value={options.value}>
                          {options.label}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              ) : (
                <Grid item xs={12} sm={4} md={4} className="pt-0">
                  <TextField
                    //  disabled={edit}
                    type={item.details.type}
                    label={item.details.label}
                    size="small"
                    name={item.name}
                    // id="author"
                    value={formData[item.name] || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
              )
            )}
            <Grid item xs={12} sm={6} md={8} className="pt-0">
              <Box sx={{
                border: "1px solid #ddd",
                padding: "8px 10px",
                margin: "16px 0 8px",
                width: "100%",
                // backgroundColor: "#f6f6f6",
                borderRadius: "4px",
                position: "relative",
                minHeight: "38.3px"
              }}>
              <span style={{
                position: "absolute",
                backgroundColor: "white",
                top: "-11px",
                left: "10px",
                color: "rgba(0, 0, 0, 0.6)",
                padding: "0 5px",
                fontSize: "13px"
              }}>qcComment</span> {formData.qcComment}
              </Box>
            </Grid>
          </Grid>
          {/* </form> */}

          <Grid
            item
            xs={12}
            sx={{ gap: 10, display: "inline-block", width: "100%" }}
            className="pt-3"
          >
            {/* {
                    edit &&  <Button
                    className="me-2"
                    variant="contained"
                    color="primary"
                    onClick={()=> setEdit((edit)=> !edit)}
                    >
                        Edit
                    </Button>
                }
                */}
            {/* {
                    !edit && <> */}
            <Button
              className="ms-2 float-end"
              variant="contained"
              color="primary"
              onClick={handleformSubmit}
            >
              Submit
            </Button>
            <Button
              className="ms-2 float-end"
              variant="contained"
              color="primary"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            {/* </>
                } */}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
