import React, { useState } from "react";
import { useEffect } from "react";
import Webcam from "react-webcam";
import { useRef } from "react";
import axios from "axios";
import $ from "jquery";
import MainLayout from "../layout/MainLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { api_rout } from "../Api-Config/config";
import Typography from "@mui/material/Typography";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ImageHoverandDelete } from "../components/ImageHoverandDelete";
import { v4 as uuidv4 } from 'uuid';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { notification } from "antd";


const videoConstraints = {
  width: 2480,
  height: 3508,
  facingMode: "environment",
};

const Camera = (props) => {
  // get user datta from redux
  const { user } = useSelector((state) => state.user);
  console.log(user);

  // this props from CameraAndVideoCapture page

  const { setCameraDetails, setLocation } = props;

  const { state } = useLocation();
  console.log(state);

  const navigate = useNavigate();

  const webCamRef = useRef(null);
  const [url, setUrl] = useState(null);
  const [snapShot, setSnapShot] = useState(null);
  const [urlArr, setUrlArr] = useState([]);


  // const [projects, setProjects] = useState([]);
  const [selectProject, setSelectProject] = useState("");
  const [selectProjectName, setSelectProjectName] = useState("");
  const [currentLatitude, setCurrentLatitude] = useState("");
  const [currentLongitude, setCurrentLongitude] = useState("");
  const [value, setValue] = React.useState(1);
  const [open, setOpen] = React.useState(false);


  //Check the user is allowed to Not use to capture the photo

  const getshehuleActivityByScheduleId = async () => {
    try {
      const response = await axios.get(
        `${api_rout}/scheduleactivity/get/current/version/${state.id}`
      );
      if (response.data[0].status === "completed") {
        alert("You are alredy submited ");
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const fetchProjects = async () => {
    try {
      const response = await fetch(
        "https://sig.eastus.cloudapp.azure.com/backend/project/get/all"
      );
      const data = await response.json();
      console.log(data);
      // setProjects(data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const GetRequiredMegapixels = () => {
    const printWidthInches = 8;
    const printHeightInches = 10;
    const desiredPPI = 300;

    // Calculate required resolution
    const resolution =
      (printWidthInches * desiredPPI * (printHeightInches * desiredPPI)) /
      1000000;

    console.log("Required resolution (in megapixels):", resolution);
    return resolution;
  };

  function checkCameraResolution() {
    // Request access to the camera
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(function (stream) {
        // Get the video track from the stream
        const track = stream.getVideoTracks()[0];
        // Get the settings object from the track
        const settings = track.getSettings();
        // Calculate the megapixel resolution
        const resolution = (settings.width * settings.height) / 1000000;
        // Display the resolution in the console or on the page
        console.log("Camera resolution:", resolution.toFixed(2), "MP");
        // Stop the camera stream
        stream.getVideoTracks()[0].stop();
      })
      .catch(function (error) {
        console.error("Error accessing camera:", error);
      });
  }

  const getDeviceCameraResolution = (width, height) => {
    const pixels = width * height; // Replace with your specific pixel count

    // Convert to megapixels
    const megapixels = pixels / 1000000;

    console.log("Megapixels:", megapixels);
    return megapixels;
  };

  const getfetures = async () => {
    //   let features = {
    //     audio: true,
    //     video: {
    //         width: { ideal: 1800 },
    //         height: { ideal: 900 }
    //     }
    // };
    //   let display = await navigator.mediaDevices
    //                 .getUserMedia(features);

    // Returns a sequence of MediaStreamTrack objects
    // representing the video tracks in the stream

    // let settings = display.getVideoTracks()[0]
    //     .getSettings();

    // let width = settings.width;
    // let height = settings.height;
    //This is for Mega pixcil check
    // console.log('Actual width of the camera video: '
    //     + width + 'px');
    // console.log('Actual height of the camera video:'
    //     + height + 'px');
    //   let deviceResolution =  getDeviceCameraResolution(width, height);
    //   let Required = GetRequiredMegapixels();
    //   if(deviceResolution < Required){
    //     alert("Camera resolution is too low. Please use another Device.");
    //     return;
    //   }
    //                 var w = window.outerWidth;
    // var h = window.outerHeight;
    // var d = Math.sqrt(w*w + h*h);
    // console.log('Width: ' + w);
    // console.log('Height: ' + h);
    // console.log('Diagonal: ' + Math.ceil(d));
    //                 const diagonalSize = Math.ceil(d);

    //                 const ppi = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2)) / diagonalSize;

    //                 console.log("PPI:", ppi);

    // By Resolution
    setTimeout(myGreeting, 1000);
    function myGreeting() {
      let check = checkCameraResolutionTwo(1920, 1080);

      if (check) {
        return;
      } else {
        //  alert("Camera resolution is too low. Please use another Device.");
      }
    }
  };
  useEffect(() => {
    // getshehuleActivityByScheduleId();
  }, []);
  useState(() => {
    // fetchProjects();
    getfetures();
    // checkCameraResolution();
  }, []);

  const handleProjectChange = (event) => {
    setSelectProject(event.target.value);
    const selectedIndex = event.target.selectedIndex;
    const selectedOption = event.target.options[selectedIndex];
    const selectedText = selectedOption.text;
    setSelectProjectName(selectedText);
  };

  const handleSubmit = async (event) => {
    setOpen(true)
    event.preventDefault();
    let urls = urlArr.map((item)=> item.files)
    console.log("Selected Project No:", selectProject);
    console.log("Selected Project Name:", selectProjectName);
    console.log("Longitude:", currentLongitude);
    console.log("Latitude:", currentLatitude);
    console.log("image:", urls);

    if (value === undefined) {
      alert("Do you want to capture More pictures ?");
      return;
    }
    if (urlArr.length === 0) {
      alert("Please capture an image.");
      return;
    }

    const formData = new FormData();
    formData.append("projectId", Number(localStorage.getItem("projectId")));
    formData.append("latitude", currentLatitude);
    formData.append("longitude", currentLongitude);
    for (let i = 0; i < urls.length; i++) {
      formData.append('images', urls[i]);
  }
    // formData.append("images", urls);
    formData.append("scheduleId", state.id);
    formData.append("createdBy", user.id);
    // The qcvrifaier and qaVerifier is demo only
    formData.append("qcVerifier", "Hakuna");
    formData.append("qaVerifier", "Matata");

    formData.append("submit", value);
    // try {
    //   // Make API request to submit the form data
    //   const response = await axios.post(
    //     "https://sig.eastus.cloudapp.azure.com/backend/onsite/add",
    //     formData,
    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     }
    //   );

    //   console.log("Form submitted successfully:", response.data);
    //   alert("Form submitted successfully!");
    //   await resetPhoto();
    // } catch (error) {
    //   console.error("Error submitting form:", error);
    //   alert("Error submitting form");
    // }

    try {
      const response = await axios.post(
        `${api_rout}/onsitephotovideo/add`,
        formData,{
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }
      );
      console.log(response.data);
      setUrlArr([])
      setOpen(false)
      notification.success({
        message: "Success",
        description: "Image Uploaded successfully!",
        placement: "topRight",
        duration: 2,
        style: {
          marginTop: "50px",
          textTransform: "capitalize",
        },
      });
    
      // alert("Image Uploaded successfully!");
    } catch (error) { 
      notification.error({
        message: "Erro",
        description: "Image Uploaded unsuccessful!",
        placement: "topRight",
        duration: 3,
        style: {
          marginTop: "50px",
          textTransform: "capitalize",
        },
      });
      setOpen(false)
      console.log("Error submitting form:", error);
    }
  };

  function checkImageResolution(imageUrl) {
    // Create an Image object
    var img = new Image();
    img.onload = function () {
      // Create a canvas element
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      // Set the canvas dimensions to match the image
      canvas.width = img.width;
      canvas.height = img.height;
      // Draw the image onto the canvas
      ctx.drawImage(img, 0, 0);
      // Get the image data from the canvas
      var imageData = ctx.getImageData(0, 0, img.width, img.height);
      // Calculate the PPI
      var ppiX = img.width / (imageData.width / 25.4);
      var ppiY = img.height / (imageData.height / 25.4);
      // Check if the PPI is 300 or not
      if (ppiX >= 300 && ppiY >= 300) {
        console.log(ppiY, ppiX, "The image has a resolution of 300 PPI.");
      } else {
        console.log(
          ppiY,
          ppiX,
          "The image does not have a resolution of 300 PPI."
        );
      }
    };
    img.src = imageUrl;
  }

  function checkCameraResolutionTwo(targetWidth, targetHeight) {
    // Request access to the camera
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(function (stream) {
        // Get the video track from the stream
        const track = stream.getVideoTracks()[0];
        // Get the settings object from the track
        const settings = track.getSettings();
        // Check if the camera resolution matches the target dimensions
        if (settings.width >= targetWidth && settings.height >= targetHeight) {
          console.log("Camera resolution is", targetWidth, "x", targetHeight);
          return true;
        } else {
          console.log(
            settings.width,
            settings.height,
            "Camera resolution is not",
            targetWidth,
            "x",
            targetHeight
          );
          return false;
        }
        // Stop the camera stream
        stream.getVideoTracks()[0].stop();
      })
      .catch(function (error) {
        console.error("Error accessing camera:", error);
      });
  }

  const capturePhoto = async () => {
    // Check if the Geolocation API is available in the browser
    if ("geolocation" in navigator) {
      // Retrieve the location data using the Geolocation API
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          // setLocation({
          //   latitude: latitude,
          //   longitude: longitude,
          // });
          // Do something with the location data
          console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
          setCurrentLatitude(latitude);
          setCurrentLongitude(longitude);
        },
        (error) => {
          console.error("Error retrieving location:", error);
          return;
        }
      );
    } else {
      console.error("Geolocation API not supported in this browser.");
      return;
    }
    // Capture photo
    const imageSrc = webCamRef.current.getScreenshot();
    console.log("Capture photo", imageSrc);
    checkImageResolution(imageSrc);
    setSnapShot(imageSrc);
    console.log(dataURLtoFile(imageSrc, "capturedImage.jpg"),"5555555555555555555555555555555")
    setUrl(dataURLtoFile(imageSrc, "capturedImage.jpg"));
    setUrlArr([...urlArr,{files:dataURLtoFile(imageSrc, "capturedImage.jpg"),url:imageSrc,id:uuidv4()}])
    $(".mainCamFormWrap").show();
    $(".mainCamWrap").hide();
    // resetPhoto();
    // setUrlArr([...urlArr,imageSrc])
    // setCameraDetails(dataURLtoFile(imageSrc, "capturedImage.jpg"));
  }

const deletImages = (id)=>{
  const update = urlArr.filter((image)=> image.id !== id)
  setUrlArr(update)
}

  useEffect(()=>{
    console.log(urlArr)
  },[urlArr])

  const resetPhoto = () => {
    setUrl(null);
    $(".mainCamFormWrap").hide();
    $(".mainCamWrap").show();
  };

  const onUserMedia = (e) => {
    console.log(e);
  };

  // Helper function to convert data URL to File object
  const dataURLtoFile = (dataURL, filename) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  return (
    <MainLayout>
      <Box
        sx={{
          display: "flex",
          padding: "10px 0 0",
          marginLeft: "20px",
          justifyContent: "space-between",
          "& button": { marginRight: "20px" },
        }}
      >
        <button
          type="button"
          class="btn btn-primary"
          onClick={() =>
            navigate(-1)
          }
        >
          Back
        </button>
        <Typography component="h1" variant="h3">
            Capture Photo
          </Typography>
        <button
          type="button"
          class="btn btn-primary"
          onClick={() =>
            navigate("/Video", {
              state: {
                id: state.id,
              },
            })
          }
        >
          Take video
        </button>
      </Box>
      <div 
      // className="mainCamWrap"
      className="mainPhotoWrap"
      >
        <Webcam
          ref={webCamRef}
          audio={false}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          onUserMedia={onUserMedia}
          mirrored={true}
        ></Webcam>
        <button className="mainCamBtn" type="button" onClick={capturePhoto}>
          Capture
        </button>
        <Box sx={{}}>
          <ul style={{ margin: "none",}}></ul>
        </Box>
      </div>
      <div 
      // className="mainCamFormWrap"
      className="photoBuffwrap"
      >
        <Box sx={{ display: "inline-block"}}>
          {
            urlArr.length !== 0 && urlArr.map((item,i)=> <ImageHoverandDelete
            img={item.url}
            id={item.id}
            handleDelete={deletImages}
            />)
          }
        

        </Box>

        {
          urlArr.length !== 0 && <form onSubmit={handleSubmit}>
          <div className="mainCamFormImgWrap">
            {/* {url && <img src={snapShot} alt="Screenshot" />} */}
          </div>
          <div 
          // style={{ display: "none"}}
          >
            {/* <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Do you want to capture More pictures ?
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel value={0} control={<Radio />} label="Yes" />
                <FormControlLabel value={1} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl> */}
          </div>
          {/* <select value={selectProject} onChange={handleProjectChange}>
            <option value="">Select Project</option>
            {projects.map((project) => (
              <option key={project.projectNo} value={project.projectNo}>
                {project.projectName}
              </option>
            ))}
          </select> */}
          <div className="mainCamFormBtnWrap">
            {/* <button type="button" onClick={resetPhoto}>
              Reset
            </button> */}
            <button type="submit">Submit</button>
          </div>
        </form>
        }
        
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </MainLayout>
  );
};

export default Camera;
