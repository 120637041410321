import React, { useState } from 'react'
import MainLayout from '../layout/MainLayout'
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import demoprofile from "../images/demoProfilepic.avif"
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { api_rout } from '../Api-Config/config';
import { getUserProfileApi } from "../Redux/GetUserDetails/userAPI"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Profile() {
    const dispatch = useDispatch();

    const { user, isLoading } = useSelector((state) => state.user);
    const [edit, setEdit] = useState(false);
    const [image, setImage] = useState("");
    const [firstName, setFirstName] = useState(user.length !== 0 ? user.firstName : " ");
    const [lastName, setLastName] = useState(user.length !== 0 ? user.lastName : " ");
    const [email, setEmail] = useState(user.length !== 0 ? user.email : "");
    const [company, setCompany] = useState(user.length !== 0 ? user.companyName : "");
    const [dob, setDob] = useState(user.length !== 0 ? user.DOB : "");
    const [mobNo, setMobNo] = useState(user.length !== 0 ? user.phoneNumber : "")
    const [designation, setDesignation] = useState(user.length !== 0 ? user.designation : "")
    const handleEdit = () => {
        setEdit(!edit)
    }

    const handleEditSubmit = async () => {
        try {
            const payload = {
                "firstName": firstName,
                "lastName": lastName,
                "email": email,
                "companyName": company,
                "phoneNumber": mobNo,
                "DOB": dob,
                "password": user.password,
                "designation": designation
            }
            const response = await axios.put(`${api_rout}/user/edit/${user.id}`, payload);
            console.log(response.data)
            await dispatch(getUserProfileApi(user.id))
            setEdit(!edit)

        }
        catch (err) {
            console.log(err);
        }
    }

    const handleChange = (setSate) => (e) => {
        setSate(e.target.value);
    }
    return (
        <MainLayout>
            {
                !edit ? <Paper
                    style={{ height: '100vh', }}
                >
                    <img
                        style={{ height: "200px", width: "200px", borderRadius: "50%" }}
                        src={user.profile ? user.profile : demoprofile} alt="profile picture" />
                    <Typography variant="h5" gutterBottom>User Name :  {user.length !== 0 ? user.firstName + " " + user.lastName : ""} </Typography>
                    <Typography variant="h5" gutterBottom>company Name :  {user.length !== 0 ? user.companyName : ""} </Typography>
                    <Typography variant="h5" gutterBottom>Email :  {user.length !== 0 ? user.email : ""} </Typography>
                    <Typography variant="h5" gutterBottom>DOB :  {user.length !== 0 ? user.DOB.slice(0, 10) : ""} </Typography>
                    <Typography variant="h5" gutterBottom>phone Number :  {user.length !== 0 ? user.phoneNumber : ""} </Typography>

                    <Button onClick={handleEdit}>
                        <EditIcon />
                    </Button>
                </Paper> : <Paper
                    style={{ height: '100vh', }}
                >

                    <Grid item xs={12} sm={12}>
                        <TextField
                            label="Uplaod Image"
                            name=""
                            id="images"
                            type="file"
                            //   onChange={handleImageChange}
                            fullWidth
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            label="First Name"
                            value={firstName}
                            onChange={handleChange(setFirstName)}
                            fullWidth
                            margin="normal"
                            maxRows={3}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            label="Last Name"
                            value={lastName}
                            onChange={handleChange(setLastName)}
                            fullWidth
                            margin="normal"
                            maxRows={3}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            disabled
                            label="Email"
                            value={email}
                            onChange={handleChange(setEmail)}
                            fullWidth
                            margin="normal"
                            maxRows={3}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            label="Company"
                            value={company}
                            onChange={handleChange(setCompany)}
                            fullWidth
                            margin="normal"
                            maxRows={3}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            type='date'
                            // label="DOB"
                            value={dob.slice(0, 10)}
                            onChange={handleChange(setDob)}
                            fullWidth
                            margin="normal"
                            maxRows={3}
                            helperText="DOB"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            label="phone Number"
                            value={mobNo}
                            onChange={handleChange(setMobNo)}
                            fullWidth
                            margin="normal"
                            maxRows={3}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            label="Designation"
                            value={designation}
                            onChange={handleChange(setDesignation)}
                            fullWidth
                            margin="normal"
                            maxRows={3}
                        />
                    </Grid>
                    <button onClick={handleEditSubmit}>
                        Submit
                    </button>
                    <button onClick={handleEdit}>
                        Cancel
                    </button>
                </Paper>
            }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </MainLayout>
    )
}
