import React from "react";
import MainLayout from "../../layout/MainLayout";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { api_rout } from "../../Api-Config/config";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { notification } from "antd";

export default function Existing_user_invite() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [userName, setUserName] = useState("");
  const [userNameList, setUserNameList] = useState([]);
  const [allusers, setAllUsers] = useState([]);
  const [email, setEmail] = useState();

  // get id fron react router dom
  const { state } = useLocation();

  // nevigate from react router dom
  const nevigate = useNavigate();

  // role Id from Local storage
  const roleId = localStorage.getItem("roleId");
  const rolePriority = localStorage.getItem("rolePriority");
  console.log(rolePriority);

  // roles list state

  const [roleList, setRolesList] = useState([]);
  const [role, setRole] = useState();

  const getRoleList = async () => {
    try {
      const response = await axios.get(`${api_rout}/role/all`);

      const filterRoleByUserRole = await response.data.filter((role) => {
        if (roleId != 5) {
          return role.priority > rolePriority && role.id != 6;
        } else {
          return role.priority > rolePriority;
        }
      });

      const data = await filterRoleByUserRole?.map((item) => {
        const payload = {
          value: item.id,
          label: item.name,
        };
        return payload;
      });

      setRolesList(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getAllUsers = async () => {
    try {
      const response = await axios.get(`${api_rout}/user/allusers`);
      console.log(response.data);
      const data = response.data?.map((item) => {
        const payload = {
          value: item.id,
          label: item.firstName + " " + item.lastName,
        };
        return payload;
      });
      setUserNameList(data);
      setAllUsers(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllUsers();
    getRoleList();
  }, []);

  const handleInviteSubmit = async () => {
    const projectID = localStorage.getItem("projectId");
    if (projectID && role && email) {
      try {
        var payload;
        if (Number(roleId) === 2) {
          payload = {
            email: email,
            roleId: role,
            projectId: Number(projectID),
            submoduleId:state.id,

            // subContractId: localStorage.getItem("userId"),
          };
        } else {
          payload = {
            email: email,
            roleId: role,
            projectId: projectID,
          };
        }
        console.log(payload);
        const response = await axios.post(
          `${api_rout}/common/add/by/invite`,
          payload
        );
        console.log(response.data);
        notification.success({
          message: "Success",
          description: "Invite sent successfully",
          placement: "topRight",
          duration: 2,
          style: {
            marginTop: "50px",
            textTransform: "capitalize",
          },
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      notification.error({
        message: "Error",
        description: "Please fill all fields",
        placement: "topRight",
        duration: 3,
        style: {
          marginTop: "50px",
          textTransform: "capitalize",
        },
      });
    }
  };

  return (
    <MainLayout>
      <Box
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 70px)",
          backgroundColor: "#f9f9f9"
        }}
      >
        <div className="pageFormWrap container bg-white p-3">
          <div className=" d-flex justify-content-between">
          <Typography component="h1" variant="h3">
            Send Invite
          </Typography>
            <Button variant="contained" onClick={() => nevigate("/invite")}>
              Invite New User
            </Button>
            </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={userNameList}
                // value={trialFinal}
                onChange={(newValue, e) => {
                  console.log("value", newValue, e);
                  if (!e) {
                    setUserName("");
                  } else {
                    setUserName(e.value);
                    let data = allusers?.filter((user) => user.id === e.value);
                    console.log("data", data);
                    setEmail(data[0].email);
                  }
                }}
                renderInput={(params) => {
                  // console.log(params,"selected")
                  return (
                    <TextField
                      {...params}
                      label="User name"
                      fullWidth
                      margin="normal"
                      size="small"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                disabled={true}
                helperText="Emial"
                name="email"
                size="small"
                value={email}
                fullWidth
                margin="normal"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={roleList}
                // value={trialFinal}
                onChange={(newValue, e) => {
                  console.log("value", newValue, e);
                  if (!e) {
                    setRole("");
                  } else {
                    setRole(e.value);
                  }
                }}
                renderInput={(params) => {
                  // console.log(params,"selected")
                  return (
                    <TextField
                      {...params}
                      label="Role"
                      size="small"
                      fullWidth
                      margin="normal"
                    />
                  );
                }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={4}>
          <TextField
           disabled={true}
                  label="Project Name"
                  name="email"
                //   value={formValues.email}
                  fullWidth
                  margin="normal"
                //   onChange={handleChange}
                />
          </Grid> */}

            <Grid item xs={12} sm={12} className="d-flex justify-content-end pt-0">
              <Button variant="contained" onClick={() => nevigate(-1)} className="me-2">
                Back
              </Button>
              <Button variant="contained" onClick={handleInviteSubmit} >
                Submit
              </Button>
            </Grid>
          </Grid>
        </div>
      </Box>
    </MainLayout>
  );
}
