import { useTheme } from '@emotion/react';
import { Box } from '@mui/material'
import React from 'react'
import { tokens } from '../../theme';
import MaterialTable from '@material-table/core';

export default function ApproveList(props) {
    const {columns, title,data} = props
    const [allData,setAllData] = React.useState(data ? data : [])
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  return (
        <Box
        sx={{
          "& .MuiDataGrid-root": {
            fontSize: "14px",
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-root p": {
            margin: 0,
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none !important",
            backgroundColor: colors.grey[900],
            fontSize: "14px",
          },
          "& .name-column--cell": {
            color: colors.primary[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[900],
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.grey[600],
          },
          "& .MuiDataGrid-footerContainer": {
            borderColor: colors.grey[800],
            backgroundColor: colors.grey[900],
          },
          "& .user_list_title": {
            color: colors.grey[300],
            fontSize: "22px",
            fontWeight: 600,
            margin: 0
          },
          "& .user_list_gridWrap": {
            backgroundColor: colors.grey[800],
          },
          "& .user_list_search": {
            backgroundColor: colors.grey[900],
          },
          "& .user_list_search input": {
            color: colors.grey[200],
          },
          "& .grid-actionsWrap": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          },
          "& .grid-actionsWrap Button": {
            fontSize: "13px",
            padding: "4px",
            minWidth: "auto",
            marginRight: "5px",
            backgroundColor: "transparent",
            boxShadow: "none",
          },
          "& .user_list_gridWrap .css-ig9rso-MuiToolbar-root": {
            minHeight: "auto",
            padding: "8px 15px",
          },
          "& .user_list_gridWrap table td": {
            padding: "10px 16px",
          },
        }}
      >
      <div className="user_list_gridWrap">
        <MaterialTable
            style={{ border: "1px solid #ddd", boxShadow: "none" }}
        title={<h2 className="user_list_title">{title}</h2>}
         columns={columns}
         data={data}
         options={{
          pageSize: 10,
          // {allData.length >= 10 ? 10 : datas.length },
          pageSizeOptions: [10, 20, 30],
          rowStyle: {
            height: "12px",
          },
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF",
          },
        }}
        />
        </div>
      </Box>
  )
}
