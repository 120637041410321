import React from "react";
import {
  TextField,
  Button,
  FormControlLabel,
  Box,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useFormik } from "formik";
import * as yup from "yup";
import MainLayout from "../../layout/MainLayout";

const Add_role = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

//   const validationSchema = yup.object({
//     firstName: yup.string().required("First Name is required"),
//     lastName: yup.string().required("Last Name is required"),
//     password: yup.string().required("Password is required"),
//     designation: yup.string().required("Designation is required"),
//     email: yup
//       .string()
//       .email("Invalid email address")
//       .required("Email is required"),
//     phoneNumber: yup.string().required("Phone number is required"),
//     client: yup.string().required("Client is required"),
//   });

  const formik = useFormik({
    initialValues: {
        name: "",
        description: "",
    },
    // validationSchema: validationSchema,
    onSubmit: async (values) => {
        console.log(values);
      try {
        // Call the API to submit the form data
        const response = await fetch("https://sig.eastus.cloudapp.azure.com/backend/role/create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });

        if (response.ok) {
          // Handle successful API response
          console.log("User added successfully");
          console.log(values);
        } else {
          // Handle API error
          console.error("Failed to add user");
        }
      } catch (error) {
        // Handle network or other errors
        console.error("Error occurred:", error);
      }
    },
  });

  return (
    <MainLayout>
      <Box
        sx={{
          marginTop: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="pageFormWrap">
          <Typography component="h1" variant="h3">
            Add Role
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Role Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                //   error={
                //     formik.touched.firstName && Boolean(formik.errors.firstName)
                //   }
                //   helperText={
                //     formik.touched.firstName && formik.errors.firstName
                //   }
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
              <TextField
                  label="Description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                //   error={
                //     formik.touched.lastName && Boolean(formik.errors.lastName)
                //   }
                //   helperText={
                //     formik.touched.lastName && formik.errors.lastName
                //   }
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}><Button type="submit" variant="contained" color="primary">Add Role</Button></Grid>
            </Grid>
          </form>
        </div>
      </Box>
    </MainLayout>
  );
};

export default Add_role;
