import React from "react";
import { useState } from "react";
import { ColorModeContext, tokens, useMode } from "../../theme";
import { Box, CssBaseline, ThemeProvider, useTheme } from "@mui/material";
import { Sidebar, Menu, MenuItem, useProSidebar, SubMenu } from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";
import LoginLogo from "../../images/SS.png";
// import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
// import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
// import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
// import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonIcon from "@mui/icons-material/Person";
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Navigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";


function Mainsidebar() {
  const { user, isLoading } = useSelector((state) => state.user);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // console.log(isMobile);

  const navigate = useNavigate();

  // const handleButtonClick = () =>{
  //   navigate('/dashboard');
  // }
  return (
    <Box
    sx={{
      "& .ps-menuitem-root a.ps-menu-button:hover": {
        // backgroundColor: "rgba(0,0,0,0.2) !important",
        backgroundColor: "transparent !important",
        color:`${colors.grey[100]}`,
      },
      "& .ps-menuitem-root .ps-submenu-content": {
        backgroundColor: "transparent !important",
      },
      "& .ps-menuitem-root .ps-submenu-content a.ps-menu-button":{
        color: `${colors.grey[100]}`
      }
    }}
    >
      <Sidebar className="mainSidebar" style={{ height: "auto" }} collapsed={isMobile}>
        <Menu>
          <MenuItem
            // icon={<MenuOutlinedIcon />}
            // onClick={() => {
            //   collapseSidebar();
            // }}
            style={{
              textAlign: "left",
              backgroundColor: "transparent !important",
              height: "38px",
              paddingLeft: "10px"
              // height: "59px",
              // marginBottom: "12px",
              // borderBottom: "1px solid",
              // borderColor: colors.grey[700]
            }}
          >
            <img
              alt="profile-ser"
              width="100%"
              src={`${LoginLogo}`}
              onClick={()=>navigate("/dashboard")}
              style={{ cursor: "pointer", width:"210px" }}
            />
          </MenuItem>
          {/* {
            user.length !== 0 ? (user.designation === "Admin" ? <MenuItem icon={<DashboardIcon />}  onClick={()=>navigate("/dashboard")}>Dashboard</MenuItem> : "" ):""
          }
          
          {
            user.length !== 0 ? (user.designation === "Admin" ? <MenuItem icon={<ApartmentIcon />}  onClick={()=>navigate("/projects")}>Projects</MenuItem> : "" ):""
          }
          {
            user.length !== 0 ? (user.designation === "Admin" ? <MenuItem icon={<SupervisedUserCircleIcon />}>Clients</MenuItem> : "" ):""
          }
          {
            user.length !== 0 ? (user.designation === "Admin" ? <MenuItem icon={<PeopleOutlinedIcon />}  onClick={()=>navigate("/users")}>Users</MenuItem> : "" ):""
          }
          {
            user.length !== 0 ? (user.designation === "Admin" ? <MenuItem icon={<AssignmentIcon />}>Reports</MenuItem> : "" ):""
          }
          {
            user.length !== 0 ? (user.designation === "Admin" ? <MenuItem icon={<PersonIcon />} onClick={()=>navigate("/SignUp")}>Profile</MenuItem> : "" ):""
          }
          {
            user.length !== 0 ? (user.designation === "Admin" ? <MenuItem icon={<PeopleOutlinedIcon />}  onClick={()=>navigate("/schedule")}>Schedule</MenuItem> : "" ):""
          }
          
          {
            user.length !== 0 ? (user.designation === "Admin" ? <SubMenu icon={<FeedOutlinedIcon />} label="Records">
            <MenuItem onClick={()=>navigate("/consctructionInfo")}>Construction Info</MenuItem>
            <MenuItem onClick={()=> navigate("/concreteBatch")}>Concrete Batch</MenuItem>
            <MenuItem onClick={()=>navigate("/concreteMix")}>Concrete Mix</MenuItem>
          </SubMenu> : "" ):""
          } */}
          
        </Menu>
      </Sidebar>
    </Box>
  );
}

export default Mainsidebar;
