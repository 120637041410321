import * as React from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import LoginLogo from "../images/SS.png";
import { useEffect } from "react";
import axios from "axios";
import { notification } from "antd";
import { inviteUserSignUpValidate } from "../helpers/inviteSignUpValidation";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {/* <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '} */}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function CompleteSignup() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    DOB: null,
    password: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        `https://sig.eastus.cloudapp.azure.com/backend/user/get/by/token/${token}`
      )
      .then((res) => {
        // eslint-disable-next-line array-callback-return
        Object.keys(res.data).map((key) => {
          if (res.data[key] !== null) {
            if (formValues.hasOwnProperty(key)) {
              setFormValues((prevValues) => ({
                ...prevValues,
                [key]: res.data[key],
              }));
            }
          }
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDateChange = (event) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      DOB: event,
    }));
  };

  const handleSubmit = () => {
    // const errors = inviteUserSignUpValidate(formValues);
    // setFormErrors(errors);
    // if (Object.keys(errors).length === 0) {
      axios
        .put(
          `https://sig.eastus.cloudapp.azure.com/backend/user/edit/by/token/${token}`,
          {
            ...formValues,
          }
        )
        .then(() => {
          notification.success({
            message: "Success",
            description: "Sign up completed",
            placement: "topRight",
            duration: 2,
            style: {
              marginTop: "50px",
              textTransform: "capitalize",
            },
          });
          navigate("/");
        })
        .catch((error) => {
          notification.error({
            message: "Erro",
            description: error.response.data.message,
            placement: "topRight",
            duration: 3,
            style: {
              marginTop: "50px",
              textTransform: "capitalize",
            },
          });
        });
    // }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Box sx={{ m: 1, pb: 3 }}>
            <img src={`${LoginLogo}`} width="300" alt="logo"></img>
          </Box>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" method="post" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  // error={formErrors.firstName ? true : false}
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={formValues.firstName}
                  onChange={handleChange}
                  helperText={formErrors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  error={formErrors.lastName ? true : false}
                  name="lastName"
                  required
                  fullWidth
                  id="lastName"
                  helperText={formErrors.lastName}
                  label="Last Name"
                  autoFocus
                  value={formValues.lastName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="company-name"
                  error={formErrors.companyName ? true : false}
                  name="companyName"
                  required
                  fullWidth
                  id="companyName"
                  label="Company Name"
                  helperText={formErrors.companyName}
                  autoFocus
                  value={formValues.companyName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={formValues.email}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  error={formErrors.phoneNumber ? true : false}
                  id="phone"
                  label="phoneNumber"
                  type="tel"
                  name="phoneNumber"
                  autoComplete="phone"
                  helperText={formErrors.phoneNumber}
                  value={formValues.phoneNumber}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  disableFuture
                >
                  <DatePicker
                    required
                    fullWidth
                    name="DOB"
                    id="DOB"
                    label="Date of Birth"
                    value={formValues.DOB}
                    onChange={handleDateChange}
                    renderInput={() => (
                      <TextField
                        error={formErrors.DOB ? true : false}
                        helperText={formErrors.DOB}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={formValues.password}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="I want to receive marketing promotions and updates via email."
                />
              </Grid>
            </Grid>
            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href={"./"} variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
