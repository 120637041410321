import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";

function Email_function_value({ handleCheckboxChange, values }) {
  return (
    <div>
      {values &&
        values.map((data, index) => (
          <FormControlLabel
            key={index}
            value={data}
            control={<Checkbox />}
            label={data}
            labelPlacement="end"
            onChange={handleCheckboxChange}
          />
        ))}
    </div>
  );
}

export default Email_function_value;
