import React, { useEffect } from "react";
import MainLayout from "../layout/MainLayout";
import { Box, Button } from "@mui/material";
import ProjectInfo from "../components/projects/projectInfo";
import Users from "../components/projects/cards/users";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

// Material Table
import MaterialTable from "@material-table/core";
import ExportCsv from "@material-table/exporters/csv";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { api_rout } from "../Api-Config/config";

export default function AllEngineer_view_page() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const projectId = localStorage.getItem("projectId");

  // role Id from Local storage
  const roleId = localStorage.getItem("roleId");


  const navigate = useNavigate();

  const [clickedRow, setClickedRow] = React.useState();
  const [data, setData] = React.useState([]);
  const [userByProjectId, setUsersByProjectId] = React.useState([]);

  const getAllUsersByProjectId = async () => {
    try {
      const response = await axios.get(
        `${api_rout}/user/get/users/by/project/${projectId}`
      );
      const data = response.data.filter((user) => user.roleId === 6);
      console.log(data);
      setUsersByProjectId(data);
    } catch (error) {
      console.log(error);
    }
  };

  const onButtonClick = (e, row) => {
    e.stopPropagation();
    setClickedRow(row);
  };
  const handleButtonClick = (projectNo) => {
    // Do something with the project ID
    console.log("Clicked project ID:", projectNo);
    // localStorage.setItem("projectId", projectNo);
    navigate("/engineerDash/" + projectNo);
  };

  const getEngineerByProjectAndRoleId = async () => {
    let projectId = await localStorage.getItem("projectId");
    try {
      const response = await axios.get(
        `${api_rout}/user/get/by/role/${projectId}/6`
      );
      let data = response.data.map((item) => {
        let payload = {
          ...item,
          fullName: item.firstName + " " + item.lastName,
        };
        return payload;
      });
      console.log(data);

      setData(data);
    } catch (error) {
      console.log("Error: " + error);
    }
  };

  useEffect(() => {
    getAllUsersByProjectId();
    getEngineerByProjectAndRoleId();
  }, []);

  const column = [
    { field: "id", title: "ID", width: 5 },
    { field: "companyName", title: "company Name", width: 10 },
    { field: "fullName", title: "Name", width: 150 },
    {
      field: "action",
      title: "Actions",
      width: 100,
      // valueGetter: (params) => params.row.id,
      render: (params) => {
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "100px",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                minWidth: "auto",
                marginRight: "5px",
                color: colors.grey[300],
                // backgroundColor: colors.primary[500],
              },
            }}
          >
            <div className="grid-actionsWrap">
              <Button
                onClick={() => handleButtonClick(params.id)}
                variant="contained"
              >
                <VisibilityOutlinedIcon />
              </Button>
              <Button
                onClick={(e) => onButtonClick(e, params)}
                variant="contained"
              >
                <EditOutlinedIcon />
              </Button>
            </div>
          </Box>
        );
      },
      width: 120,
    },
  ];
  return (
    <MainLayout>
      <Box
        sx={{
          p: 3,
          "& h5": {
            fontSize: "16px",
            fontWeight: "600",
          },
        }}
      >
        <div className="user_cards_wrap">
          <div className="row">
            {/* <div className="col-md-12 col-12 mb-3">
              <ProjectInfo />
            </div> */}
            <div className="col-md-4 col-12 mb-3">
              <Users totalUser={userByProjectId.length} />
            </div>
            <div className="col-md-12 col-12 mb-3">
              <Button
              disabled={roleId === "5" ? false : true}
               variant="contained"
               onClick={()=>navigate("/Exist_invite") }
              >
                Invite Engineer
              </Button>
              {/* All Engeneer List */}
              <MaterialTable
                title={<h2 className="user_list_title">Engineer List</h2>}
                columns={column}
                data={data}
              />
            </div>
          </div>
        </div>
      </Box>
    </MainLayout>
  );
}
