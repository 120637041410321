import {
  Backdrop,
  Box,
  useTheme,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import { useMode, tokens } from "../../theme";
import MainLayout from "../../layout/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import ProjectDetails from "./clientDash";
import ProjectInfo from "./projectInfo";
import SchecudleList from "./SchecudleList";
import Reports from "./cards/reports";
import Users from "./cards/users";
import Tickets from "./cards/tickets";
import axios from "axios";
import { api_rout } from "../../Api-Config/config";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate } from "react-router-dom";
import ProjectIfoOnAccordion from "./ProjectIfoOnAccordion";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MaterialTable from "@material-table/core";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { notification } from "antd";
import { SubContractorModuleView } from "../SubContractorModuleView";
import EastIcon from "@mui/icons-material/East";
import SubContractorRejList from "../subContractor/subContractorRejList";
import ApproveListModal from "../ApproveListForQCandQA/ApproveListModal";
import MessagesCard from "../messages/messagesCard";
import { WorkEditModalForSubContractor } from "../WorkEditModalForSubContractor";

const SubContractorDash = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  // role Id from Local storage
  const roleId = localStorage.getItem("roleId");

  const { user, isLoading } = useSelector((state) => state.user);
  const projectId = localStorage.getItem("projectId");
  const { state } = useLocation();
  const userID = user.id;
  const [subContractData, setSubContractData] = useState();

  const [engineerList, setEngineerList] = useState([]);
  const [userByProjectId, setUsersByProjectId] = useState([]);

  console.log(state, "whole state");
  console.log(userID, "User ID");

  const [subContract, setSubContract] = useState();
  const [load, setload] = useState(false);

  const [totaluserByproject, setTotaluserByproject] = useState();

  const [subModule, setSubModule] = useState([]);

  const [subModuleNastedData, setSubModuleNastedData] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  const [rejectData,setRejectData] = useState();
  const [reSubmitDataId,setResubmitDataId] = useState();
  const [InputFildes, setInputFildes] = useState([]);

  const [allRejectList,setAllRejectList] = useState([]);

  const [formData, setFormData] = useState({});


  const handleClose = () => {
    setOpenModal(false);
  };

  const getFormsAndExcelFileDownloadLink = async (tableName) => {
    try {
      const response = await axios.get(
        `${api_rout}/form/get/${tableName}`
      );
      console.log(response.data.data, "Data getFormsAndExcelFileDownloadLink");
      setInputFildes(response.data.data.form);
      // setTempLateDownloadLink(response.data.data.templateDownload);
    } catch (error) { 
      console.log(error)
    }
  };


const handleGetWorkData = async(rejectedUUID,approvalId,tableNameCase,tableName)=>{
 
    setOpenModal(true);
    setResubmitDataId({
      rejectedUUID:rejectedUUID,
      approvalId:approvalId,
    });

  try {
    const response = await axios.get(
      `${api_rout}/approval/get/enrty/details/${tableNameCase}/${rejectedUUID}`
    );
    console.log(response.data.data,"table all column");
    const {
      trialFinal, mixId, mixDescription, wcRatio, water, sand,sNo8, sNo67, sNo4, s3In,flyAsh,comments,qcComment
    } = response.data.data[response.data.data.length-1];
    setFormData({
      trialFinal, mixId, mixDescription, wcRatio, water, sand,sNo8, sNo67, sNo4, s3In,flyAsh,comments,qcComment
    })
    await getFormsAndExcelFileDownloadLink(tableNameCase)
  } catch (e) {
    console.log(e);
  }
}



const handleSubmitReFiledForms = async() => {
  const formDatas = new FormData();
  formDatas.append("trialFinal", formData.trialFinal);
  formDatas.append("mixId", formData.mixId);
  formDatas.append("mixDescription", formData.mixDescription);
  formDatas.append("wcRatio", formData.wcRatio);
  formDatas.append("water", formData.water);
  formDatas.append("sand", formData.sand);
  formDatas.append("sNo8", formData.sNo8);
  formDatas.append("sNo67", formData.sNo67);
  formDatas.append("sNo4", formData.sNo4);
  formDatas.append("s3In", formData.s3In);
  formDatas.append("flyAsh", formData.flyAsh);
  formDatas.append("comments", formData.comments);
  formDatas.append("projectId", localStorage.getItem("projectId"));
  formDatas.append("createdBy", user.id);
  formDatas.append("rejectedUUID", reSubmitDataId.rejectedUUID);
  formDatas.append("approvalId", reSubmitDataId.approvalId);
  console.log(formDatas);
  const formDataObj = {};
  formDatas.forEach((value, key) => (formDataObj[key] = value));
  console.log(formDataObj);
  try {
    // Make API request to submit the form data
    const response = await axios.post(
      `${api_rout}/concmixdesign/create`,
      formDatas
    );
    notification.success({
      message: "Success",
      description: "concrete mix created Sucessfully",
      placement: "topRight",
      duration: 2,
      style: {
        marginTop: "50px",
        textTransform: "capitalize",
      },
    });
    console.log(response);
    await getRejectedWoekData();
    handleClose();
  } catch (error) {
    notification.error({
      message: "Error",
      description: "An error occurred",
      placement: "topRight",
      duration: 2,
      style: {
        marginTop: "50px",
        textTransform: "capitalize",
      },
    });

  }
}


const handleEdit = () => {
  // navigate("/addConcreteMixDesign")
  navigate("/addConcreteMixDesign", {
        state:{
          reSubmitDataId:reSubmitDataId,
          data:rejectData,
        }
        
      })

}


  const getContract = async () => {
    try {
      const response = await axios.get(
        `${api_rout}/contract/by/project/${projectId}`
      );
      console.log(response.data, "contract by project Id");
      return response.data[0];
    } catch (err) {
      console.log(err);
    }
  };

  const getSubContractByContractId = async (ContractId) => {
    try {
      const response = await axios.get(
        `${api_rout}/subcontract/by/contract/${ContractId}`
      );
      console.log(response.data, "subcontract by contract Id");
      return response.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  // const getSubContractIdByUserId = async() => {
  //   try {
  //     const response = await axios.get(
  //       `${api_rout}/subcontract/by/contractor/77`
  //     );
  //     console.log(response.data, "subcontract by User Id");
  //     setSubContractData(response.data[0].contractId);
  //   } catch (error) {
  //     console.log(error);
  //     return false;
  //   }
  // }

  console.log(subContractData, "subContractData data");

  const onButtonClick = (e, row) => {
    e.stopPropagation();
  };
  const handleButtonClick = (WorkName,tableName) => {
    // Do something with the project ID
    // console.log("Clicked project ID:", projectNo);
    // // localStorage.setItem("projectId", projectNo);
    // navigate("/engineerDaspointer

    if (WorkName === "Concrete_Mix_Design") {
      navigate("/addConcreteMixDesign",{state:{tableName:tableName}});
    } else {
      notification.warning({
        message: "warning",
        description: `${WorkName} Form is under Process`,
        placement: "topRight",
        duration: 3,
        style: {
          marginTop: "50px",
          // textTransform: "capitalize",
        },
      });
    }
  };

  // For get te Engineer list;
  // const getEngineerByProjectAndRoleId = async () => {
  //   let projectId = await localStorage.getItem("projectId");
  //   try {
  //     const response = await axios.get(
  //       `${api_rout}/user/get/by/role/${projectId}/6`
  //     );
  //     let data = response.data.map((item,i) => {
  //       let payload = {
  //         ...item,
  //         fullName: item.firstName + " " + item.lastName,
  //         slNo:i+1
  //       };
  //       return payload;
  //     });

  //     console.log(data);

  //     setEngineerList(data);
  //   } catch (error) {
  //     console.log("Error: " + error);
  //   }
  // };

  const getAllSubModule = async () => {
    try {
      const response = await axios.get(
        `${api_rout}/domain/subcontractor/submodule/get/all`
      );
      console.log(userID);
      const data = response.data.data.filter((item) => item.userId === user.id);
      console.log(data, "by userID");
      // setSubModule(data)
    } catch (error) {
      console.log("Error: " + error);
    }
  };

  // Nasted Data Function

  const createSubModalByModal = async (arr) => {
    const groupedData = [];
    arr.forEach((item) => {
      const { moduleId, module, subModuleId, subModule } = item;

      let existingGroup = groupedData.find(
        (group) => group.moduleId === moduleId
      );

      if (!existingGroup) {
        existingGroup = {
          moduleId,
          module,
          subModules: [],
        };
        groupedData.push(existingGroup);
      }

      let existingSubModule = existingGroup.subModules.find(
        (sub) => sub.subModuleId === subModuleId
      );

      if (!existingSubModule) {
        existingSubModule = {
          subModuleId,
          subModule,
          data: [],
        };
        existingGroup.subModules.push(existingSubModule);
      }

      existingSubModule.data.push(item);
    });

    console.log(groupedData);
    return groupedData;
  };

  const getAllSubModuleBySumContractorUserId = async () => {
    try {
      const response = await axios.get(
        `${api_rout}/domain/subcontractor/submodule/get/flow/by/user/${user.id}`
      );

      const data = response.data.data;
      console.log(data, "by userID");
      setSubModule(data);

      // To make nested Data
      const nestedData = await createSubModalByModal(data);
      setSubModuleNastedData(nestedData);
    } catch (error) {
      console.log("Error: " + error);
    }
  };

  // const getSubContractByUserId = async () => {
  //   // setload(true)
  //   let contract = await getContract();
  //   console.log(contract, "get contract");
  //   if(contract){
  //     let subContractByContractId = await getSubContractByContractId(contract.id);
  //     if(subContractByContractId){

  //       // if it rout from the contract then return state

  //       if(state !== null){
  //         let filterSubcontractByuserId = await subContractByContractId.filter(
  //           (item) => item.subContractorId === state.id
  //         );
  //         console.log(filterSubcontractByuserId, "filter sub contract by user iD");
  //         setSubContract(filterSubcontractByuserId[0]);
  //       }
  //       else{
  //         let filterSubcontractByuserId = await subContractByContractId.filter(
  //           (item) => item.subContractorId === user.id
  //         );
  //         console.log(filterSubcontractByuserId, "filter sub contract by user iD");
  //         setSubContract(filterSubcontractByuserId[0]);

  //       }

  //     }
  //   }

  //   setload(false)
  // };

  // const getAllusersByProjectId = async() =>{
  //   try{
  //     const response = await axios.get(
  //       `${api_rout}/user/get/users/by/project/${projectId}`
  //     );
  //     const data = response.data.filter((user) => user.roleId === 6);
  //     console.log(data);
  //     setTotaluserByproject(data);
  //   }
  //   catch(error){
  //     console.log(error);
  //   }
  // }


  const getRejectedWoekData = async ()=>{
    try{
      const response = await axios.get(`${api_rout}/approval/get/rejected/by/user/${user.id}`);
      setAllRejectList(response.data.data)
      console.log(response.data)
    }
    catch(error){
      console.log(error);
    }
  }

  useEffect(() => {
    // getSubContractByUserId();
    // getAllusersByProjectId();
    // getSubContractIdByUserId();
    // getEngineerByProjectAndRoleId();
    // getAllSubModule();
    getAllSubModuleBySumContractorUserId();
    getAllSubModule();
    getRejectedWoekData();
  }, [user]);


  const Modulecolumn = [
    //   { field: "id", title: "Sl. No", width: 5 },
    //   { field: "fullName", title: "Name", width: 150,
    // render: (params)=>{
    //   return params.firstName+" "+params.lastName
    // }
    // },
    { field: "module", title: "Module", width: 10 },
    { field: "subModule", title: "Sub Module", width: 10 },
    { field: "work", title: "Work", width: 10 },

    //   { field: "", title: "Role", width: 150 ,
    //   render: (params) => {
    //     return (
    //       <>Engineer</>
    //     )}
    // },

    {
      field: "action",
      title: "Actions",
      width: 100,
      // valueGetter: (params) => params.row.id,
      render: (params) => {
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "100px",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                minWidth: "auto",
                marginRight: "5px",
                color: colors.grey[300],
                backgroundColor: "transparent",
              },
            }}
          >
            <div className="grid-actionsWrap">
              {/* <Button
                onClick={() => handleButtonClick(params.id)}
                variant="contained"
              > */}

              {/* {params.work} */}
              <EastIcon
                sx={{ cursor: "pointer" }}
                onClick={() => handleButtonClick(params.work,params.tableName)}
              />
              {/* </Button> */}
              {/* <Button
                // onClick={(e) => onButtonClick(e, params)}
                variant="contained"
              >
                <EditOutlinedIcon />
              </Button>
              <Button>
                <MessageOutlinedIcon />
              </Button> */}
            </div>
          </Box>
        );
      },
      width: 120,
    },
  ];

  return (
    <MainLayout>
      <Box
        sx={{
          p: 3,
          backgroundColor: "#f9f9f9",
          "& h5": {
            fontSize: "16px",
            fontWeight: "600",
          },
          "& .user_list_title": {
            color: colors.grey[300],
            fontSize: "22px",
            fontWeight: 600,
            margin: 0,
          },
          "& .user_list_gridWrap": {
            backgroundColor: colors.grey[800],
          },
          "& .user_list_gridWrap .css-ig9rso-MuiToolbar-root": {
            minHeight: "auto",
            padding: "8px 15px",
          },
          "& .user_list_gridWrap table td": {
            padding: "10px 16px",
          },
          "& .user_list_gridWrap table tfoot td": {
            padding: "0 16px",
          },
          "& .user_list_gridWrap table tfoot td p": {
            margin: 0,
          },
        }}
      >
        <div className="user_cards_wrap container">
          <div className="row">
            <div className="col-md-9 col-12 mb-3">
              <ProjectIfoOnAccordion />
            </div>
            <div className="col-md-3 col-12 mb-3">
              <Paper
                sx={{
                  padding: "10px",
                  alignItems: "center",
                  gap: "10px",
                  border: "1px solid #d9d9d9",
                  boxShadow: "none",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h3"
                    gutterBottom
                    style={{ fontSize: "19px", margin: 0 }}
                  >
                    {+" " + user && user.firstName + " " + user.lastName}
                  </Typography>
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ fontSize: "16px", margin: 0 }}
                  >
                    (
                    {localStorage.getItem("role")
                      ? localStorage.getItem("role").charAt(0).toUpperCase() +
                        localStorage.getItem("role").slice(1)
                      : ""}
                    )
                  </Typography>
                </Box>
                <Box>
                  {/* <p>
                    <strong>Current Login:</strong>{" "}
                    {new Date(Date.now()).toLocaleString()}
                  </p> */}
                  <p style={{ margin: 0 }}>
                    <strong>Last Login:</strong> 8/11/2023, 3:01:32 PM
                  </p>
                </Box>
              </Paper>
            </div>
            
            <div className="col-md-4 col-12 mb-4">
              <MessagesCard />
            </div>
            {/* <div className="col-md-4 col-12 mb-3">
              <Reports />
            </div>
            <div className="col-md-4 col-12 mb-3">
              <Users 
              totalUser={totaluserByproject ? totaluserByproject.length : 0}
              />
            </div>
            <div className="col-md-4 col-12 mb-3">
              <Tickets />
            </div> */}

            {/* <div className="col-md-12 col-12 mb-3">
             <Button
              disabled={roleId === "5" ? false : true}
               variant="contained"
               onClick={()=>navigate("/Exist_invite") }
              >
                Invite Engineer
              </Button>
            <MaterialTable
                title={<h2 className="user_list_title">Engineer List</h2>}
                columns={column}
                data={engineerList}
              />
            </div> */}
            {/* <div className="col-md-12 col-12 mb-3">
            <SubContractorModuleView
            subModuleData = {subModuleNastedData}
            />
             </div> */}
            <div className="col-md-12 col-12 mb-4">
              {/* <SchecudleList subContract={subContractData} /> */}
              <div className="user_list_gridWrap">
                <MaterialTable
                  style={{
                    border: "1px solid #ddd",
                    boxShadow: "none",
                    borderRadius: "4px",
                    overflow: "hidden",
                  }}
                  // title={<h2 className="user_list_title">Modules</h2>}
                  columns={Modulecolumn}
                  data={subModule}
                  options={{
                    toolbar: false, // This removes the title bar
                    search: false, // This disables the search field
                    paging: false, // This disables the footer pagination
                    // pageSize: 10,
                    // pageSizeOptions: [10, 20, 30],
                    rowStyle: {
                      // backgroundColor: '#EEE',
                      height: "10px",
                    },
                    headerStyle: {
                      backgroundColor: "#01579b",
                      color: "#FFF",
                    },
                  }}
                />
              </div>
            </div>
            <div className="col-md-12 col-12 mb-3">
              <SubContractorRejList 
              handleClick={handleGetWorkData}
              allRejectList={allRejectList}
              />
            </div>
          </div>
        </div>
      </Box>


      {/* <ApproveListModal 
      open={openModal} 
      handleClose={handleClose}
      row={rejectData}
      handleEdit={handleEdit}
      /> */}

        <WorkEditModalForSubContractor
         open={openModal} 
         handleClose={handleClose}
         InputFildes={InputFildes}
         rejectData={rejectData}
         setFormData={setFormData}
         formData={formData}
         handleformSubmit={handleSubmitReFiledForms}
        />          

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={load}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </MainLayout>
  );
};

export default SubContractorDash;
