import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./scheduleStep.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AddFieldActivity from "../addFieldActivity/addFieldActivity";
import AddTestData from "../addTestData/addTestData";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MainLayout from "../../layout/MainLayout";

function ScheduleStep() {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [schedule, setSchedule] = useState({});
  const [setpsForTest, setSetpsForTest] = useState({});
  const [testData, setTestData] = useState({});
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { state } = location;
  console.log(state, "location");
  const [reschedule, setReschedule] = useState({
    status: false,
    rescheduleId: "",
  });

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    axios
      .get(
        `https://sig.eastus.cloudapp.azure.com/backend/field/activity/get/by/schedule/${state}`
      )
      .then((res) => {
        console.log(res.data);

        axios
          .get(
            `https://sig.eastus.cloudapp.azure.com/backend/test/get/by/schedule/${state}`
          )
          .then((res) => {
            if (res.data.length > 0) {
              setTestData(res.data[0]);
              console.log(res.data[0]);
            }
          });
      });

    axios
      .get(
        `https://sig.eastus.cloudapp.azure.com/backend/schedule/get/by/${state}`
      )
      .then((res) => {
        const data = res.data[0];
        console.log(data);
        if (res.data[0].rescheduleId != null) {
          setReschedule({
            status: true,
            rescheduleId: res.data[0].rescheduleId,
          });
          const confirmed = window.confirm(
            "this test was reschduled do you want to go to the reschedule test"
          );
          if (confirmed) {
            navigate("/progress", { state: res.data[0].rescheduleId });
            window.location.reload();
          }
        }
        setSchedule(data);
        axios
          .get(
            `https://sig.eastus.cloudapp.azure.com/backend/steps/get/by/${res.data[0].discipline}`
          )
          .then((response) => {
            console.log("Asd");
            console.log(response.data);
            setSetpsForTest(response.data[0]);
          });
      });
    axios
      .get("http://sig.eastus.cloudapp.azure.com:8080/backend/user/allusers")
      .then((response) => {
        setUsers(response.data);
      });
  }, [refresh]);

  const handleStatusChange = (newStatus) => {
    setOpen(true);
    axios
      .patch(
        `https://sig.eastus.cloudapp.azure.com/backend/test/change/status/${testData.id}`,
        { status: newStatus }
      )
      .then((res) => {
        axios
          .patch(
            `https://sig.eastus.cloudapp.azure.com/backend/schedule/status/${schedule.id}`,
            { status: "completed" }
          )
          .then(() => {
            handleRefresh();
            setOpen(false);
          });
      });
  };

  return (
    <MainLayout>
      <div style={{ width: "100%", height: "100vh" }}>
        <div style={{ width: "100%", height: "100%" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="steps-div">
            <div className="step-head">
              <h4>{schedule && schedule.name}</h4>
              <h4>{schedule && schedule.projectName}</h4>
            </div>
            <div className="stp-btns">
              {setpsForTest.fieldActivity === 1 && (
                <button
                  className={step === 0 ? "selected-form" : ""}
                  onClick={() => {
                    setStep(0);
                  }}
                >
                  Field Activity
                </button>
              )}
              {setpsForTest.testData === 1 && (
                <button
                  className={step === 1 ? "selected-form" : ""}
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  Test Data
                </button>
              )}
              {setpsForTest.signAndApprove == 1 && (
                <button
                  className={step === 2 ? "selected-form" : ""}
                  onClick={() => {
                    setStep(2);
                  }}
                >
                  Sign & Approve
                </button>
              )}
            </div>
            <div className="step-form">
              {step === 0 && (
                <div>
                  <AddFieldActivity
                    sid={state}
                    tid={schedule.technician}
                    refresh={refresh}
                    setStep={setStep}
                    reschedule={reschedule}
                  ></AddFieldActivity>
                </div>
              )}
              {step === 1 && (
                <AddTestData
                  schedule={schedule}
                  state={state}
                  users={users}
                  handleRefresh={handleRefresh}
                  setTestData={setTestData}
                  SetpsForTest={setpsForTest}
                  reschedule={reschedule}
                />
              )}
              {step === 2 && (
                <div className="step-div">
                  <h1>Sign and Approve</h1>
                  <form className="one-form">
                    <div>
                      <p>
                        <b>Test data</b>: {testData.data}
                      </p>
                      <p>
                        <b>Test result</b>: {testData.result}
                      </p>
                    </div>
                    <div>
                      {testData.status == "pending" && (
                        <button
                          disabled={
                            testData.approveBy != localStorage.getItem("user")
                          }
                          type="button"
                          onClick={() => handleStatusChange("Approved")}
                        >
                          Approve
                        </button>
                      )}
                      {testData.status == "pending" && (
                        <button
                          disabled={
                            testData.approveBy != localStorage.getItem("user")
                          }
                          type="button"
                          onClick={() => handleStatusChange("Rejected")}
                        >
                          Reject
                        </button>
                      )}
                      {testData.status !== "pending" && (
                        <label>
                          {" "}
                          Schedule {testData.status} by{" "}
                          {testData.firstName + " " + testData.lastName}
                        </label>
                      )}
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default ScheduleStep;
