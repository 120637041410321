import { Box, IconButton, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

const AlertsMenu = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  return (
    <div className="alertsListWrap">
        <ul>
            <li>
                <div className="alertItemWrap" onClick={()=> navigate("/testSteps")}>
                    <div className="alertItemIcon">
                        <CalendarTodayOutlinedIcon />
                    </div>
                    <div className="alertItemDetail">
                        <h4>Project Name</h4>
                        <h5>Test Name</h5>
                        <p>Schedule Date: 10-09-2023 10:30 AM</p>
                    </div>
                </div>
            </li>
            <li>
                <div className="alertItemWrap" onClick={()=> navigate("/testSteps")}>
                    <div className="alertItemIcon">
                        <CalendarTodayOutlinedIcon />
                    </div>
                    <div className="alertItemDetail">
                        <h4>Project Name</h4>
                        <h5>Test Name</h5>
                        <p>Schedule Date: 10-09-2023 10:30 AM</p>
                    </div>
                </div>
            </li>
            <li>
                <div className="alertItemWrap" onClick={()=> navigate("/testSteps")}>
                    <div className="alertItemIcon">
                        <CalendarTodayOutlinedIcon />
                    </div>
                    <div className="alertItemDetail">
                        <h4>Project Name</h4>
                        <h5>Test Name</h5>
                        <p>Schedule Date: 10-09-2023 10:30 AM</p>
                    </div>
                </div>
            </li>
            <li>
                <div className="alertItemWrap" onClick={()=> navigate("/testSteps")}>
                    <div className="alertItemIcon">
                        <CalendarTodayOutlinedIcon />
                    </div>
                    <div className="alertItemDetail">
                        <h4>Project Name</h4>
                        <h5>Test Name</h5>
                        <p>Schedule Date: 10-09-2023 10:30 AM</p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
  );
};

export default AlertsMenu;
