import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, useTheme } from "@mui/material";
import { Button } from "@mui/material";
import { tokens } from "../../theme";
// import { mockProjectsData } from "../../data/mockdata";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import React, { useEffect, useState } from "react";
import axios from "axios";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const Modules_list = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [clickedRow, setClickedRow] = React.useState();
  const onButtonClick = (e, row) => {
    e.stopPropagation();
    setClickedRow(row);
  };

  useEffect(() => {
    fetch("https://sig.eastus.cloudapp.azure.com/backend/module/all")
      .then((data) => data.json())
      .then((data) => setTableData(data));
  }, []);

  console.log(tableData);

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "name", headerName: "Name", width: 120 },
    { field: "description", headerName: "Description", width: 320 },
    { field: "right", headerName: "Right", width: 320 },
    {
      field: "action",
      headerName: "Actions",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                minWidth: "auto",
                marginRight: "5px",
                backgroundColor: colors.primary[500],
              },
            }}
          >
            <div className="grid-actionsWrap">
              <Button
                onClick={(e) => onButtonClick(e, params.row)}
                variant="contained"
              >
                <VisibilityOutlinedIcon />
              </Button>
              <Button
                onClick={(e) => onButtonClick(e, params.row)}
                variant="contained"
              >
                <EditOutlinedIcon />
              </Button>
              <Button
                onClick={(e) => onButtonClick(e, params.row)}
                variant="contained"
              >
                <DeleteOutlineOutlinedIcon />
              </Button>
            </div>
          </Box>
        );
      },
    width: 120,
    },
  ];

  return (
    <Box>
      <Box
        m="40px 0 0 0"
        sx={{
          "& .MuiDataGrid-root": {
            fontSize: "14px",
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-root p": {
            margin: 0,
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none !important",
            backgroundColor: colors.grey[900],
            fontSize: "14px",
          },
          "& .name-column--cell": {
            color: colors.primary[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[900],
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.grey[600],
          },
          "& .MuiDataGrid-footerContainer": {
            borderColor: colors.grey[800],
            backgroundColor: colors.grey[900],
          },
          "& .user_list_title": {
            color: colors.grey[300],
            fontSize: "22px",
            fontWeight: 600,
          },
          "& .user_list_gridWrap": {
            backgroundColor: colors.grey[800],
          },
          "& .user_list_search": {
            backgroundColor: colors.grey[900],
          },
          "& .user_list_search input": {
            color: colors.grey[200],
          },
        }}
      >
        <h2 className="user_list_title">Modules</h2>
        <div className="user_list_gridWrap">
          <div className="user_list_filterWrap">
            <div className="row flex-row-reverse">
              <div className="col-md-6">
                <div className="user_list_filter_rt">
                  <div className="user_list_search">
                    <SearchOutlinedIcon />
                    <input type="text" />
                  </div>
                  <div className="user_list_btn export">
                    Export to CSV <FileDownloadOutlinedIcon />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="user_list_filter_lt">
                  <div className="user_list_btn delete">
                    Delete <DeleteOutlineOutlinedIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DataGrid
            rows={tableData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        </div>
      </Box>
    </Box>
  );
};

export default Modules_list;
