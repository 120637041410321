import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import MaterialTable from "@material-table/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "500px",
  overflow: "auto",
};

export const RejectAndApproveListModal = (props) => {
  const { open, handleClose, Data,column } = props;

  return (
    <>
     <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        max-width="lg"
      >
         <Box
          sx={{
            ...style,
            maxWidth: "840px",
            width: "80%",
            borderRadius: "6px",
            height: "auto"
          }}
        >
            <MaterialTable
                    title=""
                    columns={column}
                    data={Data}
                    options={{
                      toolbar: false,  // This removes the title bar
                      search: false,      // This disables the search field
                      paging: false,      // This disables the footer pagination
                      // pageSize: 10,
                      // pageSizeOptions: [10, 20, 30],
                      rowStyle: {
                        backgroundColor: '#EEE',
                        height:"10 px"
                      },
                      headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF'
                      }
                    }}
                  />

        </Box>
      
      </Modal>
    </>
  )
}
