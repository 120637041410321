import React from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const ProjectMap = ({ latitude, longitude }) => {

  return (
    <GoogleMapReact
      defaultZoom={10}
      defaultCenter={{ lat: latitude, lng: longitude }}
      center={{ lat: latitude, lng: longitude }}
    >
      {/* <AnyReactComponent 
          lat={latitude} 
          lng={longitude} 
          text={'Marker'} 
        /> */}
    </GoogleMapReact>
  );
};

export default ProjectMap