import * as React from "react";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoginImage from "../images/OIU9IR1T.png";
import LoginLogo from "../images/SS.png";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

// material icon for paswords show and off
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import { api_rout } from "../Api-Config/config";

import { FloatButton, notification } from "antd";


function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {/* <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '} */}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

// const handleSubmit=(e)=>{
//     e.preventDefault();
// }

export default function ForgotPass() {

  const {id} = useParams();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // for show password
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // for show password
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const data = new FormData(event.currentTarget);
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });
    const payload ={
      "email" : email
  }
    try {
      const response = await axios.patch(
        `${api_rout}/email/forgot/password`,payload
      );
      console.log(response.data);
      // const user = response.data[0];
      // alert(`Your password is: ${user.password}`);
      notification.success({
        message: "Success",
        description: "Re-set password link sent to your Email successfully",
        placement: "topRight",
        duration: 2,
        style: {
          marginTop: "50px",
          textTransform: "capitalize",
        },
      });
    } catch (error) {
      console.log(error);
      // alert();
      notification.error({
        message: "Error",
        description: "User not found please pass correct email ID and try again",
        placement: "topRight",
        duration: 2,
        style: {
          marginTop: "50px",
          textTransform: "capitalize",
        },
      });
    }
  };

const handleResetPassword = async(event) => {
  event.preventDefault();
  if(password !== confirmPassword){
    // alert("please enter your password and confirm password same");
    notification.error({
      message: "Error",
      description: "please enter your password and confirm password same",
      placement: "topRight",
      duration: 2,
      style: {
        marginTop: "50px",
        textTransform: "capitalize",
        backgroundColor: "#FFF2F0"
      },
    });
    return;
  }
  const payload = {
    "password": password,
    "token": id
}
  try{
    const response = await axios.patch(`${api_rout}/user/change/password`,payload);
    console.log(response.data);
    // alert("password changed successfully");
    notification.success({
      message: "Success",
      description: "password changed successfully",
      placement: "topRight",
      duration: 5,
      style: {
        marginTop: "50px",
        textTransform: "capitalize",
        backgroundColor: "#F6FFED"

      },
    });
    setTimeout(()=>{
      navigate("/");
    },2000)
    
  }
  catch(error){
    console.log(error)
  }
}

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage: `url(${LoginImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "bottom center",
          }}
        />
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ m: 1, pb: 3 }}>
              <img src={`${LoginLogo}`} width="300"></img>
            </Box>
            <Typography component="h1" variant="h5">
              {
                id ? "Reset Your Password" : "Forgot Your Password?"
              }
            </Typography>
            {
              !id &&  <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onSubmit={handleSubmit}
              >
                Submit
              </Button>
              <Grid container justifyContent="center">
                <Grid item>
                  <Link href={"/"} variant="body2">
                    Back to Login
                  </Link>
                </Grid>
              </Grid>

              <Copyright sx={{ mt: 5 }} />
            </Box>
            }
            
           

{/* // This is for password Change */}
            {
              id && <Box
              component="form"
              noValidate
              onSubmit={handleResetPassword}
              sx={{ mt: 1 }}
            >

              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="Password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        {/* <Icon
                        // sx={{color:"black"}}
                        icon={showPassword ?  eyeFill : eyeOffFill} /> */}
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
<TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                id="Password"
                autoComplete="current-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={()=>setShowConfirmPassword((show)=> !show)} edge="end">
                        {/* <Icon
                        // sx={{color:"black"}}
                        icon={showPassword ?  eyeFill : eyeOffFill} /> */}
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onSubmit={handleResetPassword}
              >
                Submit
              </Button>
              <Grid container justifyContent="center">
                <Grid item>
                  <Link href={"./"} variant="body2">
                    Back to Login
                  </Link>
                </Grid>
              </Grid>

              <Copyright sx={{ mt: 5 }} />
            </Box>
            }

            
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
