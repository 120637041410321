import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import { Box, Button, Paper, Typography } from "@mui/material";
import ProjectInfo from "../../components/projects/projectInfo";
import Reports from "../../components/projects/cards/reports";
import ApproveList from "../../components/ApproveListForQCandQA";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { api_rout } from "../../Api-Config/config";
import ApproveListModal from "../../components/ApproveListForQCandQA/ApproveListModal";
import ProjectIfoOnAccordion from "../../components/projects/ProjectIfoOnAccordion";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
export default function QADashBoard() {
  const { user } = useSelector((state) => state.user);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [rows, setRows] = useState();

  // some more data send to the modal
  const [more, setMore] = useState();

  // State for Open Modal
  const [open, setOpen] = useState(false);

  // function for close the modal

  const handleClose = () => {
    setOpen(false);
  };

  const getAllWorkData = async () => {
    try {
      const response = await axios.get(
        `${api_rout}/scheduleactivity/all/approval/${Number(
          localStorage.getItem("projectId")
        )}`
      );
      console.log(response.data);
      const data = response.data.map((item, i) => {
        return { ...item, slNo: i + 1 };
      });

      setData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlgetWorkByFormId = async (id, scheduleId) => {
    try {
      setOpen(true);
      const response = await axios.get(
        `${api_rout}/concmixdesign/get/latest/mix/${id}`
      );
      console.log(response.data);
      // const data = response.data.map((item,i)=> {
      //     return {...item,slNo:i+1}
      // })
      const {
        mixDescription,
        wcRatio,
        water,
        sand,
        sNo8,
        sNo67,
        sNo4,
        s3In,
        flyAsh,
        comments,
      } = response.data;

      setMore({ id:response.data.mixId, scheduleId: scheduleId });

      setRows({
        mixDescription,
        wcRatio,
        water,
        sand,
        sNo8,
        sNo67,
        sNo4,
        s3In,
        flyAsh,
        comments,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleQCSubmit = async (status) => {
    console.log("status: " + status);
    try {
      const payload = {
        id: more.id,
        scheduleId: more.scheduleId,
        status: Number(status),
      };
      const response = await axios.patch(
        `${api_rout}/concmixdesign/edit/qc/status`,payload
      );
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllWorkData();
  }, []);

  useEffect(() => {
    console.log(rows);
  }, [rows, open]);

  const NewColumn = [
    { field: "slNo", title: "Sl No.", width: 5 },
    { field: "formName", title: "Form Name", width: 10 },
    { field: "work", title: "Work", width: 10 },
    {
      field: "action",
      title: "View",
      width: 100,
      // valueGetter: (params) => params.row.id,
      render: (params) => {
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "100px",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                minWidth: "auto",
                marginRight: "5px",
                color: colors.grey[300],
                // backgroundColor: colors.primary[500],
              },
            }}
          >
            <div className="grid-actionsWrap">
              <Button
                onClick={
                  () => handlgetWorkByFormId(params.formId, params.scheduleId)
                  // navigate("/QCDash/list", {
                  //   state: {
                  //     id: params.formId,
                  //   },
                  // })
                  // {
                  //     setOpen(true);
                  //     const {mixDescription,wcRatio,water,sand,sNo8,sNo67,sNo4,s3In,flyAsh,comments} = params

                  //     setRows({mixDescription,wcRatio,water,sand,sNo8,sNo67,sNo4,s3In,flyAsh,comments})
                  // }
                }
                variant="contained"
              >
                <VisibilityOutlinedIcon />
              </Button>
              {/* <Button
                    onClick={(e) => onButtonClick(e, params)}
                    variant="contained"
                  >
                    <EditOutlinedIcon />
                  </Button> */}
            </div>
          </Box>
        );
      },
      width: 120,
    },
    {
      field: "action",
      title: "View all test",
      width: 10,
      // valueGetter: (params) => params.row.id,
      render: (params) => {
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "100px",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                minWidth: "auto",
                marginRight: "5px",
                color: colors.grey[300],
                // backgroundColor: colors.primary[500],
              },
            }}
          >
            <div className="grid-actionsWrap">
              <Button
                onClick={() =>
                  navigate("/QCDash/list", {
                    state: {
                      id: params.formId,
                    },
                  })
                }
                variant="contained"
              >
                <EastIcon />
              </Button>
            </div>
          </Box>
        );
      },
      width: 120,
    },
  ];
  return (
    <MainLayout>
      <Box
        sx={{
          p: 3,
          "& h5": {
            fontSize: "16px",
            fontWeight: "600",
          },
        }}
      >
        <div className="user_cards_wrap">
          <div className="row  mb-3">
            <div className="col-md-9 col-12 mb-3">
              <ProjectIfoOnAccordion />
            </div>
            <div className="col-md-3 col-12 mb-3">
            <Paper  sx={{ padding: "10px",display:"flex",alignItems:"center",gap:"10px" }}>
                <Typography variant="h3" gutterBottom>
                 {+" " + user && user.firstName + " " + user.lastName}
                </Typography>
                <Typography variant="h4" gutterBottom>
                ({localStorage.getItem("role") ? localStorage.getItem("role").charAt(0).toUpperCase()+ localStorage.getItem("role").slice(1): ""})
                </Typography>
              </Paper>
            </div>
            {/* <ProjectInfo /> */}
          </div>
          <div className="col-md-3 col-12 mb-3">
            <Reports />
          </div>
          <div className="col-md-12 col-12 mb-3">
            <ApproveList title="Approval List" columns={NewColumn} data={data} />
          </div>
        </div>
      </Box>
      <ApproveListModal
        open={open}
        handleClose={handleClose}
        row={rows}
        moreData={more}
        handleSubmit={handleQCSubmit}
      />
    </MainLayout>
  );
}
