import { GET_USER_FAILURE, GET_USER_REQUEST, GET_USER_SUCCESS } from "./user.actionType";

const initState = {
    user : [],
    isLoading : false,
    isError : false

}

export const user_reducer = (state=initState,{type,payload})=>{

    switch (type){
        case GET_USER_REQUEST :
            return {
                ...state,
                isLoading : true,
                isError : false
            }
        case GET_USER_SUCCESS : 
            return {
                ...state,
                isLoading : false,
                isError : false,
                user : payload
            }
        case GET_USER_FAILURE :
            return {
                ...state,
                isLoading : false,
                isError : true
            }
            default :
            return state
    }
}