import React, { useEffect } from "react";
import {
  TextField,
  Button,
  FormControlLabel,
  Box,
  Autocomplete,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useFormik } from "formik";
import MainLayout from "../../layout/MainLayout";
import { useState } from "react";
import apiService from "../../data/apiService";
import { openDB } from "idb";
import axios from "axios";
import { api_rout } from "../../Api-Config/config";
import { useParams } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const trialFinallist = [
  {
    label: "Trial ",
    value: "0",
  },
  {
    label: "Final",
    value: "1",
  },
];

export default function View_concreteMix() {
  const { id } = useParams();
  console.log(id);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [disabledInput, setDisabledInput] = useState(true);

  const [concreteMixid, setConcreteMixId] = useState("");
  const [trialFinal, setTrialFinal] = useState("");
  const [mixId, setMixId] = useState("");
  const [mixDescription, setMixDescription] = useState("");
  const [wcRatio, setWcRatio] = useState("");
  const [water, setWater] = useState("");
  const [sand, setSand] = useState("");
  const [sNo8, setSNo8] = useState("");
  const [sNo67, setSNo67] = useState("");
  const [sNo4, setSNo4] = useState("");
  const [s3ln, setS3ln] = useState("");
  const [flyAsh, setFlyAsh] = useState("");
  const [comments, setComments] = useState("");
  const [dateAppended, setDateAppended] = useState("");
  const [dateModified, setDateModified] = useState("");
  const [author, setAuthor] = useState("");
  const [qcVerifier, setQcVerifier] = useState("");
  const [qcDate, setQcDate] = useState("");
  const [qaVerifier, setQaVerifier] = useState("");
  const [qaDate, setQaDate] = useState("");


  //Mix Id List

  const [mixIdList, setMixIdList] = useState([]);

  // get Concrete Mix Design By Id

  const getConcreteBatchById = async () => {
    try {
      const response = await axios.get(
        `${api_rout}/concmixdesign/get/by/id/${id}`
      );
      console.log(response.data);
      const data = response.data[0];
      console.log(data);
      setConcreteMixId(data.id);
      setTrialFinal(data.trialFinal);
      setMixId(data.mixId);
      setMixDescription(data.mixDescription);
      setWcRatio(data.wcRatio);
      setWater(data.water);
      setSand(data.sand);
      setSNo8(data.sNo8);
      setSNo67(data.sNo67);
      setSNo4(data.sNo4);
      setS3ln(data.s3ln);
      setFlyAsh(data.flyAsh);
      setComments(data.comments);
      setDateAppended(data.dateAppended.slice(0, 10));
      setDateModified(data.dateModified.slice(0, 10));
      setAuthor(data.author);
      setQcVerifier(data.qcVerifier);
      setQcDate(data.qcDate.slice(0, 10));
      setQaVerifier(data.qaVerifier);
      setQaDate(data.qaDate);
    } catch (error) {
      console.log(error);
    }
  };

  const getConcreteMixId = async() => {
    try{
      const response = await axios.get(`${api_rout}/concbatchtickets/get/all`);
      let data = response.data.map((item)=> item.mixId)
      console.log(data);
      setMixIdList(data)
    }
    catch(error){
      console.log(error);
    }
  }

  useEffect(() => {
    getConcreteBatchById();
    getConcreteMixId();
  }, []);

  // For Enable and Disable Editable

  const handleEdit = () => {
    console.log(!disabledInput);
    setDisabledInput((disabledInput) => !disabledInput);
  };
  // Handle change for all inputs
  const handleChangeInputs = (sateState) => (e) => {
    console.log("click");
    console.log(e.target.value);
    sateState(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("id", id);
    formData.append("trialFinal", trialFinal);
    formData.append("mixId", mixId);
    formData.append("mixDescription", mixDescription);
    formData.append("wcRatio", wcRatio);
    formData.append("water", water);
    formData.append("sand", sand);
    formData.append("sNo8", sNo8);
    formData.append("sNo67", sNo67);
    formData.append("sNo4", sNo4);
    formData.append("s3ln", s3ln);
    formData.append("flyAsh", flyAsh);
    formData.append("comments", comments);
    formData.append("dateAppended", dateAppended);
    formData.append("dateModified", dateModified);
    formData.append("author", author);
    formData.append("qcVerifier", qcVerifier);
    formData.append("qcDate", qcDate);
    formData.append("qaVerifier", qaVerifier);
    formData.append("qaDate", qaDate);

    console.log(formData);
    const formDataObj = {};
    formData.forEach((value, key) => (formDataObj[key] = value));
    console.log(formDataObj);
    try {
      // Make API request to submit the form data
      const response = await axios.put(
        `${api_rout}/concmixdesign/edit/${id}`,
        formData
      );
      console.log(response);
    } catch (error) {
      console.log("offline");
      console.error("Error submitting form:", error);
    }
  };

  return (
    <MainLayout>
      <Box
        sx={{
          marginTop: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
          "& #images": {
            paddingLeft: "120px",
          },
          "& #video": {
            paddingLeft: "120px",
          },
        }}
      >
        <div className="pageFormWrap">
          <Typography component="h1" variant="h3">
            Concrete Mix Design
          </Typography>
          {
disabledInput &&  <Button onClick={handleEdit} variant="contained" color="primary">
            <EditOutlinedIcon />
          </Button>
          }
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  disabled={disabledInput}
                  id="combo-box-demo"
                  options={trialFinallist}
                  sx={{ width: 300 }}
                  value={trialFinal === 0 ? "Trial" : "Final"}
                  onChange={(newValue, e) => {
                    console.log("value", newValue, e);
                    if (!e) {
                      setTrialFinal("");
                    } else {
                      setTrialFinal(e.value);
                    }
                  }}
                  renderInput={(params) => {
                    // console.log(params,"selected")
                    return (
                      <TextField
                        {...params}
                        label="Trail/Final"
                        fullWidth
                        margin="normal"
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                {/* <TextField
                  disabled={disabledInput}
                  label="Mix ID"
                  name="mixId"
                  id="mixId"
                  value={mixId}
                  onChange={handleChangeInputs(setMixId)}
                  // onChange={handleMixIdChange}
                  fullWidth
                  margin="normal"
                /> */}
                   <Autocomplete
                  disabled={disabledInput}
                  id="combo-box-demo"
                  options={mixIdList}
                  sx={{ width: 300 }}
                  value={mixId}
                  onChange={(newValue, e) => {
                    console.log("value", newValue, "value"+e);
                    if (!e) {
                      setMixId("");
                    } else {
                      setMixId(e);
                    }
                  }}
                  renderInput={(params) => {
                    // console.log(params,"selected")
                    return (
                      <TextField
                        {...params}
                        label="Mix ID"
                        fullWidth
                        margin="normal"
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disabledInput}
                  label="Mix Description"
                  name="mixDescription"
                  id="mixDescription"
                  value={mixDescription}
                  onChange={handleChangeInputs(setMixDescription)}
                  // onChange={handleMixDescriptionChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disabledInput}
                  label="wcRatio"
                  name="wcRatio"
                  id="wcRatio"
                  value={wcRatio}
                  onChange={handleChangeInputs(setWcRatio)}
                  // onChange={handleWcRatioChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disabledInput}
                  label="Water"
                  name="water"
                  id="water"
                  value={water}
                  onChange={handleChangeInputs(setWater)}
                  // onChange={handleWaterChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disabledInput}
                  label="Sand"
                  name="sand"
                  id="sand"
                  value={sand}
                  onChange={handleChangeInputs(setSand)}
                  // onChange={handleSandChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disabledInput}
                  label="sNo8"
                  name="sNo8"
                  id="sNo8"
                  value={sNo8}
                  onChange={handleChangeInputs(setSNo8)}
                  // onChange={handleSNo8Change}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disabledInput}
                  label="sNo67"
                  name="sNo67"
                  id="sNo67"
                  value={sNo67}
                  onChange={handleChangeInputs(setSNo67)}
                  // onChange={handleSNo67Change}
                  fullWidth
                  margin="normal"
                  multiline
                  maxRows={3}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disabledInput}
                  label="sNo4"
                  name="sNo4"
                  id="sNo4"
                  value={sNo4}
                  onChange={handleChangeInputs(setSNo4)}
                  // onChange={handleSNo4Change}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disabledInput}
                  label="s3ln"
                  name="s3ln"
                  id="s3ln"
                  value={s3ln}
                  onChange={handleChangeInputs(setS3ln)}
                  // onChange={handleS3lnChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disabledInput}
                  label="flyAsh"
                  name="flyAsh"
                  id="flyAsh"
                  value={flyAsh}
                  onChange={handleChangeInputs(setFlyAsh)}
                  // onChange={handleFlyAshChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disabledInput}
                  label="Comments"
                  name="comments"
                  id="comments"
                  value={comments}
                  onChange={handleChangeInputs(setComments)}
                  // onChange={handleCommentsChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disabledInput}
                  helperText="Date Appended"
                  type="date"
                  name="dateAppended"
                  id="dateAppended"
                  value={dateAppended}
                  onChange={handleChangeInputs(setDateAppended)}
                  // onChange={handleDateAppendedChange}
                  fullWidth
                  margin="normal"
                  defaultValue="2017-05-24"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disabledInput}
                  helperText="Date Modified"
                  type="date"
                  name="dateModified"
                  id="dateModified"
                  value={dateModified}
                  onChange={handleChangeInputs(setDateModified)}
                  // onChange={handleDateModifiedChange}
                  fullWidth
                  margin="normal"
                  defaultValue="2017-05-24"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disabledInput}
                  label="Author"
                  name="author"
                  id="author"
                  value={author}
                  onChange={handleChangeInputs(setAuthor)}
                  // onChange={handleAuthorChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disabledInput}
                  label="qcVerifier"
                  name="qcVerifier"
                  id="qcVerifier"
                  value={qcVerifier}
                  onChange={handleChangeInputs(setQcVerifier)}
                  // onChange={handleQcVerifierChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disabledInput}
                  helperText="qcDate"
                  type="date"
                  name="qcDate"
                  id="qcDate"
                  value={qcDate}
                  onChange={handleChangeInputs(setQcDate)}
                  // onChange={handleQcDateChange}
                  fullWidth
                  margin="normal"
                  defaultValue="2017-05-24"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disabledInput}
                  label="qaVerifier"
                  name="qaVerifier"
                  id="qaVerifier"
                  value={qaVerifier}
                  onChange={handleChangeInputs(setQaVerifier)}
                  // onChange={handleQaVerifierChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disabledInput}
                  helperText="qcDate"
                  type="date"
                  name="qcDate"
                  id="qcDate"
                  value={qcDate}
                  onChange={handleChangeInputs(setQaDate)}
                  // onChange={handleQaDateChange}
                  fullWidth
                  margin="normal"
                  defaultValue="2021-05-24"
                />
              </Grid>
              <Grid item xs={12}>
              {!disabledInput && (
                <>
                
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                  <Button onClick={handleEdit} type="submit" variant="contained" color="primary">
                    Cancel
                  </Button>
                </>
                )}
              </Grid>
            </Grid>
          </form>
        </div>
      </Box>
    </MainLayout>
  );
}
