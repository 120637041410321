import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, useTheme } from "@mui/material";
import { Button } from "@mui/material";
import { tokens } from "../../theme";
// // import { mockProjectsData } from "../../data/mockdata";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
// import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import React, { useEffect, useState } from "react";
// import axios from "axios";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
// import { useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";
import "./allSchedule.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RestoreIcon from "@mui/icons-material/Restore";
import axios from "axios";

export default function View_schedule({ scheduleData }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const [rescheduleData, setRescheduleData] = useState({});
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    axios
      .get("https://sig.eastus.cloudapp.azure.com/backend/schedule/get/all")
      .then((res) => {
        const { data } = res;
        data.sort((a, b) => {
          if (a.status === "pending" && b.status !== "pending") {
            return -1; // a comes before b
          } else if (a.status !== "pending" && b.status === "pending") {
            return 1; // b comes before a
          } else {
            return 0; // order remains unchanged
          }
        });
        setRows(data);
        console.log(res.data);
      });
  }, [open]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [reDate, setReDate] = useState();
  const handleOpen = (data) => {
    setOpen(true);
    setRescheduleData(data);
    console.log(data);
  };
  const handleClose = () => setOpen(false);

  const onRescheduleSubmit = () => {
    console.log(rescheduleData);
    console.log(reDate);
    axios
      .post(
        "https://sig.eastus.cloudapp.azure.com/backend/schedule/reschedule",
        {
          data: rescheduleData,
          newDate: reDate,
        }
      )
      .then((res) => {
        axios
          .patch(
            `https://sig.eastus.cloudapp.azure.com/backend/schedule/status/${rescheduleData.id}`,
            { status: "rescheduled" }
          )
          .then(() => {
            handleClose();
          });
      });
  };

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "name", headerName: "Name", width: 120 },
    { field: "projectName", headerName: "Project Name" },
    { field: "firstName", headerName: "Created By", width: 120 },
    {
      field: "scheduleDate",
      headerName: "Schedule Date",
      width: 200,
      renderCell: (params, row) => {
        return params.row.scheduleDate.slice(0, 10);
      },
    },
    {
      field: "Technician",
      headerName: "Technician",
      width: 120,
      renderCell: (params, row) => {
        return params.row.firstName + " " + params.row.lastName;
      },
    },
    { field: "status", headerName: "Status", width: 120 },
    {
      field: "action",
      headerName: "Actions",
      // valueGetter: (params) => params.row.id,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                minWidth: "auto",
                marginRight: "5px",
                color: colors.grey[300],
                // backgroundColor: colors.primary[500],
              },
            }}
          >
            <div className="grid-actionsWrap">
              <Button
                onClick={() => handleOpen(params.row)}
                variant="contained"
              >
                <RestoreIcon />
              </Button>
              <Button
                onClick={() => navigate("/progress", { state: params.row.id })}
                variant="contained"
              >
                <MoreHorizIcon />
              </Button>

              {/* <Button
                    // onClick={(e) => onButtonClick(e, params.row)}
                    variant="contained"
                  >
                    <DeleteOutlineOutlinedIcon />
                  </Button> */}
            </div>
          </Box>
        );
      },
      width: 120,
    },
  ];
  return (
    <div>
      <DataGrid
        rows={scheduleData}
        className="withdata"
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />

      <div style={{ width: "100%", height: "100%" }}>
        <div className="schedule-table">
          <div>
            {/* <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 430 }}>
                <Table aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>SL No.</TableCell>
                      <TableCell align="center">Test</TableCell>
                      <TableCell align="center">Project</TableCell>
                      <TableCell align="center">Schedule Date</TableCell>
                      <TableCell align="center">Technician</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Options</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" align="center" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row.projectName}</TableCell>
                        <TableCell align="center">
                          {row.scheduleDate.slice(0, 10)}
                        </TableCell>
                        <TableCell align="center">
                          {row.firstName + " " + row.lastName}
                        </TableCell>
                        <TableCell align="center">{row.status}</TableCell>
                        <TableCell align="center">
                          {row.rescheduleId === null && (
                            <button onClick={() => handleOpen(row)}>
                              <RestoreIcon />
                            </button>
                          )}
                          <button
                            onClick={() =>
                              navigate("/progress", { state: row.id })
                            }
                          >
                            <MoreHorizIcon />
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper> */}
          </div>
          <button onClick={() => navigate("/create")}>Create schedule</button>
        </div>
      </div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <h3>Select the re-scheduled date</h3>
          <input
            type="date"
            name="reDate"
            min={new Date().toISOString().split("T")[0]}
            onChange={(event) => setReDate(event.target.value)}
            value={reDate}
          />
          <br />
          <button type="button" onClick={onRescheduleSubmit}>
            submit
          </button>
        </Box>
      </Modal>
    </div>
  );
}
