import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Card,
  CircularProgress,
  Grid,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import MainLayout from "../../layout/MainLayout";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useLocation, useParams } from "react-router-dom";
import AddConcreteMix from "../../components/records/concreteMix";
import Mix_List from "../../components/projects/Mix_List";
import MaterialTable from "@material-table/core";
import axios from "axios";
import { api_rout } from "../../Api-Config/config";
import { notification } from "antd";
import { useSelector } from "react-redux";
import { ExcelDataUpload } from "../../components/ExcelDataUpload";
import { useRef } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useNavigate } from "react-router-dom";

export default function AddConcreteMixDesign() {
  const { user, isLoading } = useSelector((state) => state.user);

  const fileRef = useRef();

  const [uploadFile, setUploadFile] = useState();
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState();
  const navigate = useNavigate();

  // const [rejectData,setRejectData] = useState();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { id } = useParams();
  // console.log(id, "+++++++++++");

  const { state } = useLocation();

  console.log(id, "++++++++");

  // const mixScheduleID = id;

  // Submit Excel file data on Loop

  const handleSubmitExcelData = async (
    trialFinal,
    flyAsh,
    comments,
    mixDescription,
    mixId,
    s3In,
    sNo4,
    sNo8,
    sNo67,
    sand,
    water,
    wcRatio
  ) => {
    try {
      // Make API request to submit the form data
      const formData = new FormData();
      formData.append("trialFinal", trialFinal);
      formData.append("mixId", mixId);
      formData.append("mixDescription", mixDescription);
      formData.append("wcRatio", wcRatio);
      formData.append("water", water);
      formData.append("sand", sand);
      formData.append("sNo8", sNo8);
      formData.append("sNo67", sNo67);
      formData.append("sNo4", sNo4);
      formData.append("s3In", s3In);
      formData.append("flyAsh", flyAsh);
      formData.append("comments", comments);
      formData.append("projectId", localStorage.getItem("projectId"));

      // formData.append("dateAppended", Date.now());
      // formData.append("dateModified", Date.now());
      // formData.append("author", `${user.firstName} ${user.lastName}`);
      // formData.append("qcVerifier", "Hakuna");
      // formData.append("qcDate", qcDate);
      // formData.append("qaVerifier", "Matata");
      // formData.append("qaDate", qaDate);
      // formData.append("scheduleId", id);
      formData.append("createdBy", user.id);

      const response = await axios.post(
        `${api_rout}/concmixdesign/create`,
        formData
      );
      console.log(response);
    } catch (error) {
      console.log("offline");
      console.error("Error submitting form:", error);
    }
  };

  const handleSubmitExcelDataOnLoop = async () => {

    if(uploadFile.length === 0){
      notification.error({
        message: "Error ",
        description: "Please Provide Data on CSV file",
        placement: "topRight",
        duration: 2,
        style: {
          marginTop: "50px",
          // textTransform: "capitalize",
        },
      });
      return;
    }
    // const payload = {author,flyAsh,comments,mixDescription,mixId,s3ln,sNo4,sNo8,sNo67,sand,water,wcRatio};
    setLoading(true);
    //     [
    //     "trialFinal",
    //     "mixId",
    //     "mixDescription",
    //     "wcRatio",
    //     "water",
    //     "sand",
    //     "sNo8",
    //     "sNo67",
    //     "sNo4",
    //     "s3In",
    //     "flyAsh",
    //     "comments"
    // ]
var getTrilandFinalError = true;
    for (let i = 0; i < uploadFile.length; i++) {
      if(Number(uploadFile[i].trialFinal) === 0 || Number(uploadFile[i].trialFinal) === 1 ){
        console.log(uploadFile[i].trialFinal)
        
        continue; 
      }
      else{
        getTrilandFinalError= false
      }
    }

    console.log(getTrilandFinalError,"in out loop")
    if(!getTrilandFinalError){
      setLoading(false);
      notification.error({
        message: "Invalid Values: ",
        description: "Please enter either 0 or 1 for Trial/Final ",
        placement: "topRight",
        duration: 2,
        style: {
          marginTop: "50px",
          // textTransform: "capitalize",
        },
      });
      return;
    }

    for (let i = 0; i < uploadFile.length; i++) {
      const {
        trialFinal,
        flyAsh,
        comments,
        mixDescription,
        mixId,
        s3In,
        sNo4,
        sNo8,
        sNo67,
        sand,
        water,
        wcRatio,
      } = uploadFile[i];
      // console.log(payload);
      await handleSubmitExcelData(
        trialFinal,
        flyAsh,
        comments,
        mixDescription,
        mixId,
        s3In,
        sNo4,
        sNo8,
        sNo67,
        sand,
        water,
        wcRatio
      );
    }
    setUploadFile();
    setLoading(false);
    notification.success({
      message: "Success",
      description: "upload Concrete Mix Design successfully",
      placement: "topRight",
      duration: 2,
      style: {
        marginTop: "50px",
        textTransform: "capitalize",
      },
    });


  };

  // Upload File Import Data From Excel file Data

  const handaleUploadFile = async (file) => {
    console.log("Uploading file", file);
    const update = uploadFile.map((obj) =>
      obj.id === rowData.id ? { ...obj, file: file } : obj
    );
    console.log("update Excel data", update);
    setUploadFile(update);
  };

  const CSMcolumn = [
    // { field: "slNO", title: "Sl. No" ,width: 5},
    {
      field: "author",
      title: "Author",
      width: 20,
      render: (params) => {
        return user.firstName;
      },
    },
    { field: "trialFinal", title: "Trial/Final", width: 10 },
    // { field: "version", title: "Version",width: 10 },
    {
      field: "dateAppended",
      title: "Date Appended",
      width: 10,
      render: (params) => {
        // return params.dateAppended.slice(0,10)
        return Date.now();
      },
    },
    { field: "mixDescription", title: "Mix Description", width: 10 },
    // { field: "mixId", title: "Mix ID",width: 10 },

    { field: "wcRatio", title: "Water/Cement Ratio", width: 10 },
    { field: "sand", title: "Sand", width: 10 },
    { field: "sNo8", title: "sNo8", width: 10 },
    { field: "sNo67", title: "sNo67", width: 10 },
    { field: "sNo4", title: "sNo4", width: 10 },
    { field: "s3In", title: "s3In", width: 10 },
    { field: "flyAsh", title: "flyAsh", width: 10 },
    { field: "comments", title: "Comments", width: 10 },

    {
      field: "FileUpload",
      title: "Upload File",
      width: 15,
      // valueGetter: (params) => params.row.id,
      render: (params) => {
        // console.log(params);
        return (
          <Box
            sx={{
              "& .grid-actionsWrap": {
                display: "flex",
                justifyContent: "space-between",
                width: "250px",
              },
              "& .grid-actionsWrap Button": {
                fontSize: "13px",
                padding: "4px",
                // minWidth: "auto",
                marginRight: "5px",
                color: colors.grey[300],
                width: "250px",
                // backgroundColor: colors.primary[500],
              },
            }}
          >
            <input
              hidden
              ref={fileRef}
              type="file"
              // accept=".xlsx"
              onChange={(event) => {
                handaleUploadFile(event.target.files[0]);
                //   setUploadImgName(event.target.files[0].name)
              }}
            />
            <CloudUploadOutlinedIcon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                fileRef.current.click();
                setRowData(params);
              }}
            />
            {params.file ? params.file.name : ""}

            {/* <Button
              sx={{
                backgroundColor: color,
                color: "#fff",
                "&:hover": { backgroundColor: color },
              }}
              endIcon={<CloudUploadIcon />}
              variant="contained"
              onClick={() => {
                fileRef.current.click();
              }}
            >
              {"Upload File"}
            </Button> */}
            {/* <div className="grid-actionsWrap">
//           <Button
//             onClick={() => handleButtonClick(params.projectNo)}
//             variant="contained"
//           >
//             <VisibilityOutlinedIcon />
//           </Button>
//           <Button
//             onClick={(e) => onButtonClick(e, params)}
//             variant="contained"
//           >
//             <EditOutlinedIcon />
//           </Button>
//         </div> */}
          </Box>
        );
      },
      width: 120,
    },
  ];

  // const getRejectWorkAllDetails = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${api_rout}/approval/get/enrty/details/${state.tableNameCase}/${state.rejectedUUID}`
  //     );
  //     console.log(response.data.data);
  //     const {
  //       trialFinal, mixId, mixDescription, wcRatio, water, sand,sNo8, sNo67, sNo4, s3In,flyAsh,comments,
  //     } = response.data.data[0];
  //     setRejectData({
  //       trialFinal, mixId, mixDescription, wcRatio, water, sand,sNo8, sNo67, sNo4, s3In,flyAsh,comments,
  //     })
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // useEffect(() => {
  //   if (state) {
  //     getRejectWorkAllDetails();
  //   }
  // }, []);

  return (
    <Box>
      <MainLayout>
        <Box
          sx={{
            p: 3,
            backgroundColor: "#f9f9f9",
          }}
        >
          <div className="container">
            <h2>Concrete Mix Design</h2>
            <Box
              sx={{
                border: "1px solid #eee",
                backgroundColor: "#fff",
                borderRadius: "4px",
              }}
            >
              <AddConcreteMix
                // rejectData={state && state.data}
                state={state && state}
                tableName={id}
                setUploadFile={setUploadFile}
              />
            </Box>
            <Box>
              {uploadFile && (
                <Card
                  style={{
                    border: "1px solid #eee",
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                    margin: "40px 0 0",
                    boxShadow: "none",
                  }}
                >
                  <MaterialTable
                    title=""
                    columns={CSMcolumn}
                    data={uploadFile}
                    options={{
                      toolbar: false, // This removes the title bar
                      search: false, // This disables the search field
                      paging: false, // This disables the footer pagination
                      // pageSize: 10,
                      // pageSizeOptions: [10, 20, 30],
                      rowStyle: {
                        backgroundColor: "#EEE",
                        height: "10 px",
                      },
                      headerStyle: {
                        backgroundColor: "#01579b",
                        color: "#FFF",
                      },
                    }}
                  />
                  <Grid item xs={12} sm={4}>
                    <Box
                      sx={{
                        padding: "15px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        className="me-2"
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => 
                          setUploadFile()
                          // navigate(-1)
                        }
                      >Cancel</Button>
                      <Button
                        sx={{ width: "auto", display: "block" }}
                        variant="contained"
                        onClick={handleSubmitExcelDataOnLoop}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                </Card>
              )}
            </Box>

            {/* <Mix_List /> */}
          </div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      </MainLayout>
    </Box>
  );
}
