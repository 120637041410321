import { Box, Typography, useTheme } from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { api_rout } from "../../Api-Config/config";

const DataManagerCard = () =>{
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [roleData, setRoleData] = useState([]);
  const [approveData, setApproveData] = useState([]);
  const [userFirstData, setUserFirstData] = useState('');
  const [userLastData, setUserLastData] = useState('');
  const [userId, setUserId] = useState(null);
  const [pendingCount, setPendingCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${api_rout}/approval/get/all`)
    .then(data => data.json())
    .then(data => {
        setApproveData(data.data);
        console.log(data.data);
    })
    .catch(error => console.error(error));
  }, [])

  useEffect(() => {
    fetch(`${api_rout}/assignment/get/all`)
      .then(data => data.json())
      .then(data => {
        setRoleData(data);
        const rowWithRole = data.find(row => row.roleId === 7);
        if (rowWithRole) {
          setUserId(rowWithRole.userId);
        } else {
          setUserId(null);
        }
      })
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    if (userId) {
      fetch(`${api_rout}/user/find/${userId}`)
        .then(data => data.json())
        .then(data => {
          setUserFirstData(data.firstName);
          setUserLastData(data.lastName);
        })
        .catch(error => console.error(error));
    }
  }, [userId]);

  useEffect(() => {
    const pendingCount = approveData.filter(row => row.status === 'pending').length;
    const completedCount = approveData.length;
    console.log(pendingCount + completedCount, "All counts");
    setPendingCount(pendingCount);
    setCompletedCount(completedCount);
  }, [approveData]);

  return (
    <Box
      sx={{
        p: 2,
        border: "1px solid #ddd",
        borderRadius: "4px",
        backgroundColor: "#fff",
        "& .user_card": {
          fontSize: "14px",
          color: colors.grey[300],
          padding: 0
        },
        "& .user_card_btn": {
          borderColor: colors.grey[500],
          color: colors.grey[300],
        },
        "& .user_card_btn:hover": {
          borderColor: "#3d96f7",
          color: colors.grey[900],
        },
        "& .user_card_rt .icon_wrap": {
          color: colors.grey[400],
        },
        "& .user_card_rt .icon_wrap:hover": {
          color: colors.grey[300],
        },
      }}
    >
      <div className="user_card">
        <div className="row">
          <div className="col-8">
            <div className="icon_wrap">
              <h3>
                <PersonOutlineOutlinedIcon /> Data Manager
              </h3>
            </div>
          </div>

          {userId ? (
          <div className="col-4">
            <button className="btn btn-primary float-end" disabled>Edit</button>
          </div>
          ):(
            <div></div>
          )}

        {userId ? (
            <div className="row">
            <div className="col-12">
                <div className="user_card_lt">
                <div className="user_card_count d-flex align-items-baseline mb-2">
                    <h4 className="me-2 mb-0">{userFirstData + " " + userLastData}</h4>
                    <h3 style={{ lineHeight: "20px"}}></h3>
                </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                <div className="col-6">
                    <div className="user_card_lt">
                    <div className="user_card_count d-flex" >
                        <h4 style={{ fontSize: "18px", color: "#999", marginRight: "10px"}}>Total</h4>
                        <h3 style={{ fontSize: "18px", color: "#666"}}>{completedCount}</h3>
                    </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="user_card_lt">
                    <div className="user_card_count d-flex">
                        <h4 style={{ fontSize: "18px", color: "#999", marginRight: "10px"}}>Pending</h4>
                        <h3 style={{ fontSize: "18px", color: "#666"}}>{pendingCount}</h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
          </div>
        ): (
            <button style={{ border: "none", backgroundColor: "#0D6EFD", color: "white", width: "180px"}} className="btn btn-primery" onClick={() => navigate("/Exist_invite")}>Invite Data Manager</button>
        )}
          

        </div>
      </div>
    </Box>
  )
}

export default DataManagerCard;