import { Box } from "@mui/material";
import { useMode, tokens } from "../theme";
import MainLayout from "../layout/MainLayout";
import Project_card from "../components/projects/projects_card";
import Schedule_card from "../components/schedules/schedule_card";
import Device_card from "../components/devices/devices_card";
import Projects_list from "../components/projects/projects_list";
import { useDispatch, useSelector } from "react-redux";

const Projects = () =>{
    const [theme, colorMode] = useMode();
    const colors = tokens(theme.palette.mode);
    const { user, isLoading } = useSelector((state) => state.user);

    return(
        <MainLayout>
            <Box m="20px">
              {
                user.designation === "Employee" ? <Projects_list /> :  <Box>
                <h1 className="page_title">Projects Management</h1>
                <div className="user_cards_wrap">
                  <div className="row">
                    <div className="col-md-4 col-12 mb-3">
                      <Project_card />
                    </div>
                    <div className="col-md-4 col-12 mb-3">
                      <Schedule_card />
                    </div>
                    <div className="col-md-4 col-12 mb-3">
                      <Device_card />
                    </div>
                  </div>
                </div>
      
                <Projects_list />
              </Box>

              }
       
      </Box>
        </MainLayout>
    )
}

export default Projects