import React, { useEffect } from "react";
import { useState } from "react";
import {
  TextField,
  Button,
  FormControlLabel,
  Box,
  Autocomplete,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useFormik } from "formik";
import * as yup from "yup";
import MainLayout from "../../layout/MainLayout";
import axios from "axios";
import { api_rout } from "../../Api-Config/config";

const Add_user = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

//   const validationSchema = yup.object({
//     firstName: yup.string().required("First Name is required"),
//     lastName: yup.string().required("Last Name is required"),
//     password: yup.string().required("Password is required"),
//     designation: yup.string().required("Designation is required"),
//     email: yup
//       .string()
//       .email("Invalid email address")
//       .required("Email is required"),
//     phoneNumber: yup.string().required("Phone number is required"),
//     client: yup.string().required("Client is required"),
//   });

// project Id list View
const [projectIDlist, setProjectIdlist] = useState([]);
const [constId, setConstId] = useState("");

const getProjectList = async () => {
  try {
    const response = await axios.get(`${api_rout}/project/get/all`);
    console.log(response, "get all projects");
    let data = response.data;

    data = data.map((item) => {
      return {
        value: item.projectNo,
        label: item.projectName,
      };
    });
    console.log(data);
    setProjectIdlist(data);
  } catch (err) {
    console.log(err);
  }
};

useEffect(() => {
  getProjectList();
}, []);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      password: "",
      designation: "",
      email: "",
      phoneNumber: "",
      client: "",
      isActive: false,
    },
    // validationSchema: validationSchema,
    onSubmit: async (values) => {
        console.log(values);
      try {
        // Call the API to submit the form data
        const response = await fetch("https://sig.eastus.cloudapp.azure.com/backend/user", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });

        if (response.ok) {
          // Handle successful API response
          console.log("User added successfully");
          console.log(values);
        } else {
          // Handle API error
          console.error("Failed to add user");
        }
      } catch (error) {
        // Handle network or other errors
        console.error("Error occurred:", error);
      }
    },
  });

  return (
    <MainLayout>
      <Box
        sx={{
          marginTop: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="pageFormWrap">
          <Typography component="h1" variant="h3">
            Add User
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="First Name"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                //   error={
                //     formik.touched.firstName && Boolean(formik.errors.firstName)
                //   }
                //   helperText={
                //     formik.touched.firstName && formik.errors.firstName
                //   }
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
              <TextField
                  label="Last Name"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                //   error={
                //     formik.touched.lastName && Boolean(formik.errors.lastName)
                //   }
                //   helperText={
                //     formik.touched.lastName && formik.errors.lastName
                //   }
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
              <TextField
                  label="Designation"
                  name="designation"
                  value={formik.values.designation}
                  onChange={formik.handleChange}
                //   error={
                //     formik.touched.designation && Boolean(formik.errors.designation)
                //   }
                //   helperText={
                //     formik.touched.designation && formik.errors.designation
                //   }
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                //   error={
                //     formik.touched.email && Boolean(formik.errors.email)
                //   }
                //   helperText={
                //     formik.touched.email && formik.errors.email
                //   }
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
              <TextField
                  label="Phone Number"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                //   error={
                //     formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
                //   }
                //   helperText={
                //     formik.touched.phoneNumber && formik.errors.phoneNumber
                //   }
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
              <TextField
                  label="Company Name"
                  name="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                //   error={
                //     formik.touched.companyName && Boolean(formik.errors.companyName)
                //   }
                //   helperText={
                //     formik.touched.companyName && formik.errors.companyName
                //   }
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
              <TextField
                  label="Password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                //   error={
                //     formik.touched.password && Boolean(formik.errors.password)
                //   }
                //   helperText={
                //     formik.touched.password && formik.errors.password
                //   }
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={projectIDlist}
                  // value={trialFinal}
                  onChange={(newValue, e) => {
                    console.log("value", newValue, e);
                    if (!e) {
                      setConstId("");
                    } else {
                      setConstId(e.value);
                    }
                  }}
                  renderInput={(params) => {
                    // console.log(params,"selected")
                    return <TextField {...params} label="Project ID" fullWidth margin="normal" />;
                  }}
                />
              </Grid>
              <Grid item xs={12}><Button type="submit" variant="contained" color="primary">Add User</Button></Grid>
            </Grid>
          </form>
        </div>
      </Box>
    </MainLayout>
  );
};

export default Add_user;
