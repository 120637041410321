import { Box, CircularProgress } from "@mui/material";
import React, { useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button } from "antd";

export const ExcelDataUpload = (props) => {
  const { handeImportExcellData,buttonName } = props;
  const [selectFile, setSelectFile] = useState();
  const [loading, setLoading] = useState(false);

  const fileRef = useRef();
  const color = "#e53267";

  const style = {
    height: "350px",
    overflow: "auto",
  };

  return (
    <Box sx={{ display: "inline-block", marginLeft: "10px" }}>
      <input
        hidden
        ref={fileRef}
        type="file"
        accept=".xlsx"
        onChange={(event) => {
          handeImportExcellData(event.target.files[0]);
          //   setUploadImgName(event.target.files[0].name)
        }}
      />
      <div style={{}}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box>
            <Button
              sx={{
                backgroundColor: color,
                color: "#fff",
                "&:hover": { backgroundColor: color },
              }}
              endIcon={<CloudUploadIcon />}
              variant="contained"
              onClick={() => {
                fileRef.current.click();
              }}
            >
              {buttonName}
            </Button>

            {/* <Button  sx={{
                                                color: "#fff",
                                              }}
                                              color='success'
                                              variant = 'contained'
                                              onClick={handleUploadFile}
                                              >
                                                <AddIcon/>
                                              </Button> */}
          </Box>
        )}
      </div>
    </Box>
  );
};
