import React, { useEffect } from "react";
import { TextField, Button, FormControlLabel, Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import MainLayout from "../../layout/MainLayout";
import { useState } from "react";
import apiService from "../../data/apiService";
import { openDB } from "idb";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";



const AddConstProject = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const addConstProjectSchema =  Yup.object().shape({
    contractNumber : Yup.string()
      .required("Mobile number is required")
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(10, "Mobile number is not valid")
      .max(10, "Mobile number not valid"),
      projectName : Yup.string().required("Project Number is required"),
      usaceContact : Yup.string().required("Usace Contact is required"),
      division : Yup.string().required("Division is required"),
      district : Yup.string().required("District is required"),
      constStartDate : Yup.string().required("Const Start Date is required"),
      constEndDate : Yup.string().required("Const End Date is required"),
      comments : Yup.string().required("Comments is required")
  })

  const formik = useFormik({
    initialValues: {
      contractNumber : "",
      projectName : "",
      usaceContact : "",
      division : "",
      district : "",
      constStartDate : "2017-05-24",
      constEndDate : "2021-07-12",
      comments : ""
    },
    validationSchema: addConstProjectSchema,
    onSubmit: async(values) => {
      // console.log(values);
        try {
      // console.log(values);
      // Make API request to submit the form data
      const response = await apiService.addNewConstProject(values);
    } catch (error) {
      console.log("offline");
      console.error("Error submitting form:", error);
    }
    }
  })

  const {
    values,
    touched,
    errors,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <MainLayout>
      <Box
        sx={{
          marginTop: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
          "& #images": {
            paddingLeft: "120px",
          },
          "& #video": {
            paddingLeft: "120px",
          },
        }}
      >
        <div className="pageFormWrap">
          <Typography component="h1" variant="h3">
            Construction Details
          </Typography>
          <FormikProvider value={formik}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Contract Number"
                  name="contractNumber"
                  id="contractNumber"
                  // value={contractNumber}
                  // onChange={handleContractNumberChange}
                  {...getFieldProps("contractNumber")}
                    error={touched.contractNumber && errors.contractNumber}
                    helperText={touched.contractNumber && errors.contractNumber}

                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Project Name"
                  name="projectName"
                  id="projectName"
                  // value={projectName}
                  // onChange={handleProjectNameChange}
                  {...getFieldProps("projectName")}
                  error={touched.projectName && errors.projectName}
                  helperText={touched.projectName && errors.projectName}

                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Usace Contact"
                  name="usaceContact"
                  id="usaceContact"
                  // value={usaceContact}
                  // onChange={handleUsaceContactChange}
                  {...getFieldProps("usaceContact")}
                  error={touched.usaceContact && errors.usaceContact}
                  helperText={touched.usaceContact && errors.usaceContact}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Division"
                  name="division"
                  id="division"
                  // value={division}
                  // onChange={handleDivisionChange}
                  {...getFieldProps("division")}
                  error={touched.division && errors.division}
                  helperText={touched.division && errors.division}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="District"
                  name="district"
                  id="district"
                  // value={district}
                  // onChange={handleDistrictChange}
                  {...getFieldProps("district")}
                  error={touched.district && errors.district}
                  helperText={touched.district && errors.district}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Start Date"
                  type="date"
                  name="constStartDate"
                  id="constStartDate"
                  // value={constStartDate}
                  // onChange={handleConstStartDateChange}
                  {...getFieldProps("constStartDate")}
                  error={touched.constStartDate && errors.constStartDate}
                  helperText={touched.constStartDate && errors.constStartDate}
                  fullWidth
                  margin="normal"
                  // defaultValue="2017-05-24"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="End Date"
                  type="date"
                  name="constEndDate"
                  id="constEndDate"
                  // value={constEndDate}
                  // onChange={handleConstEndDateChange}
                  {...getFieldProps("constEndDate")}
                  error={touched.constEndDate && errors.constEndDate}
                  helperText={touched.constEndDate && errors.constEndDate}
                  fullWidth
                  margin="normal"
                  // defaultValue="2021-05-24"
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  label="Comments"
                  name="comments"
                  id="comments"
                  // value={comments}
                  // onChange={handleCommentsChange}
                  {...getFieldProps("comments")}
                  error={touched.comments && errors.comments}
                  helperText={touched.comments && errors.comments}
                  fullWidth
                  margin="normal"
                  multiline
                  maxRows={3}
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
          </FormikProvider>
        </div>
      </Box>
    </MainLayout>
  );
};

export default AddConstProject;
