import { useTheme } from "@emotion/react";
import MaterialTable from "@material-table/core";
import { Box, Button } from "@mui/material";
import React from "react";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { api_rout } from "../../Api-Config/config";
import { useEffect } from "react";

export default function Mix_List() {
  const [data, setData] = useState();
  const [clickedRow, setClickedRow] = React.useState();
  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const onButtonClick = (e, row) => {
    e.stopPropagation();
    setClickedRow(row);
  };
  const handleButtonClick = (projectNo) => {
    // Do something with the project ID
    console.log("Clicked project ID:", projectNo);
    // localStorage.setItem("projectId", projectNo);
    navigate("/viewConcreteMixDesign");
  };

  const getAllconcreteMixDesignData = async () => {
    try {
      const response = await axios.get(`${api_rout}/concmixdesign/get/all`);
      console.log(response.data, "getAllconcreteMixDesignData");
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllconcreteMixDesignData();
  }, []);

      const handleMixStatus = (event, rowData) => {
        const selectedValue = event.target.value;
        const updatedData = data.map(item => 
          item.id === rowData.id ? { ...item, trialFinal: selectedValue } : item
          );

          fetch(`${api_rout}/concmixdesign/edit/${rowData.id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ trialFinal: selectedValue }),
          })
          .then(response => response.json())
          .then(() => {
            setData(updatedData);
          })
          .catch(error => console.error('Error updating data:', error));
      };


      useEffect(()=>{
        getAllconcreteMixDesignData();
      },[])

    const CSMcolumn = [
        // { field: "id", title: "ID" ,width: 5},
        // { field: "mixId", title: "Mix ID",width: 10 },
    //     { field: "plantId", title: "Trial/Final",width: 10,
    // render:(params)=> {
    //         return params.trialFinal === 0 ? "Trial" : "Final"
    //     }
    // },
    //     { field: "mixDescription", title: "Mix Description",width: 10 },
    //     { field: "wcRatio", title: "Water/Cement Ratio",width: 10 },
    //     { field: "sand", title: "Sand",width: 10 },
        { field: "author", title: "Author" },
        { field: "trialFinal", title: "Trial/Final",width: 10,
        render: (params)=> {
          return params.trialFinal===0 ? "Trial" : "Final" 
        }
      },
        // { field: "version", title: "Version",width: 10 },
        { field: "dateAppended", title: "Date Appended",width: 10,
        render:(params)=> {
            return params.dateAppended.slice(0,10)
        }
    },
        { field: "mixDescription", title: "Mix Description",width: 10 },
        { field: "wcRatio", title: "Water/Cement Ratio",width: 10 },
    { field: "sand", title: "Sand",width: 10 },
    { field: "sNo8", title: "sNo8",width: 10 },
    { field: "sNo67", title: "sNo67",width: 10 },
    { field: "sNo4", title: "sNo4",width: 10 },
    { field: "s3In", title: "s3ln",width: 10 },
    { field: "flyAsh", title: "flyAsh",width: 10 },
    { field: "comments", title: "Comments",width: 10 },

    // {
    //   field: "action",
    //   title: "Actions",
    //   width: 100,
    //   // valueGetter: (params) => params.row.id,
    //   render: (params) => {
    //     return (
    //       <Box
    //         sx={{
    //           "& .grid-actionsWrap": {
    //             display: "flex",
    //             justifyContent: "space-between",
    //             width: "100px",
    //           },
    //           "& .grid-actionsWrap Button": {
    //             fontSize: "13px",
    //             padding: "4px",
    //             minWidth: "auto",
    //             marginRight: "5px",
    //             color: colors.grey[300],
    //             // backgroundColor: colors.primary[500],
    //           },
    //         }}
    //       >
    //         <div className="grid-actionsWrap">
    //           <Button
    //             onClick={() => handleButtonClick(params.projectNo)}
    //             variant="contained"
    //           >
    //             <VisibilityOutlinedIcon />
    //           </Button>
    //           <Button
    //             onClick={(e) => onButtonClick(e, params)}
    //             variant="contained"
    //           >
    //             <EditOutlinedIcon />
    //           </Button>
    //         </div>
    //       </Box>
    //     );
    //   },
    //   width: 120,
    // },
  ];

  return (
    <Box>
      <Box
        m="40px 0 0 0"
        sx={{
          "& .MuiDataGrid-root": {
            fontSize: "14px",
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-root p": {
            margin: 0,
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none !important",
            backgroundColor: colors.grey[900],
            fontSize: "14px",
          },
          "& .name-column--cell": {
            color: colors.primary[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[900],
            borderColor: colors.grey[800],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.grey[600],
          },
          "& .MuiDataGrid-footerContainer": {
            borderColor: colors.grey[800],
            backgroundColor: colors.grey[900],
          },
          "& .user_list_title": {
            color: colors.grey[300],
            fontSize: "22px",
            fontWeight: 600,
          },
          "& .user_list_gridWrap": {
            backgroundColor: colors.grey[800],
          },
          "& .user_list_search": {
            backgroundColor: colors.grey[900],
          },
          "& .user_list_search input": {
            color: colors.grey[200],
          },
          "& .grid-actionsWrap": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          },
          "& .grid-actionsWrap Button": {
            fontSize: "13px",
            padding: "4px",
            minWidth: "auto",
            marginRight: "5px",
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <MaterialTable
          title=""
          columns={CSMcolumn}
          data={data}
          options={{
            toolbar: false,  // This removes the title bar
            search: false,      // This disables the search field
            paging:data?.length<= 5 ? false : true,      // This disables the footer pagination
            pageSize: data?.length<= 5 ? data.length : 5, 
            // pageSizeOptions: [10, 20, 30],
            rowStyle: {
              backgroundColor: '#EEE',
              height:"10 px"
            },
            headerStyle: {
              backgroundColor: '#01579b',
              color: '#FFF'
            }
          }}
        />
      </Box>
    </Box>
  );
}
