import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";

function AddTestData({
  schedule,
  state,
  users,
  handleRefresh,
  setTestData,
  SetpsForTest,
  reschedule,
}) {
  const [fromOneValue, setFromOneValue] = useState({
    data: "",
    result: "",
    scheduleId: state,
    approveBy: null,
  });
  const [selected, setSelected] = React.useState("");
  const [alreadySubmited, setAlreadySubmited] = useState(false);

  const handleChange = (event) => {
    setSelected(event.target.value);
    setFromOneValue({ ...fromOneValue, ["approveBy"]: event.target.value });
  };

  const handleSubmit = () => {
    axios
      .post("https://sig.eastus.cloudapp.azure.com/backend/test/create", { ...fromOneValue })
      .then((res) => {
        axios.patch(
          `https://sig.eastus.cloudapp.azure.com/backend/schedule/status/${schedule.id}`,
          {
            status: "tested",
          }
        );
        setAlreadySubmited(true);
        handleRefresh();
      });
  };

  const handleChangeFormOne = (event) => {
    const { name, value } = event.target;
    setFromOneValue({ ...fromOneValue, [name]: value });
  };

  useEffect(() => {
    axios
      .get(`https://sig.eastus.cloudapp.azure.com/backend/test/get/by/schedule/${state}`)
      .then((res) => {
        console.log(res);
        console.log(state);
        if (res.data.length > 0) {
          setFromOneValue({
            data: res.data[0].data,
            result: res.data[0].result,
            approveBy: res.data[0].approveBy,
          });
          setSelected(res.data[0].approveBy);
          setTestData(res.data[0]);
          console.log(res.data[0]);
          setAlreadySubmited(true);
        }
      });
  }, []);

  return (
    <div className="step-div">
      <h1>Test Data</h1>
      <form className="one-form">
        <div>
          test data:
          <input
            type="text"
            name="data"
            value={fromOneValue.data}
            disabled={
              schedule.technician != localStorage.getItem("user") ||
              alreadySubmited ||
              reschedule.status
            }
            onChange={handleChangeFormOne}
          />
        </div>
        <div>
          Pass:{" "}
          <input
            type="radio"
            name="result"
            value="pass"
            checked={fromOneValue.result === "pass"}
            onChange={handleChangeFormOne}
            disabled={
              schedule.technician != localStorage.getItem("user") ||
              alreadySubmited ||
              reschedule.status
            }
          />
          Fail:{" "}
          <input
            type="radio"
            name="result"
            value="fail"
            checked={fromOneValue.result === "fail"}
            onChange={handleChangeFormOne}
            disabled={
              schedule.technician != localStorage.getItem("user") ||
              alreadySubmited ||
              reschedule.status
            }
          />
        </div>
        <div>
          {SetpsForTest.signAndApprove === 1 && (
            <FormControl
              sx={{ m: 1, minWidth: 120, color: "white" }}
              size="small"
            >
              <InputLabel id="demo-simple-select-label">
                To be approved by
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selected}
                label="Age"
                disabled={
                  schedule.technician != localStorage.getItem("user") ||
                  alreadySubmited ||
                  reschedule.status
                }
                onChange={handleChange}
              >
                {users.map((value) => (
                  <MenuItem value={value.id} key={value.id}>
                    {value.firstName + " " + value.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        <div>
          {schedule.technician == localStorage.getItem("user") &&
            reschedule.status === false &&
            !alreadySubmited && (
              <button type="button" onClick={handleSubmit}>
                Submit
              </button>
            )}
          {schedule && alreadySubmited && (
            <label>
              Submitted by {schedule.firstName + " " + schedule.lastName}
            </label>
          )}
        </div>
      </form>
    </div>
  );
}

export default AddTestData;
