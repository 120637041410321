import React, { useEffect, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import MainLayout from "../../layout/MainLayout";
import apiService from "../../data/apiService";
import ProjectMap from "../maps/projectMap";
import { useNavigate } from "react-router-dom";
import { useParams,useLocation } from "react-router-dom";
import TestList from "../projects/Test_List";

const ConcreteBatchView = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

  const projectId = localStorage.getItem("projectId");
  const nevigate = useNavigate();

  return (
    <MainLayout>
        <Box
        sx={{
            p: 2
        }}>
    <Box 
    sx={{
      p: 2,
      border: "1px solid #ddd",
      borderRadius: "4px",
      "& h5": {
        fontSize: "16px",
        fontWeight: "600",
      },
    }}
    >
    <div className="row" style={{ }}>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-9">
            <h1 className="page_title mb-4">Project Name - Text Name</h1>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-3 mb-1">
            <div className="projectView_txt">
              <h5>Construction ID</h5>
              <p>Civil_235</p>
            </div>
          </div>
          <div className="col-md-3 mb-1">
            <div className="projectView_txt">
              <h5>Concrete Mix ID</h5>
              <p>07</p>
            </div>
          </div>
          <div className="col-md-3 mb-1">
            <div className="projectView_txt">
              <h5>Plant ID</h5>
              <p>86</p>
            </div>
          </div>
          <div className="col-md-3 mb-1">
            <div className="projectView_txt">
              <h5>Mixer ID</h5>
              <p>TF756</p>
            </div>
          </div>
          <div className="col-md-3 mb-1">
            <div className="projectView_txt">
              <h5>Batch Number</h5>
              <p>42</p>
            </div>
          </div>
          <div className="col-md-3 mb-1">
            <div className="projectView_txt">
              <h5>Ticket Number</h5>
              <p>12</p>
            </div>
          </div>
          <div className="col-md-3 mb-1">
            <div className="projectView_txt">
              <h5>Truck ID</h5>
              <p>YS 8734</p>
            </div>
          </div>
          <div className="col-md-3 mb-1">
            <div className="projectView_txt">
              <h5>Batch Time</h5>
              <p>2023-07-20 09:30 AM</p>
            </div>
          </div>
          <div className="col-md-3 mb-1">
            <div className="projectView_txt">
              <h5>Batch</h5>
              <p>A-45</p>
            </div>
          </div>
          <div className="col-md-3 mb-1">
            <div className="projectView_txt">
              <h5>Batch Yards</h5>
              <p>202 yrds</p>
            </div>
          </div>
          <div className="col-md-3 mb-1">
            <div className="projectView_txt">
              <h5>Batch LBS</h5>
              <p>1324lbs</p>
            </div>
          </div>
          <div className="col-md-3 mb-1">
            <div className="projectView_txt">
              <h5>Required WC</h5>
              <p>78</p>
            </div>
          </div>
          <div className="col-md-3 mb-1">
            <div className="projectView_txt">
              <h5>Water Trim</h5>
              <p>Turbo</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Box>
    <TestList />
    </Box>
    </MainLayout>
  );
};

export default ConcreteBatchView;
