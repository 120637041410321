import React, { useEffect } from "react";
import {
  TextField,
  Button,
  FormControlLabel,
  Box,
  Autocomplete,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useFormik } from "formik";
import MainLayout from "../../layout/MainLayout";
import { useState } from "react";
import apiService from "../../data/apiService";
import { openDB } from "idb";
import axios from "axios";
import { api_rout } from "../../Api-Config/config";

const AddConcreteBatch = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [constId, setConstId] = useState("");
  const [mixId, setMixId] = useState("");
  const [plantId, setPlantId] = useState("");
  const [mixerId, setMixerId] = useState("");
  const [batchNum, setBatchNum] = useState("");
  const [ticketNum, setTicketNum] = useState("");
  const [truckId, setTruckId] = useState("");
  const [timeBatched, setTimeBatched] = useState("");
  const [batchUse, setBatchUse] = useState("");
  const [placementLocation, setPlacementLocation] = useState("");
  const [ydsBatched, setYdsBatched] = useState("");
  const [lbsBatched, setLbsBatched] = useState("");
  const [wcRequired, setWcRequired] = useState("");
  const [wcBatched, setWcBatched] = useState("");
  const [waterTrim, setWaterTrim] = useState("");
  const [waterAdded, setWaterAdded] = useState("");
  const [totalWater, setTotalWater] = useState("");
  const [maxWater, setMaxWater] = useState("");
  const [addableWater, setAddableWater] = useState("");
  const [waterRequired, setWaterRequired] = useState("");
  const [waterBatched, setWaterBatched] = useState("");
  const [waterVariance, setWaterVariance] = useState("");
  const [iceRequired, setIceRequired] = useState("");
  const [iceBatched, setIceBatched] = useState("");
  const [iceVariance, setIceVariance] = useState("");
  const [sandDesign, setSandDesign] = useState("");
  const [sandRequired, setSandRequired] = useState("");
  const [sandBatched, setSandBatched] = useState("");
  const [sandVariance, setSandVariance] = useState("");
  const [sandMoisture, setSandMoisture] = useState("");
  const [sandActWater, setSandActWater] = useState("");
  const [no8Required, setNo8Required] = useState("");
  const [no8Batched, setNo8Batched] = useState("");
  const [no8Variance, setNo8Variance] = useState("");
  const [no8Moisture, setNo8Moisture] = useState("");
  const [no8ActWater, setNo8ActWater] = useState("");
  const [no67Required, setNo67Required] = useState("");
  const [no67Batched, setNo67Batched] = useState("");
  const [no67Variance, setNo67Variance] = useState("");
  const [no67Moisture, setNo67Moisture] = useState("");
  const [no67ActWater, setNo67ActWater] = useState("");
  const [no4Required, setNo4Required] = useState("");
  const [no4Batched, setNo4Batched] = useState("");
  const [no4Variance, setNo4Variance] = useState("");
  const [no4Moisture, setNo4Moisture] = useState("");
  const [no4ActWater, setNo4ActWater] = useState("");
  const [s3InRequired, setS3InRequired] = useState("");
  const [s3InBatched, setS3InBatched] = useState("");
  const [s3InVariance, setS3InVariance] = useState("");
  const [s3InMoisture, setS3InMoisture] = useState("");
  const [s3InActWater, setS3InActWater] = useState("");
  const [cmtRequired, setCmtRequired] = useState("");
  const [cmtBatched, setCmtBatched] = useState("");
  const [cmtVariance, setCmtVariance] = useState("");
  const [flyAshRequired, setFlyAshRequired] = useState("");
  const [flyAshBatched, setFlyAshBatched] = useState("");
  const [flyAshVariance, setFlyAshVariance] = useState("");
  const [comments, setComments] = useState("");
  const [dateAppended, setDateAppended] = useState("");
  const [dateModified, setDateModified] = useState("");
  const [author, setAuthor] = useState("");
  const [qcVerifier, setQcVerifier] = useState("");
  const [qcDate, setQcDate] = useState("");
  const [qaVerifier, setQaVerifier] = useState("");
  const [qaDate, setQaDate] = useState("");

  // project Id list View
  const [projectIDlist, setProjectIdlist] = useState([]);

  const getProjectList = async () => {
    try {
      const response = await axios.get(`${api_rout}/project/get/all`);
      console.log(response, "get all projects");
      let data = response.data;

      data = data.map((item) => {
        return {
          value: item.projectNo,
          label: item.projectName,
        };
      });
      console.log(data);
      setProjectIdlist(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProjectList();
  }, []);

  const handleChangeInputs = (sateState) => (e) => {
    console.log("click");
    console.log(e.target.value);
    sateState(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("constId", constId);
    formData.append("mixId", mixId);
    formData.append("plantId", plantId);
    formData.append("mixerId", mixerId);
    formData.append("batchNum", batchNum);
    formData.append("ticketNum", ticketNum);
    formData.append("truckId", truckId);
    formData.append("timeBatched", timeBatched);
    formData.append("batchUse", batchUse);
    formData.append("placementLocation", placementLocation);
    formData.append("ydsBatched", ydsBatched);
    formData.append("lbsBatched", lbsBatched);
    formData.append("wcRequired", wcRequired);
    formData.append("wcBatched", wcBatched);
    formData.append("waterTrim", waterTrim);
    formData.append("waterAdded", waterAdded);
    formData.append("totalWater", totalWater);
    formData.append("maxWater", maxWater);
    formData.append("addableWater", addableWater);
    formData.append("waterRequired", waterRequired);
    formData.append("waterBatched", waterBatched);
    formData.append("waterVariance", waterVariance);
    formData.append("iceRequired", iceRequired);
    formData.append("iceBatched", iceBatched);
    formData.append("iceVariance", iceVariance);
    formData.append("sandDesign", sandDesign);
    formData.append("sandRequired", sandRequired);
    formData.append("sandBatched", sandBatched);
    formData.append("sandVariance", sandVariance);
    formData.append("sandMoisture", sandMoisture);
    formData.append("sandActWater", sandActWater);
    formData.append("no8Required", no8Required);
    formData.append("no8Batched", no8Batched);
    formData.append("no8Variance", no8Variance);
    formData.append("no8Moisture", no8Moisture);
    formData.append("no8ActWater", no8ActWater);
    formData.append("no67Required", no67Required);
    formData.append("no67Batched", no67Batched);
    formData.append("no67Variance", no67Variance);
    formData.append("no67Moisture", no67Moisture);
    formData.append("no67ActWater", no67ActWater);
    formData.append("no4Required", no4Required);
    formData.append("no4Batched", no4Batched);
    formData.append("no4Variance", no4Variance);
    formData.append("no4Moisture", no4Moisture);
    formData.append("no4ActWater", no4ActWater);
    formData.append("s3InRequired", s3InRequired);
    formData.append("s3InBatched", s3InBatched);
    formData.append("s3InVariance", s3InVariance);
    formData.append("s3InMoisture", s3InMoisture);
    formData.append("s3InActWater", s3InActWater);
    formData.append("cmtRequired", cmtRequired);
    formData.append("cmtBatched", cmtBatched);
    formData.append("cmtVariance", cmtVariance);
    formData.append("flyAshRequired", flyAshRequired);
    formData.append("flyAshBatched", flyAshBatched);
    formData.append("flyAshVariance", flyAshVariance);
    formData.append("comments", comments);
    formData.append("dateAppended", dateAppended);
    formData.append("author", author);
    formData.append("qcVerifier", qcVerifier);
    formData.append("qcDate", qcDate);
    formData.append("qaVerifier", qaVerifier);
    formData.append("qaDate", qaDate);

    console.log(formData);
    const formDataObj = {};
    await formData.forEach((value, key) => (formDataObj[key] = value));
    console.log(formDataObj);
    try {
      // Make API request to submit the form data
      const response = await axios.post(
        `${api_rout}/concbatchtickets/create`,
        formData
      );
    } catch (error) {
      console.log("offline");
      console.error("Error submitting form:", error);
    }
  };

  return (
      <Box
        sx={{
          marginTop: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
          "& #images": {
            paddingLeft: "120px",
          },
          "& #video": {
            paddingLeft: "120px",
          },
        }}
      >
        <div className="pageFormWrap">
          <Typography component="h1" variant="h3">
            Concrete Batch Ticket
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                {/* <TextField
                  label="Construction ID"
                  name="constId"
                  id="constId"
                  value={constId}
                  onChange = {handleChangeInputs(setConstId)}
                  // onChange={handleConstIdChange}
                  fullWidth
                  margin="normal"
                /> */}

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={projectIDlist}
                  // value={trialFinal}
                  onChange={(newValue, e) => {
                    console.log("value", newValue, e);
                    if (!e) {
                      setConstId("");
                    } else {
                      setConstId(e.value);
                    }
                  }}
                  renderInput={(params) => {
                    // console.log(params,"selected")
                    return <TextField {...params} label="Construction ID" fullWidth margin="normal" />;
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Concrete Mix ID"
                  name="mixId"
                  id="mixId"
                  value={mixId}
                  onChange={handleChangeInputs(setMixId)}
                  // onChange={handleMixIdChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Plant ID"
                  name="plantId"
                  id="plantId"
                  value={plantId}
                  onChange={handleChangeInputs(setPlantId)}
                  // onChange={handlePlantIdChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Mixer ID"
                  name="mixerId"
                  id="mixerId"
                  value={mixerId}
                  onChange={handleChangeInputs(setMixerId)}
                  // onChange={handleMixerIdChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Batch Number"
                  name="batchNum"
                  id="batchNum"
                  value={batchNum}
                  onChange={handleChangeInputs(setBatchNum)}
                  // onChange={handleBatchNumChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Ticket Number"
                  name="ticketNum"
                  id="ticketNum"
                  value={ticketNum}
                  onChange={handleChangeInputs(setTicketNum)}
                  // onChange={handleticketNumChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Truck ID"
                  name="truckId"
                  id="truckId"
                  value={truckId}
                  onChange={handleChangeInputs(setTruckId)}
                  // onChange={handletruckIdChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  helperText="Batch Time"
                  type="date"
                  name="timeBatched"
                  id="timeBatched"
                  value={timeBatched}
                  onChange={handleChangeInputs(setTimeBatched)}
                  // onChange={handleTimeBatchedChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Batch"
                  name="batchUse"
                  id="batchUse"
                  value={batchUse}
                  onChange={handleChangeInputs(setBatchUse)}
                  // onChange={handleBatchUseChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Placement Location"
                  name="placementLocation"
                  id="placementLocation"
                  value={placementLocation}
                  onChange={handleChangeInputs(setPlacementLocation)}
                  // onChange={handlePlacementLocationChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Bacth Yards"
                  name="ydsBatched"
                  id="ydsBatched"
                  value={ydsBatched}
                  onChange={handleChangeInputs(setYdsBatched)}
                  // onChange={handleYdsBatchedChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Batch LBS"
                  name="lbsBatched"
                  id="lbsBatched"
                  value={lbsBatched}
                  onChange={handleChangeInputs(setLbsBatched)}
                  // onChange={handleLbsBatchedChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Required WC"
                  name="wcRequired"
                  id="wcRequired"
                  value={wcRequired}
                  onChange={handleChangeInputs(setWcRequired)}
                  // onChange={handleWcRequiredChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Batched WC"
                  name="wcBatched"
                  id="wcBatched"
                  value={wcBatched}
                  onChange={handleChangeInputs(setWcBatched)}
                  // onChange={handleWcBatchedChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Water Trim"
                  name="waterTrim"
                  id="waterTrim"
                  value={waterTrim}
                  onChange={handleChangeInputs(setWaterTrim)}
                  // onChange={handleWaterTrimChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Water Added"
                  name="waterAdded"
                  id="waterAdded"
                  value={waterAdded}
                  onChange={handleChangeInputs(setWaterAdded)}
                  // onChange={handleWaterAddedChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Total Water"
                  name="totalWater"
                  id="totalWater"
                  value={totalWater}
                  onChange={handleChangeInputs(setTotalWater)}
                  // onChange={handleTotalWaterChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Maximum Water"
                  name="maxWater"
                  id="maxWater"
                  value={maxWater}
                  onChange={handleChangeInputs(setMaxWater)}
                  // onChange={handleMaxWaterChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Addable Water"
                  name="addableWater"
                  id="addableWater"
                  value={addableWater}
                  onChange={handleChangeInputs(setAddableWater)}
                  // onChange={handleAddableWaterChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>

              {/* changes  by me */}

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Water Required"
                  name="waterRequired"
                  id="waterRequired"
                  value={waterRequired}
                  onChange={handleChangeInputs(setWaterRequired)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Water Batched"
                  name="waterBatched"
                  id="waterBatched"
                  value={waterBatched}
                  onChange={handleChangeInputs(setWaterBatched)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Water Variance"
                  name="waterVariance"
                  id="waterVariance"
                  value={waterVariance}
                  onChange={handleChangeInputs(setWaterVariance)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Ice Required"
                  name="iceRequired"
                  id="iceRequired"
                  value={iceRequired}
                  onChange={handleChangeInputs(setIceRequired)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Ice Batched"
                  name="iceBatched"
                  id="iceBatched"
                  value={iceBatched}
                  onChange={handleChangeInputs(setIceBatched)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Ice Variance"
                  name="iceVariance"
                  id="iceVariance"
                  value={iceVariance}
                  onChange={handleChangeInputs(setIceVariance)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Sand Design"
                  name="sandDesign"
                  id="sandDesign"
                  value={sandDesign}
                  onChange={handleChangeInputs(setSandDesign)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Sand Required"
                  name="sandRequired"
                  id="sandRequired"
                  value={sandRequired}
                  onChange={handleChangeInputs(setSandRequired)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Sand Batched"
                  name="sandBatched"
                  id="sandBatched"
                  value={sandBatched}
                  onChange={handleChangeInputs(setSandBatched)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Sand Variance"
                  name="sandVariance"
                  id="sandVariance"
                  value={sandVariance}
                  onChange={handleChangeInputs(setSandVariance)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Sand Moisture"
                  name="sandMoisture"
                  id="sandMoisture"
                  value={sandMoisture}
                  onChange={handleChangeInputs(setSandMoisture)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="sand Actual Water"
                  name="sandActWater"
                  id="sandActWater"
                  value={sandActWater}
                  onChange={handleChangeInputs(setSandActWater)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="no8 Required"
                  name="no8Required"
                  id="no8Required"
                  value={no8Required}
                  onChange={handleChangeInputs(setNo8Required)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="no8 Batched"
                  name="no8Batched"
                  id="no8Batched"
                  value={no8Batched}
                  onChange={handleChangeInputs(setNo8Batched)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="no8 Variance"
                  name="no8Variance"
                  id="no8Variance"
                  value={no8Variance}
                  onChange={handleChangeInputs(setNo8Variance)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="no8 Moisture"
                  name="no8Moisture"
                  id="no8Moisture"
                  value={no8Moisture}
                  onChange={handleChangeInputs(setNo8Moisture)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="no8 Actual Water"
                  name="no8ActWater"
                  id="no8ActWater"
                  value={no8ActWater}
                  onChange={handleChangeInputs(setNo8ActWater)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="no67 Required"
                  name="no67Required"
                  id="no67Required"
                  value={no67Required}
                  onChange={handleChangeInputs(setNo67Required)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="no67 Batched"
                  name="no67Batched"
                  id="no67Batched"
                  value={no67Batched}
                  onChange={handleChangeInputs(setNo67Batched)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="no67 Variance"
                  name="no67Variance"
                  id="no67Variance"
                  value={no67Variance}
                  onChange={handleChangeInputs(setNo67Variance)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="no67 Moisture"
                  name="no67Moisture"
                  id="no67Moisture"
                  value={no67Moisture}
                  onChange={handleChangeInputs(setNo67Moisture)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="no67 Actual Water"
                  name="no67ActWater"
                  id="no67ActWater"
                  value={no67ActWater}
                  onChange={handleChangeInputs(setNo67ActWater)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="no4 Required"
                  name="no4Required"
                  id="no4Required"
                  value={no4Required}
                  onChange={handleChangeInputs(setNo4Required)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="no4 Batched"
                  name="no4Batched"
                  id="no4Batched"
                  value={no4Batched}
                  onChange={handleChangeInputs(setNo4Batched)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="no4 Variance"
                  name="no4Variance"
                  id="no4Variance"
                  value={no4Variance}
                  onChange={handleChangeInputs(setNo4Variance)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="no4 Moisture"
                  name="no4Moisture"
                  id="no4Moisture"
                  value={no4Moisture}
                  onChange={handleChangeInputs(setNo4Moisture)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="no4 Actual Water"
                  name="no4ActWater"
                  id="no4ActWater"
                  value={no4ActWater}
                  onChange={handleChangeInputs(setNo4ActWater)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="s3 In Required"
                  name="s3InRequired"
                  id="s3InRequired"
                  value={s3InRequired}
                  onChange={handleChangeInputs(setS3InRequired)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="s3 In Batched"
                  name="s3InBatched"
                  id="s3InBatched"
                  value={s3InBatched}
                  onChange={handleChangeInputs(setS3InBatched)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="s3 In Variance"
                  name="s3InVariance"
                  id="s3InVariance"
                  value={s3InVariance}
                  onChange={handleChangeInputs(setS3InVariance)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="s3 In Moisture"
                  name="s3InMoisture"
                  id="s3InMoisture"
                  value={s3InMoisture}
                  onChange={handleChangeInputs(setS3InMoisture)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="s3 In Actual Water"
                  name="s3InActWater"
                  id="s3InActWater"
                  value={s3InActWater}
                  onChange={handleChangeInputs(setS3InActWater)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Cement Required"
                  name="cmtRequired"
                  id="cmtRequired"
                  value={cmtRequired}
                  onChange={handleChangeInputs(setCmtRequired)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Cement Batched"
                  name="cmtBatched"
                  id="cmtBatched"
                  value={cmtBatched}
                  onChange={handleChangeInputs(setCmtBatched)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Cement Variance"
                  name="cmtVariance"
                  id="cmtVariance"
                  value={cmtVariance}
                  onChange={handleChangeInputs(setCmtVariance)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Fly Ash Required"
                  name="flyAshRequired"
                  id="flyAshRequired"
                  value={flyAshRequired}
                  onChange={handleChangeInputs(setFlyAshRequired)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Fly Ash Batched"
                  name="flyAshBatched"
                  id="flyAshBatched"
                  value={flyAshBatched}
                  onChange={handleChangeInputs(setFlyAshBatched)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="flyAsh Variance"
                  name="flyAshVariance"
                  id="flyAshVariance"
                  value={flyAshVariance}
                  onChange={handleChangeInputs(setFlyAshVariance)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  helperText="Date Appended"
                  type="date"
                  name="dateAppended"
                  id="dateAppended"
                  value={dateAppended}
                  onChange={handleChangeInputs(setDateAppended)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  helperText="Date Modified"
                  type="date"
                  name="dateModified"
                  id="dateModified"
                  value={dateModified}
                  onChange={handleChangeInputs(setDateModified)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Author"
                  name="author"
                  id="author"
                  value={author}
                  onChange={handleChangeInputs(setAuthor)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="QC Variance"
                  name="qcVerifier"
                  id="qcVerifier"
                  value={qcVerifier}
                  onChange={handleChangeInputs(setQcVerifier)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  helperText="QC Date"
                  type="date"
                  name="qcDate"
                  id="qcDate"
                  value={qcDate}
                  onChange={handleChangeInputs(setQcDate)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="QA Verifier"
                  name="qaVerifier"
                  id="qaVerifier"
                  value={qaVerifier}
                  onChange={handleChangeInputs(setQaVerifier)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  // label=""
                  type="date"
                  name="qaDate"
                  id="qaDate"
                  value={qaDate}
                  onChange={handleChangeInputs(setQaDate)}
                  fullWidth
                  margin="normal"
                  helperText="QA Date"
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  label="Comments"
                  name="comments"
                  id="comments"
                  value={comments}
                  onChange={handleChangeInputs(setComments)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Box>
  );
};

export default AddConcreteBatch;
