import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const User_cards = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetch("https://sig.eastus.cloudapp.azure.com/backend/user/allusers")
      .then((data) => data.json())
      .then((data) => setTableData(data));
  }, []);

  const userLength = tableData.length;

  return (
    <Box
    sx={{
      "& .user_card": {
        fontSize: "14px",
        color: colors.grey[300],
        backgroundColor: colors.grey[800],
      },
      "& .user_card_btn": {
        borderColor: colors.grey[500],
        color: colors.grey[300],
      },
      "& .user_card_btn:hover": {
        borderColor: "#3d96f7",
        color: colors.grey[900],
      },
      "& .user_card_rt .icon_wrap": {
        color: colors.grey[400],
      },
      "& .user_card_rt .icon_wrap:hover": {
        color: colors.grey[300],
      },
    }}
    >
            <div className="user_card" id="tot_user_card">
              <div className="row">
                <div className="col-6">
                  <div className="user_card_lt">
                    <div className="icon_wrap">
                      <PeopleAltOutlinedIcon />
                    </div>
                    <div className="user_card_count">
                      <h4>Total Users</h4>
                      <h3>{userLength}</h3>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="user_card_rt">
                    <div className="icon_wrap">
                      <MoreVertOutlinedIcon />
                      <div className="user_card_dropmenu">
                        <ul>
                          <li>
                            <a href="">Edit User</a>
                          </li>
                          <li>
                            <a href="">Delete User</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="user_card_btn" onClick={()=>{navigate("/add_user")}}>
                      <AddOutlinedIcon /> Add User
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </Box>
  );
};

export default User_cards;
