import React, { useEffect } from "react";
import {
  TextField,
  Button,
  FormControlLabel,
  Box,
  Autocomplete,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useFormik } from "formik";
import MainLayout from "../../layout/MainLayout";
import { useState } from "react";
import apiService from "../../data/apiService";
import { openDB } from "idb";
import axios from "axios";
import { api_rout } from "../../Api-Config/config";
import { Excelexport } from "../Excelexport";
import { ExcelDataUpload } from "../ExcelDataUpload";
import { useSelector } from "react-redux";

import * as XLSX from "xlsx";
import { notification } from "antd";
import MenuItemMui from "@mui/material/MenuItem";
import { useLocation, useNavigate } from "react-router-dom";

const AddConcreteMix = (props) => {
  const { user, isLoading } = useSelector((state) => state.user);

  const navigate = useNavigate();

//   const {states} = useLocation();

// console.log(states)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { schedule, setUploadFile,
    //  rejectData, 
     state 
    } = props;
  console.log(state, "state datat in add")
  const [trialFinal, setTrialFinal] = useState("");
  const [mixId, setMixId] = useState("");
  const [mixDescription, setMixDescription] = useState("");
  const [wcRatio, setWcRatio] = useState("");
  const [water, setWater] = useState("");
  const [sand, setSand] = useState("");
  const [sNo8, setSNo8] = useState("");
  const [sNo67, setSNo67] = useState("");
  const [sNo4, setSNo4] = useState("");
  const [s3ln, setS3ln] = useState("");
  const [flyAsh, setFlyAsh] = useState("");
  const [comments, setComments] = useState("");
  const [dateAppended, setDateAppended] = useState("");
  const [dateModified, setDateModified] = useState("");
  const [author, setAuthor] = useState("");
  const [qcVerifier, setQcVerifier] = useState("");
  const [qcDate, setQcDate] = useState("");
  const [qaVerifier, setQaVerifier] = useState("");
  const [qaDate, setQaDate] = useState("");
  const [scheduleId, setScheduleId] = useState(schedule);
  const [file, setFile] = useState("");
  // const [formData, setFormData] = useState(rejectData ? rejectData : {});
  const [formData, setFormData] = useState({});
  const [InputFildes, setInputFildes] = useState([]);
  const [TempLateDownloadLink, setTempLateDownloadLink] = useState("");

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "file") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      // const {value} = event.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    console.log("form Data", formData);
    // if (rejectData) {
    //   setFormData(rejectData)
    // }
  }, [formData]);


  const trialFinallist = [
    {
      label: "Trial ",
      value: "0",
    },
    {
      label: "Final",
      value: "1",
    },
  ];

  const ExcelExportData = [
    {
      Trial_or_Final: "",
      mixDescription: "",
      wcRatio: "",
      water: "",
      sand: "",
      sNo8: "",
      sNo67: "",
      sNo4: "",
      s3ln: "",
      flyAsh: "",
      comments: "",
    },
  ];

  const handeImportExcellData = async (file) => {
    console.log("Upload File: ", file);
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      console.log(wsname, "find sheet name");
      if (wsname !== "Concrete Mix Design") {
        notification.error({
          message: "Error",
          description: "The csv data tab does not match",
          placement: "topRight",
          duration: 3,
          style: {
            marginTop: "50px",
            // textTransform: "capitalize",
          },
        });
        return;
      } else {
        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
        console.log(dataParse, "upload file data");
        // setUploadFile(dataParse);
        let column = [
          "trialFinal",
          "mixId",
          "mixDescription",
          "wcRatio",
          "water",
          "sand",
          "sNo8",
          "sNo67",
          "sNo4",
          "s3In",
          "flyAsh",
          "comments",
        ];
        const areEqual =
          column.length === dataParse[0].length &&
          column.every((value, index) => value === dataParse[0][index]);

        if (!areEqual) {
          notification.error({
            message: "Error",
            description: "The csv data column names are not correct",
            placement: "topRight",
            duration: 3,
            style: {
              marginTop: "50px",
              // textTransform: "capitalize",
            },
          });
          return;
        }
        const keys = dataParse[0];
        // const objectsArray = [];

        // for (let i = 1; i < dataParse.length; i++) {
        //     const values = dataParse[i];
        //     const obj = {};

        //     for (let j = 0; j < keys.length; j++) {
        //         obj[keys[j]] = values[j].toString(); // Convert all values to strings
        //     }

        //     objectsArray.push(obj);
        // }
        var UploadArray = dataParse.slice(1).map((values) =>
          values.reduce((obj, value, index) => {
            obj[keys[index]] = value.toString();
            return obj;
          }, {})
        );
        const Upload = UploadArray.map((values, i) => {
          return { ...values, id: i + 1 };
        })
        console.log(Upload);
        setUploadFile(Upload);
      }

      notification.success({
        message: "Success",
        description: "Template upload successfully",
        placement: "topRight",
        duration: 2,
        style: {
          marginTop: "50px",
          textTransform: "capitalize",
        },
      });
    };
    await reader.readAsBinaryString(file);
  };

  // Handle change for all inputs
  const handleChangeInputs = (sateState) => (e) => {
    console.log("click");
    console.log(e.target.value);
    sateState(e.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    // if (state) {
    //   const formDatas = new FormData();
    //   formDatas.append("trialFinal", formData.trialFinal);
    //   formDatas.append("mixId", formData.mixId);
    //   formDatas.append("mixDescription", formData.mixDescription);
    //   formDatas.append("wcRatio", formData.wcRatio);
    //   formDatas.append("water", formData.water);
    //   formDatas.append("sand", formData.sand);
    //   formDatas.append("sNo8", formData.sNo8);
    //   formDatas.append("sNo67", formData.sNo67);
    //   formDatas.append("sNo4", formData.sNo4);
    //   formDatas.append("s3In", formData.s3In);
    //   formDatas.append("flyAsh", formData.flyAsh);
    //   formDatas.append("comments", formData.comments);
    //   formDatas.append("projectId", localStorage.getItem("projectId"));

    //   // formDatas.append("dateAppended", dateAppended);
    //   // formDatas.append("dateModified", dateModified);
    //   // formDatas.append("author", `${user.firstName} ${user.lastName}`);
    //   // formDatas.append("qcVerifier", qcVerifier);
    //   // formDatas.append("qcDate", qcDate);
    //   // formDatas.append("qaVerifier", qaVerifier);
    //   // formDatas.append("qaDate", qaDate);
    //   // formDatas.append("scheduleId", scheduleId);\
    //   formDatas.append("createdBy", user.id);
    //   formDatas.append("rejectedUUID", state.rejectedUUID);
    //   formDatas.append("approvalId", state.approvalId);
    //   // "rejectedUUID": "337cd0a0-fa70-494f-8a3a-f8b921a41cb5", // entryId

    //   // "approvalId": "86fd78cd-5377-4ac5-9e83-a05e1f40bf3b" // id

    //   console.log(formDatas);
    //   const formDataObj = {};
    //   formDatas.forEach((value, key) => (formDataObj[key] = value));
    //   console.log(formDataObj);
    //   try {
    //     // Make API request to submit the form data
    //     const response = await axios.post(
    //       `${api_rout}/concmixdesign/create`,
    //       formDatas
    //     );
    //     notification.success({
    //       message: "Success",
    //       description: "concrete mix created Sucessfully",
    //       placement: "topRight",
    //       duration: 2,
    //       style: {
    //         marginTop: "50px",
    //         textTransform: "capitalize",
    //       },
    //     });
    //     console.log(response);
    //     navigate(-1)
    //   } catch (error) {
    //     notification.error({
    //       message: "Error",
    //       description: "An error occurred",
    //       placement: "topRight",
    //       duration: 2,
    //       style: {
    //         marginTop: "50px",
    //         textTransform: "capitalize",
    //       },
    //     });

    //   }
    // }
    // else {
      const formDatas = new FormData();
      formDatas.append("trialFinal", formData.trialFinal);
      formDatas.append("mixId", formData.mixId);
      formDatas.append("mixDescription", formData.mixDescription);
      formDatas.append("wcRatio", formData.wcRatio);
      formDatas.append("water", formData.water);
      formDatas.append("sand", formData.sand);
      formDatas.append("sNo8", formData.sNo8);
      formDatas.append("sNo67", formData.sNo67);
      formDatas.append("sNo4", formData.sNo4);
      formDatas.append("s3In", formData.s3In);
      formDatas.append("flyAsh", formData.flyAsh);
      formDatas.append("comments", formData.comments);
      formDatas.append("projectId", localStorage.getItem("projectId"));
      formDatas.append("createdBy", user.id);

      console.log(formDatas);
      const formDataObj = {};
      formDatas.forEach((value, key) => (formDataObj[key] = value));
      console.log(formDataObj);
      try {
        // Make API request to submit the form data
        const response = await axios.post(
          `${api_rout}/concmixdesign/create`,
          formDatas
        );
        notification.success({
          message: "Success",
          description: "concrete mix created Sucessfully",
          placement: "topRight",
          duration: 2,
          style: {
            marginTop: "50px",
            textTransform: "capitalize",
          },
        });
        navigate(-1)
        console.log(response);
      }
      catch (error) {
        notification.error({
          message: "Error",
          description: "An error occurred",
          placement: "topRight",
          duration: 2,
          style: {
            marginTop: "50px",
            textTransform: "capitalize",
          },
        });
        console.log("offline");
        console.error("Error submitting form:", error);
      }
    // }
  };

  const getFormsAndExcelFileDownloadLink = async () => {
    try {
      const response = await axios.get(
        `${api_rout}/form/get/${state.tableName}`
      );
      console.log(response.data.data, "Data getFormsAndExcelFileDownloadLink");
      setInputFildes(response.data.data.form);
      setTempLateDownloadLink("http://23.101.131.108/backend/form/download/template/"+state.tableName);
    } catch (error) { }
  };

  useEffect(() => {
    getFormsAndExcelFileDownloadLink();
  }, []);

  return (
    <Box
      sx={{
        marginTop: 1,
        p: 3,
        display: "flex",
        flexDirection: "column",
        "& #images": {
          paddingLeft: "120px",
        },
        "& #video": {
          paddingLeft: "120px",
        },
      }}
    >
      <div className="pageFormWrap">
        {/* <Typography component="h1" variant="h3">
            Concrete Mix Design
          </Typography> */}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} sm={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={trialFinallist}
                  // value={trialFinal}
                  onChange={(newValue, e) => {
                    console.log("value", newValue, e);
                    if (!e) {
                      setTrialFinal("");
                    } else {
                      setTrialFinal(e.value);
                    }
                  }}
                  renderInput={(params) => {
                    // console.log(params,"selected")
                    return <TextField {...params} label="Trail/Final" fullWidth margin="normal" />;
                  }}
                />
              </Grid> */}
            {/* <Grid item xs={12} sm={4}>
              <TextField
                label="Mix ID"
                name="mixId"
                id="mixId"
                value={mixId}
                onChange={handleChangeInputs(setMixId)}
                // onChange={handleMixIdChange}
                fullWidth
                margin="normal"
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={4} md={3} className="pt-0">
              <TextField
                label="Mix Description"
                name="mixDescription"
                id="mixDescription"
                value={mixDescription}
                size="small"
                onChange={handleChangeInputs(setMixDescription)}
                // onChange={handleMixDescriptionChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} className="pt-0">
              <TextField
                label="Water/Cement Ratio"
                name="wcRatio"
                id="wcRatio"
                size="small"
                value={wcRatio}
                onChange={handleChangeInputs(setWcRatio)}
                // onChange={handleWcRatioChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} className="pt-0">
              <TextField
                label="Water"
                name="water"
                id="water"
                size="small"
                value={water}
                onChange={handleChangeInputs(setWater)}
                // onChange={handleWaterChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} className="pt-0">
              <TextField
                label="Sand"
                name="sand"
                id="sand"
                size="small"
                value={sand}
                onChange={handleChangeInputs(setSand)}
                // onChange={handleSandChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} className="pt-0">
              <TextField
                label="sNo8"
                name="sNo8"
                id="sNo8"
                size="small"
                value={sNo8}
                onChange={handleChangeInputs(setSNo8)}
                // onChange={handleSNo8Change}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} className="pt-0">
              <TextField
                label="sNo67"
                name="sNo67"
                id="sNo67"
                size="small"
                value={sNo67}
                onChange={handleChangeInputs(setSNo67)}
                // onChange={handleSNo67Change}
                fullWidth
                margin="normal"
                multiline
                maxRows={3}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} className="pt-0">
              <TextField
                label="sNo4"
                name="sNo4"
                id="sNo4"
                size="small"
                value={sNo4}
                onChange={handleChangeInputs(setSNo4)}
                // onChange={handleSNo4Change}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} className="pt-0">
              <TextField
                label="s3ln"
                name="s3ln"
                id="s3ln"
                size="small"
                value={s3ln}
                onChange={handleChangeInputs(setS3ln)}
                // onChange={handleS3lnChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} className="pt-0">
              <TextField
                label="flyAsh"
                name="flyAsh"
                id="flyAsh"
                size="small"
                value={flyAsh}
                onChange={handleChangeInputs(setFlyAsh)}
                // onChange={handleFlyAshChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={8} md={6} className="pt-0">
              <TextField
                label="Comments"
                name="comments"
                id="comments"
                size="small"
                value={comments}
                onChange={handleChangeInputs(setComments)}
                // onChange={handleCommentsChange}
                fullWidth
                margin="normal"
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={4}>
                <TextField
                  helperText="Date Appended"
                  type="date"
                  name="dateAppended"
                  id="dateAppended"
                  //   value={dateAppended}
                  onChange={handleChangeInputs(setDateAppended)}
                  // onChange={handleDateAppendedChange}
                  fullWidth
                  margin="normal"
                  defaultValue="2017-05-24"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  helperText="Date Modified"
                  type="date"
                  name="dateModified"
                  id="dateModified"
                  //   value={dateModified}
                  onChange={handleChangeInputs(setDateModified)}
                  // onChange={handleDateModifiedChange}
                  fullWidth
                  margin="normal"
                  defaultValue="2017-05-24"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Author"
                  name="author"
                  id="author"
                  value={author}
                  onChange={handleChangeInputs(setAuthor)}
                  // onChange={handleAuthorChange}
                  fullWidth
                  margin="normal"
                />
              </Grid> */}
            {/* <Grid item xs={12} sm={4}>
              <TextField
                label="qcVerifier"
                name="qcVerifier"
                id="qcVerifier"
                value={qcVerifier}
                onChange={handleChangeInputs(setQcVerifier)}
                // onChange={handleQcVerifierChange}
                fullWidth
                margin="normal"
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={4}>
                <TextField
                  helperText="qcDate"
                  type="date"
                  name="qcDate"
                  id="qcDate"
                  value={qcDate}
                  onChange={handleChangeInputs(setQcDate)}
                  // onChange={handleQcDateChange}
                  fullWidth
                  margin="normal"
                  defaultValue="2017-05-24"
                />
              </Grid> */}
            {/* <Grid item xs={12} sm={4}>
              <TextField
                label="qaVerifier"
                name="qaVerifier"
                id="qaVerifier"
                value={qaVerifier}
                onChange={handleChangeInputs(setQaVerifier)}
                // onChange={handleQaVerifierChange}
                fullWidth
                margin="normal"
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={4} md={3} className="pt-0">
              <TextField
                helperText="File Uploaded"
                type="file"
                size="small"
                value={qaVerifier}
                onChange={handleChangeInputs(setQaVerifier)}
                // onChange={handleQaVerifierChange}
                fullWidth
                margin="normal"
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={4}>
                <TextField
                  helperText="qcDate"
                  type="date"
                  name="qcDate"
                  id="qcDate"
                  value={qcDate}
                  onChange={handleChangeInputs(setQaDate)}
                  // onChange={handleQaDateChange}
                  fullWidth
                  margin="normal"
                  defaultValue="2021-05-24"
                />
              </Grid> */}

            {InputFildes?.map((item, i) =>
              item.details.type === "file" ? (
                <Grid item xs={12} sm={4} md={3} className="pt-0">
                  <TextField
                    type={item.details.type}
                    helperText={item.name}
                    size="small"
                    name={item.name}
                    // id="author"
                    // value={formData[item.clomName] || ''}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
              ) : item.details.type === "select" ? (
                <Grid item xs={12} sm={4} md={3} className="pt-0">

                  <TextField
                    id="outlined-select-currency"
                    select
                    size="small"
                    fullWidth
                    margin="normal"
                    label={item.details.label}
                    value={formData[item.name]}
                    onChange={handleInputChange}
                    name={item.name}
                    defaultValue={0}
                  >
                    {/* <MenuItem value={""}>
                      "Select options"
                    </MenuItem> */}
                    {item.details.option &&
                      item.details.option.data.map((options) => (
                        <MenuItem key={options.value} value={options.value}>
                          {options.label}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              ) : (
                <Grid item xs={12} sm={4} md={3} className="pt-0">
                  <TextField
                     type={item.details.type}
                    label={item.details.label}
                    size="small"
                    name={item.name}
                    // id="author"
                    value={formData[item.name] || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
              )
            )}

            <Grid
              item
              xs={12}
              sx={{ gap: 10, display: "inline-block" }}
              className="pt-0 d-flex justify-content-between"
            >
              <Box>
              {/* {
                !rejectData && <> */}
                <a href={TempLateDownloadLink} download>
                <Button className="me-2" variant="contained" color="primary">
                  Download CSV Template
                </Button>
              </a>
              {/* <Excelexport
                buttonName="Download Template"
                FileName="ConcreteMIxTemplate"
                excellData={ExcelExportData}
                SheetName="CONC_MIX_DESIGN"
              /> */}
              <ExcelDataUpload
                handeImportExcellData={handeImportExcellData}
                buttonName="Upload CSV Data"
              />
              </Box>
              <Box>
              <Button
                className="me-2"
                type="button"
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <Button
                className="me-2"
                type="button"
                variant="contained"
                color="primary"
                onClick={()=> setFormData({})}
              >
                Reset
              </Button>
              <Button
                className="me-2"
                type="submit"
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
              </Box>
                {/* </>
              } */}
              
            </Grid>
          </Grid>
        </form>
      </div>
    </Box>
  );
};

export default AddConcreteMix;
