import React, { useState } from 'react'
import MainLayout from '../../../layout/MainLayout'
import { Button, Grid, TextField } from '@mui/material'
import { Description } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

export default function SubContractForm() {

  const navigate = useNavigate();
    const [work,setWork] = useState("");
    const [description,setDescription] = useState("")
    const handleChange = (e)=>(setState) => {
        setState(e.target.value);
    }
  return (
    <MainLayout>
        <Grid item xs={12} sm={4}>
                <TextField
                  label="Work"
                  name="work"
                  value={work}
                  fullWidth
                  margin="normal"setDescription
                  onChange={handleChange(setWork)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Description"
                  name="Description"
                  value={description}
                  fullWidth
                  margin="normal"
                  onChange={handleChange(setDescription)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Sub Contractor Name"
                  name="Description"
                  value={description}
                  fullWidth
                  margin="normal"
                  onChange={handleChange(setDescription)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button variant="contained">Submit</Button>
                <Button onClick={() => navigate(-1)} variant="contained"> Cancel</Button>
              </Grid>

    </MainLayout>
  )
}
