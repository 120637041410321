import { DataGrid } from "@mui/x-data-grid";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import MainLayout from "../../layout/MainLayout";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import React, { useEffect, useState } from "react";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import "./email.css";

function Email_list() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://sig.eastus.cloudapp.azure.com/backend/email/all/tempaltes")
      .then((res) => {
        setTableData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "name", headerName: "Template Name", width: 200 },
    {
      field: "subject",
      headerName: "Subject",
      width: 200,
    },
    {
      field: "body",
      headerName: "Body",
      renderCell: (params) => {
        return <div dangerouslySetInnerHTML={{ __html: params.row.body }} />;
      },
      width: 200,
      height: 300,
    },
    { field: "to", headerName: "To", width: 100 },
    { field: "cc", headerName: "CC", width: 100 },
    { field: "bcc", headerName: "BCC", width: 120 },
    { field: "functionName", headerName: "Function", width: 120 },
    { field: "value", headerName: "Values", width: 220 },
  ];

  return (
    <MainLayout>
      <Box>
        <Box
          m="40px 0 0 0"
          sx={{
            "& .MuiDataGrid-root": {
              fontSize: "14px",
              borderColor: colors.grey[800],
            },
            "& .MuiDataGrid-root p": {
              margin: 0,
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none !important",
              backgroundColor: colors.grey[900],
              fontSize: "14px",
            },
            "& .name-column--cell": {
              color: colors.grey[600],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.grey[900],
              borderColor: colors.grey[800],
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.grey[600],
            },
            "& .MuiDataGrid-footerContainer": {
              borderColor: colors.grey[800],
              backgroundColor: colors.grey[900],
            },
            "& .email_list_title": {
              color: colors.grey[300],
              fontSize: "22px",
              fontWeight: 600,
            },
            "& .email_list_gridWrap": {
              backgroundColor: colors.grey[800],
            },
            "& .email_list_search": {
              backgroundColor: colors.grey[900],
            },
            "& .email_list_search input": {
              color: colors.grey[200],
            },
          }}
        >
          <h2 className="email_list_title">Email Templates</h2>
          <div className="email_list_gridWrap">
            <div className="email_list_filterWrap">
              <div className="row flex-row-reverse">
                <div className="col-md-6">
                  <div className="email_list_filter_rt">
                    <div className="email_list_search">
                      <SearchOutlinedIcon />
                      <input type="text" />
                    </div>
                    <div className="email_list_btn export">
                      Export to CSV <FileDownloadOutlinedIcon />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="email_list_filter_lt">
                    <div
                      className="email_list_btn delete"
                      onClick={() => navigate("/email_add")}
                    >
                      Add <AddIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DataGrid
              rows={tableData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
            />
          </div>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default Email_list;
